import { intervalToDuration } from 'date-fns';

export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const formatDate = (date: Date | number | string) => {
  date = new Date(date);
  let month: string | number = (date as Date).getMonth() + 1;
  const year = (date as Date).getFullYear();
  date = <number>(date as Date).getDate();

  date = date >= 10 ? date : `0${date}`;
  month = month >= 10 ? month : `0${month}`;

  return `${year}/${month}/${date}`;
};

export const formatDateIn_ = (date: Date | number | string) => {
  date = new Date(date);
  let month: string | number = (date as Date).getMonth() + 1;
  const year = (date as Date).getFullYear();
  date = <number>(date as Date).getDate();

  date = date >= 10 ? date : `0${date}`;
  month = month >= 10 ? month : `0${month}`;

  return `${year}-${month}-${date}`;
};

export const getDateAndMonth = (date?: Date | string) => {
  date = new Date(date as Date);

  const day = date.getDate();
  const month = months[date?.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const getTomorrow = (daysAfter: number = 1): Date => {
  const date = new Date();
  date.setDate(date.getDate() + daysAfter);
  return date;
};

export const getDateDifference = (startDate: Date = getTomorrow(), endDate: Date) => {
  startDate?.setHours(0, 0, 0);
  endDate?.setHours(0, 0, 0);

  const interval = intervalToDuration({ start: startDate, end: endDate });

  const months = (interval.years ?? 0) * 12 + (interval.months ?? 0);
  const days = interval.days ?? 0;

  return { months, days };
};

export default formatDate;
