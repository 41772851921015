import { Box, Button, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { BiPlus } from "react-icons/bi";
import SelectedPhotosModal from "./SelectedPhotosModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addPhotos, removePhoto } from "../../redux/property-form.slice";

const fileTypes = ["JPEG", "JPG", "PNG", "GIF", "HEIC"];

const Step6 = () => {
  const { photos } = useSelector((state: RootState) => state?.propertyForm);
  const dispatch = useDispatch();
  const [modalOpened, setModalOpened] = React.useState<boolean>(false);

  const handleChange = (files: FileList) => {
    dispatch(addPhotos(files));
  };

  const deletePhoto = (index: number) => {
    dispatch(removePhoto({ index }));
  };

  return (
    <>
      {modalOpened && <SelectedPhotosModal closeModal={() => setModalOpened(false)} deletePhoto={deletePhoto} photos={photos} />}
      <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={1.4}
          justifyContent="center"
          alignItems={"center"}
          maxWidth={450}
          marginX={"auto"}
          textAlign="center"
        >
          <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
            Photos
          </Typography>

          <Typography variant="subtitle1" component={"p"}>
            Upload photos of your property! You need at least five photos, but the more the merrier. Make it feel like home!
          </Typography>
        </Box>

        {/* Custom File Uploader With Drag And Drop Option */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            marginX: "auto",
            marginTop: 2,
          }}
        >
          <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={true}>
            <Box
              width={"100%"}
              maxWidth={"250px"}
              height={"250px"}
              border={`5px dashed ${blue[900]}`}
              marginX="auto"
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              borderRadius={3}
              sx={{ cursor: "pointer" }}
            >
              <Box
                display="flex"
                width={40}
                height={40}
                justifyContent="center"
                alignItems={"center"}
                fontSize={24}
                color={blue[900]}
                component="span"
                borderRadius={"50%"}
                border={`3px dashed ${blue[900]}`}
                sx={{ cursor: "pointer" }}
              >
                <BiPlus />
              </Box>
            </Box>
          </FileUploader>
          <Typography variant="subtitle1" component="p" textAlign={"center"} marginTop={1}>
            Select or drop images
          </Typography>

          {/* button to open modal */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${blue[800]}`,
              "&:hover": {
                backgroundColor: `${blue[900]}`,
              },
              display: "block",
              marginX: "auto",
              marginTop: 2,
            }}
            onClick={() => setModalOpened(true)}
          >
            VIEW PHOTOS {`(${photos?.length})`}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Step6;
