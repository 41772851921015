import { ThemeProvider, createTheme } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  children?: ReactElement | ReactElement[];
}

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            backgroundColor: "#53cbf0",
            "&:hover": {
              backgroundColor: "#00b9f1",
            },
          },
        },
        {
          props: {
            variant: "outlined",
          },
          style: {
            borderColor: "#53cbf0",
            color: "#53cbf0",
            "&:hover": {
              backgroundColor: "#00b9f1",
              borderColor: "#53cbf0",
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h6" },
          style: {
            fontFamily: "inherit",
          },
        },
        {
          props: {
            variant: "subtitle1",
          },
          style: {
            fontSize: ".95rem",
            fontFamily: "inherit",
            fontWeight: "bold",
            lineHeight: 1.35,
          },
        },

        {
          props: {
            variant: "body1",
          },
          style: {
            fontSize: ".85rem",
            fontFamily: "inherit",
            lineHeight: 1.35,
          },
        },
      ],
    },
  },
});

export default function BlogTheme({ children }: Props) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
