import React from "react";
import { IAmenties } from "../../schema/interfaces/amenities.interface";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { BiTrash } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Props {
  amenity?: IAmenties;
  onSuccess(): void;
  onClose(): void;
  updateDesc(value: string): void;
  removeOne(text?: string): void;
  checkIsSelected(text?: string): boolean;
}

const AmenityDescriptionModal = ({ amenity, onSuccess, onClose, updateDesc, removeOne, checkIsSelected }: Props) => {
  const isSelected = checkIsSelected(amenity?.text);
  const property = useSelector((state: RootState) => state.propertyForm);

  const amenityInState = property.amenities?.find((am) => am.text === amenity?.text) as IAmenties;

  return (
    <Modal
      open={true}
      onClose={onClose}
      sx={{ outline: "none", border: "none" }}
      aria-labelledBy="modal-modal-title"
      aria-aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          width: "90vw",
          maxWidth: "300px",
          backgroundColor: "white",
          boxShadow: "0px 1px 2px rgba(0,0,0,0.3)",
          borderRadius: 2,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: ".8rem",
          border: "none",
        }}
      >
        <h4>Amenity Description</h4>

        <Box marginTop={2}>
          <Box display="flex" alignItems="flex-start" gap={2} justifyContent={"space-between"}>
            <Box flex={1} display="flex" gap={2}>
              <Box component="span">
                {typeof amenity?.icon === "string" ? (
                  <img src={amenity.icon} style={{ width: "35px", height: "35px", objectFit: "cover", objectPosition: "center" }} />
                ) : (
                  amenity?.icon
                )}
              </Box>
              <Typography variant="subtitle1" fontWeight={"bold"} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {amenity?.text}
              </Typography>
            </Box>

            {/* Icon for deleting the amenity */}
            {isSelected && (
              <Box component={"span"} onClick={() => removeOne(amenity?.text)}>
                <BiTrash size={20} color="darkred" cursor="pointer" />
              </Box>
            )}
          </Box>
        </Box>

        {/* Description Input Field */}
        <Box marginTop={2}>
          <TextField
            multiline={true}
            rows={3}
            variant="outlined"
            label="Amenity description"
            required
            fullWidth
            sx={{ padding: 0 }}
            InputProps={{ sx: { fontSize: ".8rem" } }}
            size="small"
            value={amenityInState?.description || amenity?.description}
            onChange={(e) => updateDesc(e.target.value)}
          />

          <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={1.5} marginTop={2}>
            <Button variant="outlined" size="small" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" size="small" onClick={onSuccess}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AmenityDescriptionModal;
