import React, { useEffect } from "react";
import PropertySearch from "../components/Home/PropertySearch/PropertySearch";
import { Box, Container } from "@mui/material";
import HomeBanner from "../components/Home/Banner";
import Navbar from "../components/UI/Navbar/Navbar";
import { useDispatch } from "react-redux";
import { getProfile } from "../services/profile.services";
import Services from "../components/Home/Services";
import AvailableListings from "../components/Home/AvailableListings";
import Connect from "../components/Home/Connect";
import TestimonialsContainer from "../components/Home/TestimonialsContainer";
import Footer from "../components/UI/Footer";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <Box component="section">
      <Navbar />

      <HomeBanner />
      <Container maxWidth="lg" sx={{ paddingY: "1.4rem" }}>
        <Services />
        <AvailableListings />
        <Connect />
        <TestimonialsContainer />
      </Container>

      <Footer />
    </Box>
  );
};

export default Home;
