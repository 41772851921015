import React, { useMemo, useState } from "react";
import getDashboardType from "../../utils/dashboard-type.utils";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Edit, Warning } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { DefaultStripeDue } from "../../schema/enums/payment-profile.enum";
import VerificationModal from "./VerificationModal";
import { hasOwnerAccount } from "../../utils/user-groups.utils";

export default function ProfilePicture() {
  const [verificationModalOpen, setVerificationModalOpen] = useState<boolean>(false);
  const paymentProfile = useSelector((state: RootState) => state.paymentProfile.paymentProfile);
  const user = useSelector((state: RootState) => state.user);
  const hasOwner = hasOwnerAccount(user?.groups);

  return (
    <>
      {" "}
      <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={2} flexWrap="wrap">
        <img
          src={user?.profilePicture}
          alt="profile-photo"
          style={{
            width: "180px",
            height: "180px",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "50%",
          }}
        />
        <Box>
          {paymentProfile?.eventuallyDue.includes(DefaultStripeDue.EXTERNAL_ACCOUNT) && (
            <Button
              size="small"
              variant="contained"
              endIcon={<Warning />}
              color="warning"
              sx={{ marginBottom: 1, maxWidth: "fit-content", marginLeft: "auto", display: "flex" }}
              onClick={() => setVerificationModalOpen(true)}
            >
              Verification Required!
            </Button>
          )}

          <Box display="flex" alignItems="flex-end" justifyContent="space-between" gap={2} flexWrap="wrap">
            <Link to={"/profile/edit"} style={{ maxWidth: "fit-content" }}>
              <Button variant="contained" size="small">
                Edit Profile
              </Button>
            </Link>
            {hasOwner && (
              <Link to={"/profile/edit/identification"} style={{ maxWidth: "fit-content" }}>
                <Button variant="contained" size="small">
                  Edit Identification Details
                </Button>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      <VerificationModal isOpen={verificationModalOpen} close={() => setVerificationModalOpen(false)} />
    </>
  );
}
