import React, { useMemo } from "react";
import { TableHeader, TableHeaderEntry, TableLayout, TableRowEntry, TableRowsContainer, TableRow } from "../../UI/Table";
import { Box, Typography, useMediaQuery } from "@mui/material";
import UserStatus from "../../UI/UserStatus";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import UserRow from "./UserRow";
import useWidth from "../../../hooks/useWidth";

export default function UserTable() {
  const { users } = useSelector((state: RootState) => state.user);
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const width = useWidth();

  const headers = useMemo(() => {
    return [
      { name: "Full Name", width: 180 },
      ...(screenQuery
        ? []
        : [
            { name: "Email", width: 180 },
            { name: "Username", width: 150 },
            { name: "Status", width: 150 },
            { name: "", width: 150 },
          ]),
    ];
  }, [width]);

  return (
    <TableLayout>
      <TableHeader>
        {headers?.map((header, index) => {
          return (
            <TableHeaderEntry width={header?.width} key={index}>
              {header.name}
            </TableHeaderEntry>
          );
        })}
      </TableHeader>
      <TableRowsContainer>
        {users?.length === 0 && (
          <Typography
            variant="body1"
            width={"100%"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={2}
            fontWeight={"bold"}
            fontSize=".8rem"
          >
            No user found
          </Typography>
        )}
        {users?.length > 0 &&
          users?.map((user, index) => {
            return <UserRow user={user} key={index} />;
          })}
      </TableRowsContainer>
    </TableLayout>
  );
}
