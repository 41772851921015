import { IBlog } from '../../schema/interfaces/blog.interface';

export const blogData: IBlog[] = [
  {
    title: 'Taking the First Step: Why Getting the COVID-19 Shot Matters',
    subtitle:
      "In the face of the COVID-19 pandemic, the development and distribution of vaccines have been pivotal in our collective fight against this global health crisis. Getting vaccinated against COVID-19 is not only an act of personal responsibility but also a significant contribution to our community's well-being and a step towards reclaiming a sense of normalcy in our lives.",
    src: 'get-your-shot.png',
    date: 'December 12, 2023',
    link: '/blogs/get-your-shot',
    tag: 'Health',
  },

  {
    title: 'Nourishing Your Body: The Power of Eating Healthy',
    subtitle: 'In a world filled with processed foods and fast-paced living, the significance of maintaining a healthy diet often takes a backseat',
    src: 'the-power-of-eating-healthy.png',
    date: 'December 12, 2023',
    link: '/blogs/the-power-of-eating-healthy',
    tag: 'Health',
  },

  {
    title: 'Embracing Fitness: A Journey to Wellness with A Traveled Path Homes',
    subtitle: "Wellness isn't just a destination; it's a lifestyle.",
    src: 'embracing-fitness.png',
    date: 'December 12, 2023',
    link: '/blogs/embracing-fitness',
    tag: 'Fitness',
  },
  {
    title: "Visionary Journey of Shapree' Marshall: Empowering Healthcare Heroes and Communities",
    subtitle: "A story of inspiration and dedication, embodied by Shapree' Marshall, and the inception of A Traveled Path Homes.",
    src: 'visionary-journey-of-shapree-marshall.png',
    date: 'December 12, 2023',
    link: '/blogs/visionary-journey-of-shapree-marshall',
    tag: 'Journey',
  },
  {
    title: "A Traveled Path Homes' Pledge: Promoting Health Equity for All",
    subtitle: 'At A Traveled Path Homes, our commitment to fostering thriving communities goes beyond providing comfortable accommodations.',
    date: 'December 12, 2023',
    src: 'atph-pledge.png',
    link: '/blogs/atph-pledge',
    tag: 'ATPH',
  },
];
