import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAdminPayments } from "../../../services/payment.services";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { blue } from "@mui/material/colors";
import PaymentsTable from "../Payment/PaymentsTable";

export default function PaymentLogs() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminPayments());
  }, []);
  return (
    <Box component="section" sx={{ marginTop: 4 }}>
      <Box component="header" sx={{ display: "flex", alignItems: "center", gap: 2, justifyContent: "space-between", marginBottom: 3 }}>
        <Typography variant="h6" component={"h6"}>
          Payment Logs
        </Typography>

        <Link component={RouterLink} to="/admin/payments" color={blue[900]}>
          See all
        </Link>
      </Box>

      <PaymentsTable displayPagination={false} />
    </Box>
  );
}
