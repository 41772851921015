import { Box, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updatePropertyForm } from "../../redux/property-form.slice";
import AddressSelector from "./AddressSelector";

const Step3 = () => {
  const { city, state, zipCode } = useSelector((state: RootState) => state?.propertyForm);
  const dispatch = useDispatch();

  const updateCity = (city: string) => {
    dispatch(updatePropertyForm({ key: "city", value: city }));
  };

  const updateState = (state: string) => {
    dispatch(updatePropertyForm({ key: "state", value: state }));
  };

  const updateZip = (zip: string) => {
    dispatch(updatePropertyForm({ key: "zipCode", value: zip }));
  };

  return (
    <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={1.4}
        justifyContent="center"
        alignItems={"center"}
        maxWidth={450}
        marginX={"auto"}
        textAlign="center"
      >
        <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
          Address
        </Typography>

        <Typography variant="subtitle1" component={"p"}>
          Your address and location are safe with us. It won't be viewable by renders until you chose to list property publicly.
        </Typography>
      </Box>

      <Stack
        gap={4}
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "500px",
          marginX: "auto",
          marginTop: 2,
        }}
      >
        <AddressSelector />

        <Box>
          <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
            City
          </label>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            sx={{ marginTop: 1.4 }}
            label="City"
            value={city}
            required
            onChange={(e) => updateCity(e.target.value)}
          />
        </Box>

        <Box>
          <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
            State
          </label>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            sx={{ marginTop: 1.4 }}
            label="State"
            value={state}
            required
            onChange={(e) => updateState(e.target.value)}
          />
        </Box>

        <Box>
          <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
            Zip/Postal Code
          </label>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            sx={{ marginTop: 1.4 }}
            label="Zip/Postal Code"
            value={zipCode}
            required
            onChange={(e) => updateZip(e.target.value)}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Step3;
