import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBookmark, IBookmarkSlice } from '../schema/interfaces/bookmark.interface';
import { getUserBookmarks } from '../services/bookmark.services';

const initialState: IBookmarkSlice = {
  bookmarks: [],
  handlers: { isLoading: false },
};

const bookmarkSlice = createSlice({
  name: 'bookmarkSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserBookmarks.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getUserBookmarks.fulfilled, (state, action: PayloadAction<IBookmark[]>) => {
        state.bookmarks = action.payload;
        state.handlers.isLoading = false;
      })
      .addCase(getUserBookmarks.rejected, (state) => {
        state.handlers.isLoading = false;
      });
  },
});

const bookmarkReducer = bookmarkSlice.reducer;
export const {} = bookmarkSlice.actions;
export default bookmarkReducer;
