import React, { useState } from "react";
import "./DashboardNavbar.css";
import { Link } from "react-router-dom";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import { BiMenu } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import localStorageService from "../../utils/localstorage.utils";
import { logout } from "../../services/auth.services";
import { useDispatch } from "react-redux";
import checkIsOwner from "../../utils/user-groups.utils";
import Sublink from "../UI/Navbar/Sublinks";
import BetaButton from "../UI/Navbar/BetaButton";

interface Props {
  sidebarOpened: boolean;
  toggleSidebar(): void;
}

const DashboardNavbar = ({ sidebarOpened, toggleSidebar }: Props) => {
  const dispatch = useDispatch();
  const user = localStorageService.user.get();
  const isOwner = checkIsOwner();
  const screenQuery = useMediaQuery("(max-width:1000px)");

  return (
    <nav style={{ height: "75px" }}>
      <div className="dashboard-nav">
        <Link to={"/"}>
          <img src="/images/ATPH-Logo.png" className="logo"></img>
        </Link>

        <div className={`dashboard-links-container`}>
          <ul>
            {!screenQuery && <BetaButton />}

            <Link className="dashboard-navLink" to="/">
              Home
            </Link>
            <Sublink
              title="About"
              className="dashboard-navLink"
              sublinks={[
                { text: "About Us", href: "/about" },
                { text: "Blogs", href: "/blogs" },
                { text: "Contact", href: "/contact" },
              ]}
            />
            <Sublink
              title="Properties"
              className="dashboard-navLink"
              sublinks={[
                { text: "Properties", href: "/properties" },
                { text: "Map", href: "/map" },
              ]}
            />
            <Link to={user?.isAdmin ? "/admin/dashboard" : isOwner ? "/owner/listings" : "/renter/dashboard"}>Dashboard</Link>
            <p className="dasbhboard-navLink" style={{ cursor: "pointer" }} onClick={() => dispatch(logout())}>
              Logout
            </p>

            <Link to={user?.isAdmin ? "/admin/dashboard" : "/profile"}>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  minWidth: "fit-content",
                }}
              >
                <img src={user?.profilePicture} className="Avatar"></img>
                <Typography variant="subtitle1" sx={{ minWidth: "fit-content" }}>
                  {user?.firstName} {user?.lastName}
                </Typography>
              </Box>
            </Link>
          </ul>
        </div>

        <Box sx={{ display: screenQuery ? "flex" : "none", alignItems: "center", gap: 1 }}>
          {screenQuery && <BetaButton />}
          <span className="menu-icon" onClick={toggleSidebar}>
            {sidebarOpened ? <MdClose /> : <BiMenu />}
          </span>
        </Box>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
