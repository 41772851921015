const secrets = {
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  geoapifyKey: process.env.REACT_APP_GEOAPIFY_API_KEY,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  stripeKey: {
    dev: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  },
  googleMapApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  cloudinary: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
  gaMeasurmentId: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASURMENT_ID,
  waitlistUrl: 'https://form.jotform.com/240148099098061',
  environmentUri: process.env.REACT_APP_ENVIRONMENT_URI,
};

export default secrets;
