import axios, { AxiosError } from 'axios';
import localStorageService from './utils/localstorage.utils';
import store from './redux/store';
import { openErrorModal } from './redux/handlers.slice';
import Swal from 'sweetalert2';

const http = axios.create({
  // baseURL: 'https://atphousing.com',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorageService.token.get()}`,
  },
});

const unrestrictedPages = [
  '/login',
  '/register',
  '/contact',
  '/about',
  '/blogs',
  '/properties',
  '/forgot-password',
  '/map',
  '/user-agreement',
  '/refund-policy',
];

http.interceptors.response.use(
  (res) => Promise.resolve(res),
  async (error: AxiosError) => {
    if (error.response?.status === 403) {
      let isRedirecting = false;

      // if the page is restricted, set isRedirecting to true
      for (const route of unrestrictedPages) {
        if (window.location.pathname === '/') {
          isRedirecting = false;
        } else if (!window.location.pathname.startsWith(route)) {
          isRedirecting = true;
        }
      }

      if (isRedirecting) {
        await Swal.fire({
          title: 'Session timeout',
          text: 'Your session is over, log in again',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error',
        });
        window.location.href = '/login';
        localStorageService.token.delete();
        localStorageService.user.delete();
      }
    }
    return Promise.reject(error);
  }
);

export default http;
