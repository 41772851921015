import { Box, Container, useMediaQuery } from "@mui/material";
import React from "react";
import { BiLogoFacebook, BiLogoInstagram, BiLogoLinkedin, BiLogoTwitter } from "react-icons/bi";
import { Link } from "react-router-dom";
import checkIsOwner from "../../utils/user-groups.utils";
import localStorageService from "../../utils/localstorage.utils";

const socials = [
  {
    href: "https://facebook.com",
    icon: <BiLogoFacebook />,
  },
  {
    href: "https://instagram.com",
    icon: <BiLogoInstagram />,
  },
  {
    href: "https://twitter.com",
    icon: <BiLogoTwitter />,
  },
  {
    href: "https://linkedin.com",
    icon: <BiLogoLinkedin />,
  },
];

const Footer = () => {
  const screenQuery = useMediaQuery("(max-width: 900px)");

  const user = localStorageService.user.get();

  const isOwner = checkIsOwner();

  return (
    <footer style={{ background: "#53cbf0" }}>
      <Container
        maxWidth="lg"
        sx={{
          paddingY: 3,
          gap: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: screenQuery ? "column" : "row",
        }}
      >
        <Box
          display="flex"
          sx={{
            rowGap: 2,
            columnGap: 4,
            flexDirection: screenQuery ? "column" : "row",
          }}
          flexWrap="wrap"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Link to={"/about"} style={{ fontSize: 14, color: "white" }}>
              About Us
            </Link>

            <Link to={"/blogs"} style={{ fontSize: 14, color: "white" }}>
              Blogs
            </Link>

            <Link to={"/contact"} style={{ fontSize: 14, color: "white" }}>
              Contact Us
            </Link>

            <Link to={"/map"} style={{ fontSize: 14, color: "white" }}>
              Search Our Map
            </Link>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            {user && (
              <Link to={isOwner ? "/owner/listings" : "/renter/dashboard"} style={{ fontSize: 14, color: "white" }}>
                Dashboard
              </Link>
            )}

            {/* Display for only owners and not renters */}
            {isOwner && (
              <Link to={"/owner/property/new"} style={{ fontSize: 14, color: "white" }}>
                List Your Property
              </Link>
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <Link to={""} style={{ fontSize: 14, color: "white" }}>
              FAQ
            </Link>

            <Link to={"/user-agreement"} style={{ fontSize: 14, color: "white" }}>
              User Agreement
            </Link>

            <Link to={"/refund-policy"} style={{ fontSize: 14, color: "white" }}>
              Refund Policy
            </Link>
          </Box>
        </Box>
        <Box display="flex" gap={2} alignItems={"center"}>
          {socials?.map((social, index) => {
            return (
              <Link key={index} to={social.href} style={{ fontSize: 20, color: "white" }}>
                {social.icon}
              </Link>
            );
          })}
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
