import { Box, Container, ThemeProvider, Typography, createTheme } from "@mui/material";
import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import PrivacyPolicySubtopic from "../../components/PrivacyPolicy/PrivacyPolicySubtopic";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "subtitle1",
          },
          style: {
            fontSize: ".95rem",
            fontFamily: "inherit",
            fontWeight: "bold",
            lineHeight: 1.35,
          },
        },

        {
          props: {
            variant: "body1",
          },
          style: {
            fontSize: ".85rem",
            fontFamily: "inherit",
            lineHeight: 1.35,
          },
        },
      ],
    },
  },
});

export default function RefundPolicy() {
  return (
    <>
      <Navbar />
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" sx={{ paddingY: "1.4rem" }}>
          <Typography variant="h5" marginY={2} color="#333741" fontWeight="bold">
            Refund Policy
          </Typography>
          <Typography variant="body1" color="#333741">
            At Traveled Path Homes, we strive to ensure a positive experience for all our users. This refund policy outlines the guidelines and
            procedures concerning refunds for bookings made through our platform.
          </Typography>

          <PrivacyPolicySubtopic title="Refund Eligibility">
            <PrivacyPolicySubtopicsList
              question="Cancellation by Hosts"
              answer="If a host cancels a booking, you will receive a full refund for that booking."
            />
            <PrivacyPolicySubtopicsList question="Guest Cancellation" answer="">
              <PrivacyPolicySubtopicsList
                question="Flexible Cancellation Policy"
                answer="Guests may receive a full refund if they cancel within the specified timeframe as per the host's Flexible Cancellation Policy."
              />
              <PrivacyPolicySubtopicsList
                question="Moderate or Strict Cancellation Policy"
                answer="Guests will receive a partial refund according to the host's Moderate or Strict Cancellation Policy."
              />
            </PrivacyPolicySubtopicsList>
            <PrivacyPolicySubtopicsList
              question="Extenuating Circumstances"
              answer="In case of unforeseen circumstances (e.g., natural disasters, illness, or unexpected travel restrictions), special consideration may be given for refunds. Contact our support team with relevant documentation for further assistance."
            />
          </PrivacyPolicySubtopic>

          <PrivacyPolicySubtopic title="Refund Process">
            <PrivacyPolicySubtopicsList
              question="Cancellation Procedure"
              answer="To cancel a booking and request a refund, users must do so through the Traveled Path Homes platform. Follow the cancellation steps outlined in your booking confirmation."
            />
            <PrivacyPolicySubtopicsList
              question="Refund Timeline"
              answer="Refunds will be processed within a specific timeframe, credit/debit card refunds may take 5-10 business days to appear in your account, depending on your financial institution.
Refunds through other payment methods will be processed according to the respective provider's policies."
            ></PrivacyPolicySubtopicsList>

            <PrivacyPolicySubtopicsList
              question="Extenuating Circumstances"
              answer="In case of unforeseen circumstances (e.g., natural disasters, illness, or unexpected travel restrictions), special consideration may be given for refunds. Contact our support team with relevant documentation for further assistance."
            />
          </PrivacyPolicySubtopic>

          <PrivacyPolicySubtopic title="Non-Refundable Fees">
            <PrivacyPolicySubtopicsList
              question="Service Fees"
              answer="Traveled Path Homes' service fees are non-refundable, except in cases where the host cancels the booking."
            />
            <PrivacyPolicySubtopicsList
              question="Additional Charges"
              answer="Any additional fees, including currency conversion or bank charges, are the responsibility of the user and are non-refundable by Traveled Path Homes."
            ></PrivacyPolicySubtopicsList>
          </PrivacyPolicySubtopic>

          <PrivacyPolicySubtopic title="Disputes and Exceptions">
            <PrivacyPolicySubtopicsList
              question="Dispute Resolution"
              answer="If you encounter issues regarding refunds, contact our support team. We'll work to resolve disputes fairly and promptly."
            />
            <PrivacyPolicySubtopicsList
              question="Exceptions"
              answer="Refunds outside the standard policy may be granted at Traveled Path Homes' discretion under unique circumstances. Such cases will be assessed on an individual basis."
            ></PrivacyPolicySubtopicsList>
          </PrivacyPolicySubtopic>

          <PrivacyPolicySubtopic title="Policy Updates">
            <PrivacyPolicySubtopicsList
              question="Changes to Policy"
              answer="Traveled Path Homes reserves the right to update or modify this refund policy. Any changes will be effective immediately upon posting on our website.."
            />
          </PrivacyPolicySubtopic>

          <Typography variant="body1" color={"#333741"} marginTop={2} marginBottom={1}>
            By using our platform and services, you agree to adhere to this refund policy. If you have any questions or require assistance, please
            reach out to our support team at{" "}
            <a href="mailto:support@atphousing.com" style={{ fontWeight: "bold", textDecoration: "underline", color: "#53cbf0" }}>
              support@atphousing.com.
            </a>
          </Typography>
          <Typography variant="body1" color="#333741">
            Thank you for choosing Traveled Path Homes!
          </Typography>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  );
}
