import { useEffect, useMemo, useState } from "react";

export default function useWidth() {
  const [width, setWidth] = useState<number>(window?.innerWidth);

  const resize = () => {
    setWidth(window?.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [window?.innerWidth]);

  return width;
}
