import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, getProfile } from "../services/profile.services";
import { updateUserId } from "../services/payment-profile.services";
import { UserIdTypes } from "../schema/enums/payment-profile.enum";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";

export default function EditIdInfo() {
  const user = useSelector((state: RootState) => state.user);
  const [idNumber, setIdNumber] = useState<string>(user?.idNumber ?? "");
  const [front, setFront] = useState<File | undefined>(undefined);
  const [back, setBack] = useState<File | undefined>(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const idData = await dispatch(editProfile({ idNumber }));
      if (!idData.error) {
        const frontData = await dispatch(updateUserId({ url: front, type: UserIdTypes.FRONT }));
        if (!frontData.error) {
          const backData = await dispatch(updateUserId({ url: back, type: UserIdTypes.BACK }));
          if (!backData.error) {
            await Swal.fire({
              title: "Update Identification Information",
              text: "Thank you for your submission. We have received your verification information. If there are any issues, we will contact you shortly.",
              timer: 2000,
              showConfirmButton: false,
              icon: "success",
            });
            navigate("/profile");
          }
        }
      }
    },
    [front, back, idNumber]
  );

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <DashboardLayout>
      <form onSubmit={submit}>
        <Box component="header" display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" gap={4}>
          <h2>Edit Identification Information</h2>
        </Box>

        <Box marginY={5}>
          <h3>Personal Information</h3>
          <Grid container gap={2} sx={{ marginTop: 1 }}>
            {/* Firstname */}
            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <label style={labelStyles} htmlFor="idNumber">
                  ID Number {"(Drivers License)"}
                </label>
                <TextField
                  InputProps={{ sx: { fontSize: ".8rem" } }}
                  variant="outlined"
                  id="idNumber"
                  name="idNumber"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  required={true}
                  type="number"
                  placeholder="Enter Driver's License Number"
                  helperText="Please enter your Driver's License Number (Exclude '-')"
                  size="small"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <p style={labelStyles}>Front Image</p>
                {!front ? (
                  <TextField
                    InputProps={{ sx: { fontSize: ".8rem", display: front ? "none" : "block" } }}
                    value={front}
                    required={true}
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    id="frontshot"
                    helperText="Please upload a clear image of the front side of your ID."
                    size="small"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setFront(e.target?.files?.[0])}
                  />
                ) : (
                  <Box
                    component="label"
                    htmlFor="frontshot"
                    sx={{
                      width: "100%",
                      border: "1px solid lightgray",
                      paddingX: 1,
                      paddingY: 0.6,
                      borderRadius: 1,
                      cursor: "pointer",
                      ":hover": { borderColor: "black" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{ width: "25px", height: "25px", objectFit: "cover", objectPosition: "center", borderRadius: 1.5 }}
                        src={URL.createObjectURL(front as File)}
                      />
                      <Typography variant="subtitle1" component={"p"} fontSize=".8rem" fontWeight="medium">
                        Image Selected!
                      </Typography>
                    </Box>

                    <IconButton size="small" onClick={() => setFront(undefined)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <p style={labelStyles}>Back Image</p>
                {!back ? (
                  <TextField
                    InputProps={{ sx: { fontSize: ".8rem", display: back ? "none" : "block" } }}
                    value={back}
                    required={true}
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    id="backshot"
                    helperText="Please upload a clear image of the back side of your ID."
                    size="small"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setBack(e.target?.files?.[0])}
                  />
                ) : (
                  <Box
                    component="label"
                    htmlFor="backshot"
                    sx={{
                      width: "100%",
                      border: "1px solid lightgray",
                      paddingX: 1,
                      paddingY: 0.6,
                      borderRadius: 1,
                      cursor: "pointer",
                      ":hover": { borderColor: "black" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        component={"img"}
                        sx={{ width: "25px", height: "25px", objectFit: "cover", objectPosition: "center", borderRadius: 1.5 }}
                        src={URL.createObjectURL(back as File)}
                      />
                      <Typography variant="subtitle1" component={"p"} fontSize=".8rem" fontWeight="medium">
                        Image Selected!
                      </Typography>
                    </Box>

                    <IconButton size="small" onClick={() => setBack(undefined)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Button type="submit" size="small" variant="contained" sx={{ display: "block", maxWidth: "150px", width: "100%", marginLeft: "auto" }}>
          Submit
        </Button>
      </form>
    </DashboardLayout>
  );
}

const boxStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginY: 1.5,
};

const labelStyles = {
  fontWeight: "500",
  fontSize: ".9rem",
};
