import React, { useEffect } from "react";
import { Box, Button, Card, Container, Grid } from "@mui/material";
import ResidencesItem from "../../components/UI/ResidencesItem";
import { Add, ControlPoint } from "@mui/icons-material";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getProperties, getUserProperties } from "../../services/property.services";

const OwnerListings = () => {
  const dispatch = useDispatch();
  const { properties } = useSelector((state: RootState) => state.properties);

  useEffect(() => {
    dispatch(getUserProperties());
  }, []);

  return (
    <DashboardLayout>
      <div className="dashboard">
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
          <h2>My Listings</h2>

          <Link to={"/owner/property/new"}>
            <Button variant="contained" endIcon={<Add />}>
              Add Property
            </Button>
          </Link>
        </Box>

        {properties && properties?.length > 0 && (
          <Grid container gap={4} sx={{ marginTop: 2 }}>
            {properties?.map((property, index) => {
              return <ResidencesItem {...property} key={index} />;
            })}
          </Grid>
        )}

        {(!properties || properties?.length == 0) && (
          <Box minHeight="60vh" display="flex" alignItems="center" justifyContent="center">
            <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>You have not added any listing</p>
          </Box>
        )}
      </div>
    </DashboardLayout>
  );
};

export default OwnerListings;
