import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import { IReservation } from "../../schema/interfaces/reservation.interface";
import { getDateAndMonth } from "../../utils/date.utils";
import { IReservationStatus } from "../../schema/enums/reservations.enum";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { getPropertyReservations, updateReservationStatus } from "../../services/reservations.services";

interface Props extends IReservation {}

function SingleReservation({ User, startDate, endDate, totalPrice, status, id, propertyId }: Props) {
  const dispatch = useDispatch();

  const submit = () => {
    Swal.fire({
      title: "Reject Reservation",
      text: `Are you sure you want to reject this reservation`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = await dispatch(updateReservationStatus({ id, status: IReservationStatus.REJECTED }));
        if (!data?.error) {
          await Swal.fire({
            title: "Reject Reservation",
            icon: "success",
            text: "You have successfully rejected this reservation",
            showConfirmButton: false,
            timer: 2000,
          });
          await dispatch(getPropertyReservations(propertyId));
        }
      }
    });
  };

  return (
    <Grid item xs={12} md={3.6}>
      <Paper
        elevation={1}
        sx={{ padding: 1.4, cursor: "pointer", ":hover": { boxShadow: "1px 2px 3px rgba(0,0,0,.3)" } }}
        style={{ borderRadius: 10 }}
      >
        <Box component="header" display="flex" alignItems="center" gap={2}>
          <img
            src={User?.profilePicture}
            alt=""
            style={{ width: "30px", height: "30px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
          />
          <p>
            {User?.firstName} {User?.lastName}
          </p>
        </Box>

        <Box marginTop={2} fontSize={14} display="flex" flexDirection="column" gap={1.2}>
          <p>
            Start Date: <b style={tagStyle}>{getDateAndMonth(startDate as Date)}</b>
          </p>

          <p>
            End Date: <b style={tagStyle}>{getDateAndMonth(endDate as Date)}</b>
          </p>

          <p>
            Total Price: <b style={tagStyle}>${totalPrice.toLocaleString()}</b>
          </p>

          <p>
            Status: <b style={tagStyle}>{status}</b>
          </p>

          {status === IReservationStatus.PENDING && (
            <Button variant="contained" size="small" onClick={submit}>
              Reject
            </Button>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

const tagStyle: React.CSSProperties = { padding: "2px 5px", background: "#edecec", borderRadius: 6, textTransform: "uppercase" };

export default SingleReservation;
