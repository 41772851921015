import { Box, Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { FaStethoscope, FaMapLocation } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { TbHandClick } from "react-icons/tb";
import React from "react";
import Title from "./Title";

const services = [
  {
    title: "Prime Nursing House",
    icon: <FaStethoscope size={30} style={{ color: "#53cbf0" }} />,
    body: "We create an online community of traveling medical professionals and educators.",
  },
  {
    title: "Map Search",
    icon: <FaMapLocation size={30} style={{ color: "#53cbf0" }} />,
    body: "Choose from 150K+ Affordable Monthly Rentals.",
  },
  {
    title: "Connect with Property Owners",
    icon: <TbHandClick size={30} style={{ color: "#53cbf0" }} />,
    body: "Save time – submit one request, connect with eager hosts!",
  },
];

export default function Services() {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  return (
    <Box component="section" marginY={6}>
      <Title text="What We Do" />

      <Grid container gap={3} justifyContent={screenQuery ? "center" : "space-between"} marginTop={4}>
        {services?.map((service, index) => {
          return (
            <Grid item xs={10} sm={5} md={3.5} lg={3.5} key={index}>
              <Paper
                sx={{
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2.4,
                  padding: 3,
                  alignItems: "center",
                  minHeight: "250px",
                  background: "#ececed",
                }}
                elevation={0}
              >
                <Box component={"span"}>{service.icon}</Box>
                <Typography variant="subtitle1" component="h6" fontSize={16} textAlign="center" fontWeight="600" lineHeight={1.4}>
                  {service.title}
                </Typography>
                <hr style={{ marginTop: ".7rem", background: "#333741", height: "3px", marginBottom: ".7rem", width: "100%" }} />
                <Typography variant="subtitle2" component="p" fontSize={14} textAlign="center" lineHeight={1.2}>
                  {service.body}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Box maxWidth={screenQuery ? "500px" : "800px"} width={"85vw"} marginX={"auto"} marginTop={8}>
        <Box
          component="img"
          src="./images/home2.png"
          sx={{ width: "100%", height: screenQuery ? "300px" : "500px", objectFit: "cover", objectPosition: "center" }}
        ></Box>
      </Box>
    </Box>
  );
}
