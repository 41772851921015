import { IPropertyStatus } from '../schema/enums/property.enum';
import { IProperty } from '../schema/interfaces/property.interface';

export const isPropertySavedByUser = (property: IProperty, userId: number): boolean => {
  const isSaved = property?.Bookmarks?.find((bookmark) => bookmark.userId == userId);
  return isSaved ? true : false;
};

export const getPropertyImages = (property?: IProperty): string[] => {
  return property?.Documents?.filter((doc) => doc.type.includes('image'))?.map((img) => img.url) ?? [];
};

export const filterPropertiesByStatus = (properties: IProperty[], status: IPropertyStatus): IProperty[] => {
  return properties.filter((property) => property.status == status);
};

export const getPropertyDocuments = (property?: IProperty): IProperty['Documents'] | undefined => {
  return property?.Documents?.filter((doc) => doc.type.includes('pdf'));
};
