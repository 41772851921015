import React, { FormEvent, useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getBankAccount } from "../../services/payment-profile.services";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { withdraw } from "../../services/wallet.services";

export default function Withdraw() {
  const account = useSelector((state: RootState) => state.paymentProfile.bankAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");

  const onSubmit = async function (e: FormEvent) {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Confirm",
      text: "Are you sure you want to withdraw from your wallet?",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = await dispatch(withdraw(amount));
        if (!data.error) {
          await Swal.fire({
            title: "Withdraw",
            text: "You have successfully withdrawn from your wallet",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
          });
          navigate("/owner/wallet");
        }
      }
    });
  };

  useEffect(() => {
    dispatch(getBankAccount());
  }, []);

  return (
    <DashboardLayout>
      <div className="dashboard">
        <h2>Withdraw Money</h2>
      </div>

      <Box>
        <Typography variant="subtitle2" component="h3" fontSize={"1.1rem"}>
          Bank Information
        </Typography>
        <Typography variant="subtitle2" component="h3" fontSize={".8rem"}>
          Confirm account information
        </Typography>

        <Grid container gap={2} sx={{ marginTop: 2 }}>
          <Grid item xs={5} md={3} sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Routing Number
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              variant="outlined"
              disabled
              value={account?.routingNumber as string}
              type="text"
              size="small"
            />
          </Grid>

          <Grid item xs={5} md={3} sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Account Number
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              variant="outlined"
              disabled
              value={account?.accountNumber}
              type="text"
              size="small"
            />
          </Grid>

          <Grid item xs={5} md={3} sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Account Holder Name
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              variant="outlined"
              disabled
              value={account?.accountHolderName}
              type="text"
              size="small"
            />
          </Grid>

          <Grid item xs={5} md={3} sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Country
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              variant="outlined"
              disabled
              value={account?.country as string}
              type="text"
              size="small"
            />
          </Grid>

          <Grid item xs={5} md={3} sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Currency
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              variant="outlined"
              disabled
              value={account?.currency as string}
              type="text"
              size="small"
            />
          </Grid>
        </Grid>

        <Box component={"form"} onSubmit={onSubmit} sx={{ marginTop: 2, maxWidth: "300px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
            <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
              Amount($)
            </Typography>
            <TextField
              InputProps={{ sx: { fontSize: ".8rem" } }}
              fullWidth
              variant="outlined"
              required={true}
              value={amount}
              placeholder="Enter Amount"
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              size="small"
            />
          </Box>

          <Button type="submit" variant="contained" size="small" sx={{ marginTop: 1 }}>
            Withdraw
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
