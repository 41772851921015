import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import { BiMenu } from "react-icons/bi";
import localStorageService from "../../../utils/localstorage.utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { logout } from "../../../services/auth.services";
import checkIsOwner from "../../../utils/user-groups.utils";
import { IUserGroup } from "../../../schema/interfaces/user.interface";
import Sublink from "./Sublinks";
import BetaButton from "./BetaButton";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { firstName, lastName } = useSelector((state: RootState) => state?.user);
  const dispatch = useDispatch();
  const screenQuery = useMediaQuery("(max-width:1000px)");

  const user = localStorageService.user.get();

  const isOwner = checkIsOwner();

  return (
    <div className="navbar-container">
      <nav className="nav">
        <Link to={"/"}>
          <img src="/images/ATPH-Logo.png" className="logo"></img>
        </Link>

        <div className={`links-container ${isOpen && `navbar-active`}`}>
          <header className="links-header">
            <img src="/images/ATPH-Logo.png" className="logo"></img>
            <span onClick={() => setIsOpen(false)}>
              <Close sx={{ cursor: "pointer" }} />
            </span>
          </header>
          <ul>
            {!screenQuery && <BetaButton />}
            <Link className="navLink" to="/">
              Home
            </Link>
            <Sublink
              title="About"
              className="navLink"
              sublinks={[
                { text: "About Us", href: "/about" },
                { text: "Blogs", href: "/blogs" },
                { text: "Contact", href: "/contact" },
              ]}
            />
            <Sublink
              title="Properties"
              className="navLink"
              sublinks={[
                { text: "Properties", href: "/properties" },
                { text: "Map", href: "/map" },
              ]}
            />
            {user && (
              <>
                <Link className="navLink" to={user?.isAdmin ? "/admin/dashboard" : isOwner ? "/owner/listings" : "/renter/dashboard"}>
                  Dashboard
                </Link>

                <p className="navLink" style={{ cursor: "pointer" }} onClick={() => dispatch(logout())}>
                  Logout
                </p>
              </>
            )}

            {!user && (
              <>
                <Link to={"/login"}>
                  <Button fullWidth={true} sx={{ width: "100%" }} variant="outlined">
                    LOGIN
                  </Button>
                </Link>
                <Link to="/registration">
                  <Button fullWidth={true} sx={{ width: "100%" }} variant="contained">
                    REGISTER
                  </Button>
                </Link>
              </>
            )}

            {user && (
              <Link to={user?.isAdmin ? "/admin/dashboard" : "/profile"}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    minWidth: "fit-content",
                  }}
                >
                  <img src={user?.profilePicture} className="Avatar"></img>
                  <Typography variant="subtitle1" sx={{ minWidth: "fit-content" }}>
                    {firstName ? `${firstName} ${lastName}` : `${user?.firstName} ${user?.lastName}`}
                  </Typography>
                </Box>
              </Link>
            )}
          </ul>
        </div>

        <Box sx={{ display: screenQuery ? "flex" : "none", alignItems: "center", gap: 1 }}>
          {screenQuery && <BetaButton />}
          <span className="menu-icon" onClick={() => setIsOpen(true)}>
            <Menu />
          </span>
        </Box>
      </nav>
    </div>
  );
};

export default Navbar;
