import { Box, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updatePropertyForm } from "../../redux/property-form.slice";

const Step8 = () => {
  const { minStay } = useSelector((state: RootState) => state.propertyForm);
  const dispatch = useDispatch();

  const updateMinStay = (minStay: string) => {
    dispatch(updatePropertyForm({ key: "minStay", value: parseInt(minStay) }));
  };

  return (
    <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={1.4}
        justifyContent="center"
        alignItems={"center"}
        maxWidth={450}
        marginX={"auto"}
        textAlign="center"
      >
        <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
          Minimum Stay Length
        </Typography>

        <Typography variant="subtitle1" component={"p"} lineHeight={1.2}>
          Why a minimum stay length? Traveling professionals need at least 1 month availability for their temporary housing (and often longer). A
          Traveled Path Homes directly serves these rent seekers, so we include a mininmum stay length for all properties to meet renter needs.
        </Typography>
      </Box>

      <Box
        width={"100%"}
        maxWidth={"300px"}
        height={"250px"}
        border={`2px solid ${blue[900]}`}
        marginX="auto"
        display="flex"
        marginTop={3}
        flexDirection="column"
        gap={3}
        justifyContent={"center"}
        alignItems="center"
        borderRadius={3}
        sx={{ cursor: "pointer" }}
      >
        <TextField
          type="number"
          sx={{
            border: "none",
            "& fieldset": { border: "none" },
            marginX: "auto",
          }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100,
              style: {
                padding: 6,
              },
            },
            sx: {
              textAlign: "right",
              width: "100px",
              border: "2px solid #333741",
              borderRadius: 2,
              fontSize: "20px",
            },
          }}
          value={minStay}
          onChange={(e) => updateMinStay(e.target.value)}
          required
        />

        <Typography variant="h5" fontWeight="bold">
          Month{"(s)"}
        </Typography>
      </Box>
    </Box>
  );
};

export default Step8;
