import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

interface Props {
  text: string;
}

export default function Title({ text }: Props) {
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  return (
    <Box display={"flex"} gap={2} alignItems={"center"} justifyContent={"space-between"}>
      <Box height={"3px"} sx={{ background: "#333741" }} width={"100%"} maxWidth={screenQuery ? "20%" : "25%"}></Box>
      <Typography variant={screenQuery ? "h6" : "h4"} textAlign={"center"} flex={1} color={"#333741"} fontWeight={"bold"}>
        {text}
      </Typography>
      <Box height={"3px"} sx={{ background: "#333741" }} width={"100%"} maxWidth={screenQuery ? "20%" : "25%"}></Box>
    </Box>
  );
}
