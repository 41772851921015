export enum WalletDirection {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum WalletStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}


export enum WalletTable {
  USER_PAYMENTS = "user-payments",
  USER_WITHDRAWALS = "user-withdrawals",
  USER_PROPERTIES_PAYMENTS = "user-properties-payments"
}