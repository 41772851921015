import { Box, Typography } from "@mui/material";
import React from "react";
import BlogTag from "./Tag";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

interface Props {
  imageStyle?: React.CSSProperties;
  src: string;
  title: string;
  date: string;
  tag?: string;
  link?: string;
}

export default function BlogBanner({ imageStyle = {}, src, title, date, tag, link }: Props) {
  return (
    <>
      <Box sx={{ position: "relative", height: "400px", width: "100%", marginBottom: 15 }} component={"header"}>
        <img
          src={`/images/blog/${src}`}
          style={{ width: "100%", height: "100%", borderRadius: 16, objectFit: "cover", objectPosition: "center", ...imageStyle }}
        />
        <Box
          sx={{
            position: "absolute",
            padding: 2,
            borderRadius: 2,
            background: "white",
            bottom: "0%",
            transform: "translateY(40%)",
            left: "30px",
            width: "80%",
            maxWidth: "400px",
            boxShadow: "0px 2px 3px rgba(0,0,0,.2)",
          }}
        >
          {tag && <BlogTag tag={tag} />}
          <Typography variant="h6" lineHeight={1.2} color="#333741" fontWeight="bold" marginTop={2}>
            {title}
          </Typography>

          <Typography variant="subtitle1" color="#333741" marginTop={2}>
            {date}
          </Typography>

          {link && (
            <Link to={link}>
              <Typography variant="body1" color="#53cbf0" sx={{ textDecoration: "underline" }} marginTop={1}>
                View Blog{" "}
                <span style={{ verticalAlign: "middle", marginLeft: "8px", display: "inline-block" }}>
                  <BsArrowRight size={20} />
                </span>
              </Typography>
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
}
