import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Container, Typography } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import BlogSubtitle from "../../components/Blog/Subtitle";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

export default function VisionaryJourneyOfSharpree() {
  return (
    <>
      <Navbar />
      <BlogTheme>
        <Container maxWidth="md" sx={{ paddingY: 5 }} component={"article"}>
          <BlogBanner
            src="visionary-journey-of-shapree-marshall.png"
            imageStyle={{ objectPosition: "center" }}
            title="Visionary Journey of Shapree' Marshall: Empowering Healthcare Heroes and Communities"
            date="December 12, 2023"
            tag="Journey"
          />

          <Typography component={"p"} variant="subtitle1">
            Dear Esteemed Community,
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            In these unprecedented times, the world has faced challenges that called for collective strength and resilience. Amidst the trials emerged
            a story of inspiration and dedication, embodied by our founder, Shapree' Marshall, and the inception of A Traveled Path Homes.
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            Shapree' Marshall's personal encounter with COVID-19 became the catalyst for a profound transformation. Her unwavering commitment to
            making a positive difference in the lives of frontline healthcare workers and the broader community sprang from her own battle with the
            virus.
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            Having experienced firsthand the struggles and sacrifices of healthcare professionals during emergencies, Shapree' recognized a pressing
            need for tailored accommodations. This realization birthed the vision behind A Traveled Path Homes — a mission-driven company dedicated to
            ensuring healthcare heroes have access to safe, comfortable, and convenient housing during emergency contracts.
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            More than just a response to critical shortages in the healthcare industry, A Traveled Path Homes stands firm in its commitment to
            equitable access for all community members. Shapree' Marshall's vision extends beyond providing housing solely for healthcare
            professionals; it encompasses a vision of unity and support for diverse communities, ensuring that everyone has a safe refuge during times
            of crisis. At A Traveled Path Homes, Shapree' Marshall's transformative journey from adversity to empowerment resonates deeply. Her
            leadership and unwavering dedication continue to guide our mission, leaving an indelible mark of compassion and service in the fabric of
            our company.
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            We invite you to join us in honoring the commitment to our healthcare heroes and the vision of equitable access for all. Together, let us
            pave a path towards a brighter, healthier future.
          </Typography>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
