import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreatePaymentProfileDTO, UpdateUserIdDTO } from '../schema/dto/payment-profile.dto';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import { uploadToCloud } from '../utils/upload-file';

export const createPaymentProfile: any = createAsyncThunk('createPaymentProfile', async function (data: CreatePaymentProfileDTO, thunkApi) {
  try {
    const response = await http.post(`/api/payment/profile`, { ...data });
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getPaymentProfile: any = createAsyncThunk('getPaymentProfile', async function (_, thunkApi) {
  try {
    const response = await http.get(`/api/payment/profile`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getBankAccount: any = createAsyncThunk('getBankAccount', async function (_, thunkApi) {
  try {
    const response = await http.get(`/api/payment/profile/bank`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const updateUserId: any = createAsyncThunk('updateUserId', async (data: UpdateUserIdDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Uploading Id'));

  if (data.url) {
    data.url = await uploadToCloud(data.url as File);
  }

  try {
    const response = await http.post('/api/payment/profile/document/id', data);
    dispatch(closePreloader({}));
    return response.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
