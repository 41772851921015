import { Box, Link, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  title: string;
  sublinks: { href: string; text: string }[];
  className: string;
}

const Sublink = ({ title, sublinks, className }: Props) => {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box
      sx={{ position: "relative", cursor: "pointer" }}
      className={className}
      component={"div"}
      onMouseEnter={() => !screenQuery && setIsOpen(true)}
      onMouseLeave={() => !screenQuery && setIsOpen(false)}
      onClick={() => screenQuery && setIsOpen(!isOpen)}
    >
      <Box component="p" sx={{ ...(screenQuery ? { justifyContent: "space-between", display: "flex", alignItems: "center" } : {}) }}>
        {title}
        {screenQuery && <Box component={"span"}>{isOpen ? <BiChevronUp size={23} /> : <BiChevronDown size={23} />}</Box>}
      </Box>

      {isOpen && screenQuery && (
        <Box paddingX={2}>
          {sublinks?.map((sublink, index) => {
            return (
              <Link
                component={RouterLink}
                key={index}
                to={sublink.href}
                sx={{ display: "block", paddingY: 1, textDecoration: "none", color: "#333741" }}
              >
                {sublink.text}
              </Link>
            );
          })}
        </Box>
      )}

      {isOpen && !screenQuery && (
        <Box
          sx={{
            position: "absolute",
            width: "150px",
            background: "#f9f9f9",
            top: "100%",
            left: "0%",
            boxShadow: "0px 2px 3px rgba(0,0,0,.1)",
            borderRadius: 2,
            overflow: "hidden",
            zIndex: 4,
          }}
        >
          {sublinks?.map((sublink, index) => {
            return (
              <Link
                component={RouterLink}
                to={sublink.href}
                key={index}
                sx={{
                  fontSize: ".8rem",
                  textDecoration: "none",
                  color: "#333741",
                  paddingX: 1,
                  paddingY: 1,
                  display: "block",
                  ":hover": { background: "#f3f3f3" },
                }}
              >
                {sublink.text}
              </Link>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default Sublink;
