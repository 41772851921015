import { HolidayVillage, Home } from "@mui/icons-material";
import { Box, CardHeader, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import SingleFamilyIcon from "../UI/Icons/SingleFamilyIcon";
import MultiFamilyIcon from "../UI/Icons/MultiFamilyIcon";
import TownhouseIcon from "../UI/Icons/TownhouseIcon";
import ApartmentIcon from "../UI/Icons/ApartmentIcon";
import CondoIcon from "../UI/Icons/CondoIcon";
import { blue } from "@mui/material/colors";
import { IPropertyType } from "../../schema/interfaces/property.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updatePropertyForm } from "../../redux/property-form.slice";

const propertyTypes = [
  {
    icon: <SingleFamilyIcon />,
    text: IPropertyType.singleFamily,
  },

  {
    icon: <MultiFamilyIcon />,
    text: IPropertyType.multiFamily,
  },
  {
    icon: <TownhouseIcon />,
    text: IPropertyType.townhouse,
  },
  {
    icon: <ApartmentIcon />,
    text: IPropertyType.apartment,
  },
  {
    icon: <CondoIcon />,
    text: IPropertyType.condo,
  },
];

// Description
const Step2 = () => {
  const dispatch = useDispatch();
  const { type, name, description } = useSelector((state: RootState) => state?.propertyForm);

  const selectType = (type: string) => {
    dispatch(updatePropertyForm({ key: "type", value: type }));
  };

  const updateName = (name: string) => {
    dispatch(updatePropertyForm({ key: "name", value: name }));
  };

  const updateDesc = (desc: string) => {
    dispatch(updatePropertyForm({ key: "description", value: desc }));
  };

  return (
    <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={1.4}
        justifyContent="center"
        alignItems={"center"}
        maxWidth={400}
        marginX={"auto"}
        textAlign="center"
      >
        <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
          Describe
        </Typography>

        <Typography variant="subtitle1" component={"p"}>
          Select your property type and write a short description.
        </Typography>
      </Box>

      <Box display="flex" gap={2} justifyContent={"center"} alignItems={"center"} marginTop={4} flexWrap={"wrap"}>
        {propertyTypes?.map((propertyType, index) => {
          return (
            <Box key={index} display="flex" flexDirection="column" gap={0.8} alignItems="center" justifyContent="center">
              <Box
                width={60}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={60}
                border={`2px solid ${blue[900]}`}
                borderRadius={"8px"}
                sx={{
                  background: type === propertyType.text ? blue[50] : "white",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: `${blue[50]}`,
                  },
                }}
                onClick={() => selectType(propertyType.text)}
              >
                {propertyType.icon}
              </Box>

              <Typography variant="body1" textAlign="center" minWidth={"fit-content"}>
                {propertyType.text}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Stack
        gap={4}
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "500px",
          marginX: "auto",
          marginTop: 2,
        }}
      >
        <Box>
          <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
            Name
          </label>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            value={name}
            onChange={(e) => updateName(e.target.value)}
            sx={{ marginTop: 1.4 }}
            label="Property Name"
            required
          />
        </Box>

        <Box>
          <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
            Description
          </label>
          <TextField
            variant="outlined"
            type="text"
            value={description}
            onChange={(e) => updateDesc(e.target.value)}
            fullWidth
            sx={{ marginTop: 1.4 }}
            required
            multiline={true}
            rows={5}
            InputProps={{
              placeholder:
                "Include facts about the neighbourhood, general information about amenitites, and details about the property that make it a home away from home",
            }}
            label="Property Description"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Step2;
