import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../components/UI/Navbar/Navbar";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import PropertyBasicInfo from "../components/PropertyDetails/PropertyBasicInfo";
import PropertyAmenities from "../components/PropertyDetails/PropertyAmenities";
import AboutProperty from "../components/PropertyDetails/AboutProperty";
import BookProperty from "../components/PropertyDetails/BookProperty";
import Footer from "../components/UI/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProperty } from "../services/property.services";
import { useParams } from "react-router-dom";
import PropertyAmenitiesDetails from "../components/PropertyDetails/PropertyAmenitiesDetails";
import PropertyUserInfo from "../components/PropertyDetails/PropertyUserInfo";
import AvailableReservations from "../components/PropertyDetails/AvailableReservations";
import { RootState } from "../redux/store";
import localStorageService from "../utils/localstorage.utils";
import { BiLoaderAlt } from "react-icons/bi";
import CustomLoader from "../components/UI/CustomLoader";
import secrets from "../constants/secrets.const";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { IMapCenter } from "../schema/interfaces/map.interface";

const PropertyDetails = () => {
  const dispatch = useDispatch();
  const { isLoaded, loadError, url } = useLoadScript({
    googleMapsApiKey: secrets.googleMapApiKey as string,
  });
  // kansas coordinates!!!
  const center = useMemo(() => ({ lat: 38.27312, lng: -98.5821872 }), []);
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>(undefined);
  const property = useSelector((state: RootState) => state.properties.singleProperty);
  const user = localStorageService.user.get();
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  const setMapCenter = (data: IMapCenter) => {
    mapRef?.setCenter({ lng: data.lng, lat: data.lat });
  };

  const onLoad = (map: google.maps.Map) => {
    console.log("logeddf");
    setMapRef(map);
  };

  const fetchProperty = async () => {
    setLoading(true);
    await dispatch(getSingleProperty(id));
    setLoading(false);
  };

  useEffect(() => {
    fetchProperty();
  }, []);

  const position = useMemo(
    () => (property ? { lng: parseFloat(property?.longitude?.toString()), lat: parseFloat(property?.latitude?.toString()) } : { lng: 0, lat: 0 }),
    [property]
  );

  return (
    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} sx={{ minHeight: "100vh" }}>
      <Navbar />

      <Container maxWidth="lg" sx={{ paddingY: 4, flex: 1 }}>
        {loading ? (
          <CustomLoader loading={loading} />
        ) : (
          <Grid container justifyContent="space-between" gap={4}>
            {/* Left Flex (Property Details) */}
            <Grid item sm={12} md={5} lg={3.5} sx={{ width: "100%" }}>
              <PropertyBasicInfo />
              <PropertyUserInfo />

              {(property?.Amenities?.length as number) > 0 && <PropertyAmenities />}
            </Grid>

            {/* Right Flex (About the property) */}
            <Grid item sm={12} md={5} lg={5.5} sx={{ width: "100%" }}>
              <AboutProperty />
              {(property?.Amenities?.length as number) > 0 && <PropertyAmenitiesDetails />}
              {property && (
                <Box marginY={3}>
                  <GoogleMap
                    onLoad={onLoad}
                    mapContainerStyle={{ width: "100%", height: "300px" }}
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={7}
                  >
                    <MarkerF position={position}></MarkerF>
                  </GoogleMap>
                </Box>
              )}
              {(property?.Reservations?.length as number) > 0 && <AvailableReservations />}
              <BookProperty />
            </Grid>
          </Grid>
        )}
      </Container>

      <Footer />
    </Box>
  );
};

export default PropertyDetails;
