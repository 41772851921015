import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Grid } from "@mui/material";
import ResidencesItem from "../../UI/ResidencesItem";

export default function PropertiesGrid() {
  const { properties } = useSelector((state: RootState) => state.properties);

  return (
    <Grid gap={2} flex={1} container marginTop={4}>
      {properties?.map((property, index) => {
        return <ResidencesItem key={index} {...property} />;
      })}
    </Grid>
  );
}
