import React, { useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Step1 from "../../components/BecomeOwner/Step1";
import Step2 from "../../components/BecomeOwner/Step2";

export default function BecomeOwner() {
  const [step, setStep] = useState<number>(0);
  const dispatch = useDispatch();

  const updateStep = () => {
    setStep(step === 0 ? 1 : 0);
  };

  return (
    <DashboardLayout>
      <Box component="header" gap={4}>
        <Typography variant="h5" fontWeight={"bold"}>
          Become an owner
        </Typography>

        <Typography variant="subtitle1" fontSize=".8rem" fontWeight="medium">
          {step === 0 ? "Add your profile information" : "Add your bank information to receive payouts"}
        </Typography>
      </Box>
      <>
        {step === 0 && <Step1 updateStep={updateStep} />}
        {step === 1 && <Step2 updateStep={updateStep} />}
      </>
    </DashboardLayout>
  );
}
