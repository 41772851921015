import { createAsyncThunk } from '@reduxjs/toolkit';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { IUser } from '../schema/interfaces/user.interface';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import { EditProfileDTO, EditUserStatusDTO, GetAllUsersDTO } from '../schema/dto/profile.dto';
import { uploadToCloud } from '../utils/upload-file';
import { stringify } from 'querystring';
import { PaginatedResponse } from '../schema/dto/query.dto';
import { RawAxiosRequestHeaders } from 'axios';

export const getProfile: any = createAsyncThunk('getProfile', async (token: string, thunkApi) => {
  try {
    const headers: RawAxiosRequestHeaders = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await http.get<IUser>('/api/profile', { headers });
    return response?.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const editProfile: any = createAsyncThunk('editProfile', async (data: EditProfileDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Editing Profile'));

  if (data?.profilePicture && typeof data?.profilePicture != 'string') {
    // i.e a new file has been selected
    data.profilePicture = await uploadToCloud(<File>data?.profilePicture);
  }

  try {
    const response = await http.put<IUser>('/api/profile', data);
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const becomeAnOwner: any = createAsyncThunk('becomeAnOwner', async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Loading'));

  try {
    const response = await http.post('/api/profile/owner');
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getAllUsers: any = createAsyncThunk('getAllUsers', async (query: GetAllUsersDTO, thunkApi) => {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);

  try {
    const response = await http.get<PaginatedResponse<IUser>>(`/api/profile/admin?${queryString}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const updateUserStatus: any = createAsyncThunk('updateUserStatus', async (data: EditUserStatusDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Updating user status'));

  try {
    const response = await http.put(`/api/profile/admin/${data.id}/status`, { isActive: data.isActive });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
