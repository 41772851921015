import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TableHeader, TableHeaderEntry, TableLayout, TableRowEntry, TableRowsContainer, TableRow } from "../UI/Table";
import { getPropertyImages } from "../../utils/property.utils";
import { getDateAndMonth } from "../../utils/date.utils";
import { getUserPayments, getUserPropertiesPayments } from "../../services/payment.services";
import PaymentStatus from "../Admin/Payment/PaymentStatus";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { PaginatedResponse } from "../../schema/dto/query.dto";
import { IPayment } from "../../schema/interfaces/payment.interface";
import PaginationContainer from "../UI/Pagination";
import { shortenText } from "../../utils/text.utils";

const headers = [
  { name: "Reservation", width: 250 },
  { name: "User", width: 250 },
  { name: "Amount", width: 100 },
  { name: "Date", width: 200 },
  { name: "Status", width: 100 },
];

export default function UserPropertiesPayment() {
  const payments = useSelector((state: RootState) => state?.payment?.userPropertiesPayments);
  const [page, setPage] = useState<number>(1);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getPayments = async function () {
      const data: ActionDispatchResponse<PaginatedResponse<IPayment>> = await dispatch(getUserPropertiesPayments({ page }));
      if (!data.error) {
        setPagesCount(data?.payload?.totalPages);
      }
    };

    getPayments();
  }, [page]);
  return (
    <Box component="section">
      <TableLayout>
        <TableHeader>
          {headers.map(({ width, name }, index) => (
            <TableHeaderEntry width={width} key={index}>
              {name}
            </TableHeaderEntry>
          ))}
        </TableHeader>

        <TableRowsContainer>
          {payments?.map((payment, index) => {
            const image = getPropertyImages(payment?.Reservation?.Property)?.[0];

            return (
              <TableRow key={index} InnerProps={{ style: { alignItems: "center" } }}>
                <TableRowEntry width={headers[0]?.width}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Box
                      component="img"
                      src={image}
                      alt={payment?.Reservation?.Property?.name}
                      sx={{ width: "30px", height: "30px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
                    />
                    <Box sx={{ flex: 1, maxWidth: "100%" }}>
                      <Typography
                        variant="body1"
                        component="h3"
                        fontWeight={"bold"}
                        lineHeight={1.2}
                        fontSize=".8rem"
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 }}
                      >
                        {shortenText(payment?.Reservation?.Property?.name, 25)}
                      </Typography>

                      <Typography
                        variant="body1"
                        component="h3"
                        lineHeight={1}
                        fontSize=".7rem"
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 }}
                      >
                        <>
                          {shortenText(
                            `${getDateAndMonth(payment?.Reservation?.startDate as Date)} - ${getDateAndMonth(payment?.Reservation?.endDate as Date)}`,
                            25
                          )}
                        </>
                      </Typography>
                    </Box>
                  </Box>
                </TableRowEntry>

                <TableRowEntry width={headers[1]?.width}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Box
                      component="img"
                      src={payment?.User?.profilePicture}
                      alt={payment?.User?.firstName}
                      sx={{ width: "30px", height: "30px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
                    />
                    <Box sx={{ flex: 1, maxWidth: "100%" }}>
                      <Typography
                        variant="body1"
                        component="h3"
                        fontWeight={"bold"}
                        lineHeight={1.2}
                        fontSize=".8rem"
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 }}
                      >
                        {payment?.User?.firstName} {payment?.User?.lastName}
                      </Typography>
                    </Box>
                  </Box>
                </TableRowEntry>

                <TableRowEntry width={headers[2]?.width}>
                  <>${Math.round(payment?.amountReceived / 100).toLocaleString()}</>
                </TableRowEntry>
                <TableRowEntry width={headers[3]?.width}>{getDateAndMonth(payment?.updatedAt)}</TableRowEntry>
                <TableRowEntry width={headers[4]?.width}>
                  <PaymentStatus status={payment?.status} />
                </TableRowEntry>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>

      <Box sx={{ marginTop: 4, display: "block", maxWidth: "fit-content", marginLeft: "auto" }}>
        <PaginationContainer length={pagesCount} customUpdate={(page) => setPage(page)} />
      </Box>
    </Box>
  );
}
