import { Box, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";
import { ArrowBack } from "@mui/icons-material";
import SingleChat from "./SingleChat";
import MessageBox from "./MessageBox";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentConversation, updateMessages } from "../../redux/chat.slice";
import { RootState } from "../../redux/store";
import { getConversationOtherUser } from "../../utils/chat.utils";
import { getMessages } from "../../services/chat.services";
import { Socket } from "socket.io-client";
import useSocket from "../../hooks/useSocket";
import { IMessage } from "../../schema/interfaces/chat.interface";

export default function ChatRoom() {
  const { currentConversation, messages } = useSelector((state: RootState) => state?.chat);
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const dispatch = useDispatch();
  const user = useMemo(() => getConversationOtherUser(currentConversation?.Users), [currentConversation]);
  const chatBoxRef = useRef<HTMLElement | null>(null);
  const socket = useSocket([currentConversation]);

  const onCancel = () => {
    dispatch(updateCurrentConversation(undefined));
  };

  useEffect(() => {
    if (currentConversation) {
      dispatch(getMessages());
    }
  }, [currentConversation?.id]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      // get the height and scroll down with that height
      const height = chatBoxRef.current?.getBoundingClientRect().height as number;
      chatBoxRef.current?.parentElement?.scroll({
        top: Math.pow(height, 2),
        behavior: "smooth",
      });
    }
  }, [currentConversation, messages]);

  useEffect(() => {
    let added = false;
    socket?.on("message", (message: IMessage) => {
      if (message && message.conversationId === currentConversation?.id) {
        if (!messages.find((prevMessage) => prevMessage.id === message.id)) {
          if (!added) {
            dispatch(updateMessages(message));
            added = true;
          }
        }
      }
    });
    added = false;
  }, []);

  return (
    <Box
      component={"section"}
      padding={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...(!screenQuery
          ? {
              borderRadius: 2.4,
              boxShadow: "0px 2px 3px  #c1bcbc",
              border: "1.5px solid #c1bcbc",
            }
          : {}),
      }}
    >
      {!currentConversation ? (
        <Box sx={{ height: "100%", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            Start a conversation by selecting one from the list
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            component="header"
            display="flex"
            alignItems="center"
            gap={2}
            paddingY={1.4}
            paddingX={1.5}
            sx={{ background: "#eaeaea", borderRadius: 2 }}
          >
            {/* Display back icon on mobile */}
            {screenQuery && (
              <IconButton size="small" onClick={onCancel}>
                <ArrowBack />
              </IconButton>
            )}

            <img
              src={user?.profilePicture}
              style={{ width: "45px", height: "45px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
            />
            <Typography variant="subtitle1" fontSize="1rem" fontWeight="bold">
              {user?.firstName} {user?.lastName}
            </Typography>
          </Box>

          <Box sx={{ minHeight: "calc(100vh - 350px)", maxHeight: "calc(100vh - 350px)", overflowY: "scroll" }}>
            <Stack
              component="section"
              flexDirection="column"
              gap={1}
              flex={1}
              ref={chatBoxRef}
              borderTop={"1.5px solid #c1bcbc"}
              marginTop={2}
              height={"100%"}
            >
              {messages?.map((message, index) => {
                return <SingleChat key={index} {...message} />;
              })}
            </Stack>
          </Box>

          <MessageBox socket={socket} />
        </>
      )}
    </Box>
  );
}
