import React, { useState } from "react";
import { IUser } from "../../../schema/interfaces/user.interface";
import { TableRow, TableRowEntry } from "../../UI/Table";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import UserStatus from "../../UI/UserStatus";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import SendMessageModal from "../../PropertyDetails/SendMessage";

interface Props {
  user: IUser;
}

export default function UserRow({ user }: Props) {
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);

  return (
    <Box borderBottom={screenQuery ? "1.5px solid lightgray" : "0px"}>
      <SendMessageModal isOpen={messageModalOpen} closeModal={() => setMessageModalOpen(false)} user={user} />

      <TableRow
        InnerProps={{
          style: { alignItems: "center", borderBottom: screenQuery ? "0px" : "1.5px solid lightgray" },
          onClick: screenQuery ? () => setIsOpen(!isOpen) : () => {},
        }}
      >
        <TableRowEntry width={180}>
          <Box component={"p"} display={"flex"} alignItems="center" gap={0.8}>
            <img
              src={user?.profilePicture}
              style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: "cover", objectPosition: "center" }}
              alt=""
            />{" "}
            {user?.firstName} {user?.lastName}
          </Box>
        </TableRowEntry>

        {!screenQuery && (
          <>
            <TableRowEntry width={180} InnerProps={{ style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } }}>
              {user?.email}
            </TableRowEntry>
            <TableRowEntry width={150}>{user?.username}</TableRowEntry>
            <TableRowEntry component={"div"} width={150}>
              <UserStatus user={user} />
            </TableRowEntry>
            <TableRowEntry width={150}>
              <Button onClick={() => setMessageModalOpen(true)} variant="contained" size="small">
                Send Message
              </Button>
            </TableRowEntry>
          </>
        )}

        {screenQuery && (
          <Box display="flex" alignItems="center" gap={2}>
            <Typography
              variant="body2"
              sx={{
                paddingY: 0.8,
                paddingX: 1.4,
                textAlign: "center",
                width: "70px",
                borderRadius: "15px",
                fontSize: ".6rem",
                fontWeight: "bold",
                color: user?.isActive ? green[800] : red[800],
                backgroundColor: user?.isActive ? green[200] : red[200],
              }}
            >
              {user.isActive ? "Active" : "Disabled"}
            </Typography>
            {!isOpen ? <ExpandMore cursor="pointer" /> : <ExpandLess cursor="pointer" />}
          </Box>
        )}
      </TableRow>

      {screenQuery && (
        <Box sx={{ overflow: "hidden", height: isOpen ? "140px" : "0px", transition: "all 400ms" }}>
          <Box padding={1}>
            <Typography variant="body1" fontSize={".8rem"} marginBottom={0.5}>
              <b>Email: </b> {user?.email}
            </Typography>
            <Typography variant="body1" fontSize={".8rem"} marginBottom={0.5}>
              <b>Username: </b> {user?.username}
            </Typography>
            <Box sx={{ maxWidth: "210px", marginTop: 1 }}>
              <UserStatus user={user} />
            </Box>
            <Button onClick={() => setMessageModalOpen(true)} variant="contained" size="small" sx={{ marginTop: 1 }}>
              Send Message
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
