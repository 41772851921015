import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Box, Container, Grid } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import { blogData } from "../../assets/data/blog.data";
import SingleBlog from "../../components/Blog/SingleBlog";
import Banner from "../../components/UI/Banner";

export default function Blog() {
  return (
    <>
      <Navbar />
      <Banner text="Blogs" />
      <BlogTheme>
        <Container maxWidth="lg" sx={{ paddingY: 5 }}>
          {/* <BlogBanner
            src="get-your-shot.png"
            imageStyle={{ objectPosition: "top" }}
            title="Taking the First Step: Why Getting the COVID-19 Shot Matters"
            date="December 12, 2023"
            tag="Health"
            link="/blogs/get-your-shot"
          /> */}

          <Grid gap={2} flex={1} container marginTop={4}>
            {blogData?.map((blog, index) => {
              return <SingleBlog key={index} {...blog} />;
            })}
          </Grid>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
