import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          marginBottom: 2,
          color: "#555",
        }}
      >
        404 - Not Found
      </Typography>
      <Typography
        variant="h5"
        sx={{
          marginBottom: 2,
          color: "#555",
        }}
      >
        Oops! The page you are looking for does not exist
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/"
        color="primary"
        sx={{
          marginTop: 2,
        }}
      >
        Go Home
      </Button>
    </Container>
  );
};

export default Error404;
