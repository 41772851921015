import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { logout } from "../../services/auth.services";
import { Logout } from "@mui/icons-material";

const LogoutButton = () => {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  const dispatch = useDispatch();

  const onClick = () => dispatch(logout());

  return (
    <Box
      display="flex"
      gap={2}
      alignItems={"center"}
      sx={{ cursor: "pointer" }}
      paddingY={1}
      paddingX={2}
      width={"100%"}
      maxWidth={screenQuery ? "100%" : "250px"}
      marginX={"auto"}
      onClick={onClick}
    >
      <Logout />
      <Typography variant="body1" fontWeight={"bold"}>
        Log Out
      </Typography>
    </Box>
  );
};

export default LogoutButton;
