import React from "react";
import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import Amenity from "../AddPropertySteps/Amenity";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const AboutProperty = () => {
  const property = useSelector((state: RootState) => state.properties?.singleProperty);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 4,
        paddingX: 3,
        paddingBottom: 2,
        paddingTop: 1,
      }}
    >
      <Typography variant="subtitle1" fontWeight={600} component="p" textAlign="center" marginX="auto" fontSize={14}>
        About This Stay
      </Typography>

      <Typography variant="body1" marginTop={1} component="p" textAlign="center" marginX="auto" fontSize={14}>
        $ {property?.monthlyPrice}/month • {property?.minStay} Months Stay Minimum
      </Typography>

      <hr
        style={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "lightgray",
          height: "2px",
        }}
      />

      <Typography variant="body1" component="p" textAlign="center" marginX="auto" fontWeight="bold" fontSize={14}>
        We’ve got you covered with all the comforts of home
      </Typography>

      <Box display="flex" gap={1} flexWrap="wrap" marginX={"auto"} justifyContent={"center"} alignItems={"center"} marginTop={1.3}>
        {property?.Amenities?.map((amenity, index) => {
          return <Amenity amenity={{ text: "", icon: amenity?.icon }} key={index} />;
        })}
      </Box>
    </Paper>
  );
};

export default AboutProperty;
