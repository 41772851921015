import { Close } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { DateRange, DayPicker } from "react-day-picker";

interface Props {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  closeModal(): void;
  type: "endDate" | "startDate";
}

function BookPropertyDate({ date, setDate, closeModal, type }: Props) {
  return (
    <Modal open={true} onClose={closeModal} aria-labelledby="modal-modal-title" aria-desribedby="modal-modal-description">
      <Box
        sx={{
          width: "90vw",
          maxWidth: "350px",
          marginX: "auto",
          backgroundColor: "white",
          borderRadius: 3,
          padding: 2,
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <Box component="header" display="flex" justifyContent={"space-between"} gap={4} alignItems="center">
          <Typography variant="h5">{type === "startDate" ? "Start Date" : "End Date"}</Typography>
          <Close cursor="pointer" onClick={closeModal} />
        </Box>

        <Box width="100%">
          <DayPicker
            fromYear={new Date().getFullYear()}
            toYear={new Date().getFullYear() + 4}
            selected={date}
            captionLayout="dropdown"
            mode="single"
            defaultMonth={new Date()}
            onSelect={(date) => {
              setDate(date as Date);
              closeModal();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default BookPropertyDate;
