import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';
import errorHandler from '../utils/error-handler.utils';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import { uploadToCloud } from '../utils/upload-file';
import { IOwnershipDocuments, IOwnershipDocumentsFile, IProperty } from '../schema/interfaces/property.interface';
import http from '../axios.config';
import { IAmenties } from '../schema/interfaces/amenities.interface';
import { PROPERTY_IMAGE } from '../constants/property-image.const';
import { EditPropertyStatusDTO, GetAdminPropertiesDTO, PropertyFilterDTO } from '../schema/dto/property.dto';
import { stringify } from 'querystring';
import { PaginatedResponse } from '../schema/dto/query.dto';

export const addProperty: any = createAsyncThunk('addProperty', async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Adding Property'));

  /**
   * Upload the images
   * Upload the documents
   */
  const state = thunkApi.getState() as RootState;
  const property = state?.propertyForm;
  const propertyPhotos = property?.photos || [];
  const propertyDocs = property.documents || [];

  const documents: IOwnershipDocuments[] = [];
  for (const image of propertyPhotos) {
    const url = await uploadToCloud(image);
    documents.push({
      name: (image as File).name,
      url,
      type: (image as File).type,
    });
  }

  // if there are no images, add a default image
  if (documents.length === 0) {
    documents.push({
      name: 'Dummy Image',
      url: PROPERTY_IMAGE,
      type: 'image/jpeg',
    });
  }

  for (let doc of propertyDocs) {
    if ((doc as IOwnershipDocumentsFile)?.file) {
      // set the type
      doc = doc as IOwnershipDocumentsFile;
      const url = await uploadToCloud(doc?.file as File);
      documents.push({
        url,
        type: doc.file?.type as string,
        name: doc?.name,
      });
    }
  }

  // refactor amenities
  const amenities: Omit<IAmenties, 'icon' | 'text'>[] = [];

  for (const amenity of property.amenities) {
    amenities.push({ id: amenity.id, description: amenity.description });
  }

  const data = {
    name: property.name,
    description: property.description,
    documents,
    amenities,
    address: property.address,
    addressNo: 5,
    city: property.city,
    state: property.state,
    zipCode: property.zipCode,
    dailyPrice: property.dailyPrice,
    monthlyPrice: property.monthlyPrice,
    type: property.type,
    guest: property.guest,
    beds: property.beds,
    bedroom: property.bedroom,
    bathroom: property.bathroom,
    minStay: property.minStay,
    longitude: property.longitude,
    latitude: property.latitude,
  };

  try {
    const response = await http.post('/api/property', data);
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getAmenities: any = createAsyncThunk('getAmenities', async (_, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Fetching amenities'));

  try {
    const { data } = await http.get<IAmenties[]>('/api/amenity');
    dispatch(closePreloader({}));
    return data;
  } catch (error: any) {
    return errorHandler(thunkApi, error);
  }
});

export const getProperties: any = createAsyncThunk('getProperties', async (query: PropertyFilterDTO, thunkApi) => {
  let queryString = '';

  if (query) {
    const queryClone: PropertyFilterDTO = {};
    Object.entries(query).forEach(([key, value], idx) => {
      if (value && value != 'undefined') {
        queryClone[key as keyof PropertyFilterDTO] = value;
      }
    });
    queryString = `?${stringify(queryClone as any)}`;
  }

  try {
    const { data } = await http.get<IProperty[]>('/api/property' + queryString);

    return data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getUserProperties: any = createAsyncThunk('getUserPropertis', async (_, thunkApi) => {
  try {
    const { data } = await http.get<IProperty[]>('/api/property/user');

    return data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getSingleProperty: any = createAsyncThunk('getSingleProperty', async (id: number, thunkApi) => {
  try {
    const { data } = await http.get<IProperty>(`/api/property/${id}`);

    return data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const deleteProperty: any = createAsyncThunk('deleteProperty', async (id: number, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Deleting Property'));

  try {
    const { data } = await http.delete(`/api/property/${id}`);
    dispatch(closePreloader({}));
    return data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const updatePropertyStatus: any = createAsyncThunk('editProperty', async (data: EditPropertyStatusDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Updating property status'));

  try {
    const response = await http.put(`/api/property/admin/${data.id}/status`, { status: data.status });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getAdminProperties: any = createAsyncThunk('getAdminProperties', async (query: GetAdminPropertiesDTO, thunkApi) => {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);

  try {
    const { data } = await http.get<PaginatedResponse<IProperty>>(`/api/property/admin?${queryString}`);
    return data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getSingleDocument: any = createAsyncThunk('getSingleDocument', async (id: number, thunkApi) => {
  try {
    const response = await http.get(`/api/property/document/${id}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
