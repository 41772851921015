import React, { ReactElement, useEffect, useState } from "react";
import DashboardNavbar from "./DashboardNavbar";
import { Box, useMediaQuery } from "@mui/material";
import Sidebar from "./Sidebar";
import { Location, Navigate, useLocation } from "react-router-dom";
import localStorageService from "../../utils/localstorage.utils";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../../redux/handlers.slice";

import ProtectedProvider from "../Providers/ProtectedProvider";
import { UserGroups } from "../../schema/enums/groups.enum";
import { IUser } from "../../schema/interfaces/user.interface";
import { ownerLinks, renterLinks, adminLinks } from "../../assets/data/links.data";
import { Link } from "../../schema/interfaces/link.interface";

interface Props {
  children?: ReactElement | ReactElement[];
}

const getDashboardLink = (currentGroup?: UserGroups, user?: IUser): Link[] => {
  if (user?.isAdmin) return adminLinks;
  else {
    if (currentGroup === UserGroups.OWNER) return ownerLinks;
    else if (currentGroup === UserGroups.RENTER) return renterLinks;
    else return [];
  }
};

function DashboardLayout({ children }: Props) {
  const location = useLocation();
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false);
  const userGroup = localStorageService.currentGroup.get();
  const user = localStorageService.user.get();
  const [links, setLinks] = useState<Link[]>(getDashboardLink(userGroup, user));

  const screenQuery = useMediaQuery("(max-width:1000px)");

  useEffect(() => {
    setLinks(getDashboardLink(userGroup, user));
  }, [location.pathname, user]);

  return (
    <ProtectedProvider>
      <main
        style={{
          maxHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <DashboardNavbar
          sidebarOpened={sidebarOpened}
          toggleSidebar={() => {
            setSidebarOpened(!sidebarOpened);
          }}
        />

        <Box
          position="relative"
          flex={1}
          style={{
            height: "calc(100vh - 75px)",
            maxHeight: "calc(100vh - 75px)",
          }}
        >
          <Sidebar sidebarOpened={sidebarOpened} links={links} />
          <Box
            marginLeft={screenQuery ? "0px" : "300px"}
            style={{
              maxHeight: "100%",
              overflowY: "scroll",
              height: "100%",
            }}
            padding={2}
          >
            {children}
          </Box>
        </Box>
      </main>
    </ProtectedProvider>
  );
}

export default DashboardLayout;
