import { AcUnit, Bathtub, DirectionsCar, Fireplace, LocalFireDepartment, LocalLaundryService, Pets, Shower, Tv, Wifi } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Amenity from "./Amenity";
import { blue } from "@mui/material/colors";
import { IAmenties } from "../../schema/interfaces/amenities.interface";
import AmenityDescriptionModal from "./AmenityDescriptionModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addAmenity, removeAmenity, updateAmenityDescription } from "../../redux/property-form.slice";
import { getAmenities } from "../../services/property.services";

/**
 * Once they click on an amenity, set a state to the amenity that's clicked and open a modal to enter its description
 * After entering the description, add it to the list of amenities
 */

const Step5 = () => {
  const { amenities } = useSelector((state: RootState) => state?.propertyForm);
  const dbAmenities = useSelector((state: RootState) => state.amenities.amenties);
  const [amenityDescriptionModalOpened, setModalOpened] = useState<boolean>(false);
  const [amenityToBeDescribed, setAmenityToBeDescribed] = useState<IAmenties | undefined>(undefined);
  const dispatch = useDispatch();

  const closeModal = () => setModalOpened(false);
  const openModal = () => setModalOpened(true);
  const checkIsSelected = (text: string): boolean => {
    return amenities.find((amenity) => amenity?.text === text) ? true : false;
  };
  const updateDesc = (value: string) => {
    setAmenityToBeDescribed({ ...(amenityToBeDescribed as IAmenties), description: value });
    dispatch(updateAmenityDescription({ text: amenityToBeDescribed?.text, value }));
  };
  const removeOne = (text: string) => {
    dispatch(removeAmenity({ text }));
    closeModal();
  };
  // for the modal
  const onSuccess = () => {
    const isSelected = checkIsSelected(amenityToBeDescribed?.text as string);
    if (!isSelected) dispatch(addAmenity(amenityToBeDescribed as IAmenties));
    closeModal();
    setAmenityToBeDescribed(undefined);
  };

  const onClose = () => {
    closeModal();
    setAmenityToBeDescribed(undefined);
  };

  useEffect(() => {
    // fetch amenities from db
    dispatch(getAmenities());
  }, []);

  return (
    <>
      {" "}
      {amenityDescriptionModalOpened && (
        <AmenityDescriptionModal
          onSuccess={onSuccess}
          onClose={onClose}
          amenity={amenityToBeDescribed}
          updateDesc={updateDesc}
          removeOne={removeOne}
          checkIsSelected={checkIsSelected}
        />
      )}
      <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={1.4}
          justifyContent="center"
          alignItems={"center"}
          maxWidth={450}
          marginX={"auto"}
          textAlign="center"
        >
          <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
            Amenities
          </Typography>

          <Typography variant="subtitle1" component={"p"}>
            Click on an amenity to add it to your property. You'll be able to add more details after you've added your property.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            marginX: "auto",
            marginTop: 2,
          }}
        >
          <Box marginTop={2} alignSelf={"flex-start"}>
            <Typography fontWeight="bold" variant="h6" marginBottom={1}>
              Essentials
            </Typography>

            <Box display="flex" gap={2} flexWrap={"wrap"}>
              {dbAmenities?.slice(0, 5)?.map((amenity, index) => {
                const isSelected = checkIsSelected(amenity.text as string);

                return (
                  <Amenity
                    key={index}
                    isSelected={isSelected}
                    amenity={{ icon: amenity.icon, text: amenity.text }}
                    toggle={() => {
                      setAmenityToBeDescribed(amenity);
                      openModal();
                    }}
                  />
                );
              })}
            </Box>
          </Box>

          <Box marginTop={2} alignSelf={"flex-start"}>
            <Typography fontWeight="bold" variant="h6" marginBottom={1}>
              Heating And Cooling
            </Typography>

            <Box display="flex" gap={2} flexWrap={"wrap"}>
              {dbAmenities?.slice(5, 8).map((amenity, index) => {
                const isSelected = checkIsSelected(amenity.text as string);
                return (
                  <Amenity
                    key={index}
                    isSelected={isSelected}
                    amenity={{ icon: amenity.icon, text: amenity.text }}
                    toggle={() => {
                      setAmenityToBeDescribed(amenity);
                      openModal();
                    }}
                  />
                );
              })}
            </Box>
          </Box>

          <Box marginTop={2} alignSelf={"flex-start"}>
            <Typography fontWeight="bold" variant="h6" marginBottom={1}>
              Technology
            </Typography>

            <Box display="flex" gap={2} flexWrap={"wrap"}>
              {dbAmenities?.slice(8, 10).map((amenity, index) => {
                const isSelected = checkIsSelected(amenity.text as string);
                return (
                  <Amenity
                    key={index}
                    isSelected={isSelected}
                    amenity={{ icon: amenity.icon, text: amenity.text }}
                    toggle={() => {
                      setAmenityToBeDescribed(amenity);
                      openModal();
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Step5;
