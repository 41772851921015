import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TableHeader, TableHeaderEntry, TableLayout, TableRowEntry, TableRowsContainer, TableRow } from "../../UI/Table";
import { Box, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const headers = [
  { name: "Name", width: 200 },
  { name: "Monthly Price", width: 200 },
  { name: "Daily Price", width: 150 },
  { name: "Status", width: 150 },
];

export default function PropertiesTable() {
  const { properties } = useSelector((state: RootState) => state.properties);

  return (
    <TableLayout>
      <TableHeader>
        {headers?.map((header, index) => {
          return (
            <TableHeaderEntry width={header.width} key={index}>
              {header.name}
            </TableHeaderEntry>
          );
        })}
      </TableHeader>
      <TableRowsContainer>
        {properties?.map((property, index) => {
          const firstImage = property.Documents.find((doc) => doc.type.includes("image"));
          return (
            <Link component={RouterLink} to={`/properties/${property.id}`} color="inherit" sx={{ textDecoration: "none" }}>
              <TableRow key={index}>
                <TableRowEntry width={headers[0].width}>
                  <Box component={"p"} display={"flex"} alignItems="center" gap={0.8}>
                    <img
                      src={firstImage?.url}
                      style={{ width: "30px", height: "30px", borderRadius: "50%", objectFit: "cover", objectPosition: "center" }}
                      alt=""
                    />{" "}
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {property?.name}
                    </p>
                  </Box>
                </TableRowEntry>
                <TableRowEntry InnerProps={{ style: { fontWeight: "bold" } }} width={headers[1].width}>
                  <>${property.monthlyPrice.toLocaleString()}</>
                </TableRowEntry>
                <TableRowEntry InnerProps={{ style: { fontWeight: "bold" } }} width={headers[2].width}>
                  <>${property.dailyPrice.toLocaleString()}</>
                </TableRowEntry>
                <TableRowEntry width={headers[3].width} InnerProps={{ style: { textTransform: "capitalize" } }}>
                  {property.status}
                </TableRowEntry>
              </TableRow>
            </Link>
          );
        })}
      </TableRowsContainer>
    </TableLayout>
  );
}
