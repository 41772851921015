import { Box, Grid, Paper, Rating, Typography } from "@mui/material";
import React from "react";

const testimonials = [
  {
    img: "/images/testimonial1.png",
    text: "I’ll always use A Traveled Path Homes for my Travel Nurse contracts! With services only for traveling professionals, I’m not competing with a horde of other people trying to book vacations.",
  },
  {
    img: "/images/testimonial2.png",
    text: "Consistent, Affordable, but most of all-- Reliable. I can always count on finding a place to stay for my traveling teacher contracts because of A Traveled Path Homes.",
  },
  {
    img: "/images/testimonial3.png",
    text: "A Traveled Path Homes-- I’m disappointed. In myself, for not using their services much earlier. I can go anywhere I need to for work and know that ATPH always has my back.",
  },
];

export default function Testimonials() {
  return (
    <Grid container gap={3} justifyContent={"flex-start"} marginTop={4}>
      {testimonials?.map((whatWeDo, index) => {
        return (
          <Grid item xs={12} sm={5} lg={3.6} key={index}>
            <Paper
              sx={{
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                gap: 2.4,
                padding: 3,
                alignItems: "center",
                minHeight: "150px",
              }}
              elevation={0}
            >
              <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
                <img
                  src={whatWeDo?.img}
                  alt={whatWeDo.text}
                  style={{
                    width: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Rating value={5} size="small" />
              </Box>

              <Typography
                variant="subtitle1"
                component="p"
                fontSize={14}
                //   fontWeight="600"
                lineHeight={1.4}
                textAlign="center"
              >
                {whatWeDo.text}
              </Typography>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
