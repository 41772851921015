import { IAmenties, IPropertyAmenity } from './amenities.interface';
import { IBookmark } from './bookmark.interface';
import { IPropertyStatus } from '../enums/property.enum';
import { IReservation } from './reservation.interface';
import { IUser } from './user.interface';

export enum IPropertyType {
  singleFamily = 'Single-Family',
  multiFamily = 'Multi-Family',
  townhouse = 'Townhouse',
  apartment = 'Apartment',
  condo = 'Condo',
}

export interface IPropertySearchSlice {
  location: string;
  checkInDate: Date | string;
  checkOutDate: Date | string;
  guest?: number;
  longitude?: number;
  latitude?: number;
}

export interface IOwnershipDocuments {
  id?: number;
  type: string;
  url: string;
  name: string;
}

// for the file upload
export interface IOwnershipDocumentsFile {
  file?: File;
  name: string;
}
export interface IPropertyFormSlice {
  type: IPropertyType | '';
  description: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  guest: number;
  beds: number;
  bedroom: number;
  bathroom: number;
  minStay: number;
  dailyPrice: number;
  monthlyPrice: number;
  amenities: IAmenties[];
  documents: Array<IOwnershipDocuments | IOwnershipDocumentsFile>;
  longitude?: number;
  latitude?: number;
  photos: Array<string | File>;
}

export interface IProperty {
  id: number;
  type: IPropertyType;
  description: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  guest: number;
  beds: number;
  bedroom: number;
  bathroom: number;
  minStay: number;
  dailyPrice: number;
  monthlyPrice: number;
  longitude: number;
  latitude: number;
  Amenities: IPropertyAmenity[];
  Documents: IOwnershipDocuments[];
  User: IUser;
  status: IPropertyStatus;
  Reservations: IReservation[];
  Bookmarks: IBookmark[];
}

export interface IPropertySlice {
  properties: IProperty[];
  singleProperty?: IProperty;
  handlers: { isLoading: boolean };
  singleDocument?: IOwnershipDocuments;
}
