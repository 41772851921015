import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Container, Typography } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import BlogSubtitle from "../../components/Blog/Subtitle";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

export default function PowerOfEatingHealthy() {
  return (
    <>
      <Navbar />
      <BlogTheme>
        <Container maxWidth="md" sx={{ paddingY: 5 }} component={"article"}>
          <BlogBanner
            src="the-power-of-eating-healthy.png"
            imageStyle={{ objectPosition: "top" }}
            title="Nourishing Your Body: The Power of Eating Healthy"
            date="December 12, 2023"
            tag="Health"
          />

          <Typography component={"p"} variant="body1">
            In a world filled with processed foods and fast-paced living, the significance of maintaining a healthy diet often takes a backseat.
            However, the food choices we make significantly impact our overall well-being. Embracing a diet rich in nutritious, whole foods is a
            fundamental step towards a healthier and happier life.
          </Typography>

          <BlogSubtitle header="The Importance of Eating Healthy">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Fueling Your Body"
              answer="Just like a car needs quality fuel to run efficiently, our bodies require the right nutrients to function optimally. A balanced diet provides the essential vitamins, minerals, and macronutrients necessary for energy, growth, and repair."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Boosting Immunity"
              answer="A diet filled with fruits, vegetables, whole grains, lean proteins, and healthy fats strengthens the immune system, helping our bodies fight off illnesses and infections."
            />

            <PrivacyPolicySubtopicsList
              count={3}
              question="Enhancing Mental Health"
              answer="Studies have shown a link between diet and mental health. A nutrient-rich diet can improve mood, reduce the risk of depression, and enhance cognitive function."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Tips for Eating Healthily">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Incorporate Colorful Foods"
              answer="Fruits and vegetables of varying colors offer a spectrum of nutrients. Aim to fill your plate with a rainbow of produce to ensure you're getting a diverse range of vitamins and antioxidants.."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Choose Whole Grains"
              answer="opt for whole grains like brown rice, quinoa, oats, and whole wheat over refined grains. These provide more fiber, vitamins, and minerals."
            />

            <PrivacyPolicySubtopicsList
              count={3}
              question="Balance Your Plate"
              answer="Ensure a balanced meal by including a mix of protein, healthy fats, carbohydrates, and plenty of vegetables."
            />

            <PrivacyPolicySubtopicsList
              count={4}
              question="Limit Processed Foods and Sugars"
              answer="Minimize intake of processed foods, sugary snacks, and beverages. These items often lack nutritional value and can contribute to health issues if consumed excessively."
            />

            <PrivacyPolicySubtopicsList
              count={5}
              question="Stay Hydrated"
              answer="Drink plenty of water throughout the day. Water is essential for digestion, circulation, and overall body function."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Conclusion">
            <Typography component={"p"} variant="body1" marginTop={1}>
              Eating healthy isn't about strict limitations or deprivation; it's about making informed choices that nourish our bodies and support our
              overall health. By making conscious decisions to include nutrient-dense foods in our daily meals, we empower ourselves to lead
              healthier, more fulfilling lives.
            </Typography>

            <Typography component={"p"} variant="body1" marginTop={1}>
              Let's prioritize our health by choosing foods that fuel our bodies, boost our immunity, and contribute to our overall well-being. Start
              today, one healthy meal at a time, and experience the transformative power of eating well.
            </Typography>

            <Typography component={"p"} variant="subtitle1" marginTop={1}>
              Remember, a healthy diet is a key pillar in the foundation of a vibrant and fulfilling life.
            </Typography>
          </BlogSubtitle>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
