import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useParams } from "react-router-dom";
import { getSingleReservation } from "../services/reservations.services";
import Navbar from "../components/UI/Navbar/Navbar";
import Footer from "../components/UI/Footer";
import { Box, Container, Grid, Typography } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import CustomLoader from "../components/UI/CustomLoader";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../components/UI/PaymentForm";
import { StripeElementsOptions } from "@stripe/stripe-js";
import useInitStripe from "../hooks/useInitStripe";
import { getPropertyImages } from "../utils/property.utils";
import { shortenText } from "../utils/text.utils";
import { getDateAndMonth } from "../utils/date.utils";
import ProtectedProvider from "../components/Providers/ProtectedProvider";

export default function ReservationPayment() {
  const reservation = useSelector((state: RootState) => state?.reservations?.singleReservation);
  const reservationLoading = useSelector((state: RootState) => state?.reservations?.handlers?.reservation?.isLoading);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { options, stripe } = useInitStripe(reservation?.totalPrice as number);

  useEffect(() => {
    dispatch(getSingleReservation(id));
  }, []);

  return (
    <ProtectedProvider>
      <Navbar />
      <Container maxWidth="md" sx={{ paddingY: 4, minHeight: "100vh" }}>
        <CustomLoader loading={reservationLoading} text="Loading Reservation Information" />
        {!reservationLoading && (
          <Box>
            <Typography variant="h5" component={"h1"}>
              Reservation Details
            </Typography>

            <Grid container justifyContent={"center"} gap={2} marginY={2}>
              <Grid item xs={12} md={5.6}>
                <img
                  src={getPropertyImages(reservation?.Property)?.[0]}
                  alt={reservation?.Property?.name}
                  style={{ borderRadius: "6px", width: "100%", height: "300px", objectFit: "cover", objectPosition: "center" }}
                />
              </Grid>

              <Grid item xs={12} md={5.6}>
                <Typography variant="body1" fontWeight={"bold"}>
                  {reservation?.Property?.name}
                </Typography>
                <Typography variant="body2" marginTop={0.6}>
                  {shortenText(reservation?.Property?.description as string)}
                </Typography>
                <Typography variant="body2" fontWeight={"bold"} marginTop={0.6}>
                  {getDateAndMonth(reservation?.startDate)} - {getDateAndMonth(reservation?.endDate)}
                </Typography>
                <Typography variant="body2" marginTop={0.6}>
                  Price: <b>${reservation?.totalPrice?.toLocaleString()}</b>
                </Typography>
              </Grid>
            </Grid>

            <Container maxWidth="sm">
              <Elements stripe={stripe} options={options}>
                <PaymentForm />
              </Elements>
            </Container>
          </Box>
        )}
      </Container>
      <Footer />
    </ProtectedProvider>
  );
}
