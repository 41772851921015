import { UserGroups } from '../schema/enums/groups.enum';
import { ILocalStorageItems } from '../schema/enums/localstorage.enum';
import { IUser, IUserGroup } from '../schema/interfaces/user.interface';

const localStorageService = {
  token: {
    store: (token: string): void => {
      localStorage.setItem(ILocalStorageItems.token, token);
    },
    get: (): string => {
      return <string>localStorage.getItem(ILocalStorageItems.token);
    },
    delete: (): void => {
      localStorage.removeItem(ILocalStorageItems.token);
    },
  },

  user: {
    store: (user: IUser): void => {
      localStorage.setItem(ILocalStorageItems.user, JSON.stringify(user));
    },
    get: (): IUser | undefined => {
      const user = localStorage.getItem(ILocalStorageItems.user) as string;
      return user ? <IUser>JSON.parse(user) : undefined;
    },
    delete: (): void => {
      localStorage.removeItem(ILocalStorageItems.user);
    },
  },

  currentGroup: {
    store: (group: UserGroups): void => {
      localStorage.setItem(ILocalStorageItems.currentGroup, group);
    },
    get: (): UserGroups | undefined => {
      const group = localStorage.getItem(ILocalStorageItems.currentGroup) as UserGroups;
      return group ?? undefined;
    },
    delete: (): void => {
      localStorage.removeItem(ILocalStorageItems.currentGroup);
    },
  },
};

export default localStorageService;
