import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../redux/store";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { getPropertyImages } from "../../utils/property.utils";
import CustomLoader from "../../components/UI/CustomLoader";
import { getSinglePayment } from "../../services/payment.services";
import { getDateAndMonth } from "../../utils/date.utils";
import { PaymentStatus } from "../../schema/enums/payment.enum";
import AdminProvider from "../../components/Providers/AdminProvider";

export default function SinglePayment() {
  const screenQuery = useMediaQuery("(max-width: 600px)");
  const payment = useSelector((state: RootState) => state?.payment?.singlePayment);
  const loading = useSelector((state: RootState) => state?.payment?.handlers.isLoading);
  const dispatch = useDispatch();
  const { id } = useParams();

  const image = useMemo(() => getPropertyImages(payment?.Reservation?.Property)?.[0], [payment]);

  useEffect(() => {
    dispatch(getSinglePayment(id));
  }, []);

  return (
    <DashboardLayout>
      <AdminProvider>
        <div className="dashboard">
          <Typography variant="h5" fontWeight={"bold"} component="h2" marginBottom={2}>
            Reservation Information
          </Typography>

          {loading ? (
            <CustomLoader loading={loading} text="Fetching payment information" />
          ) : (
            <>
              <Box display="flex" justifyContent="flex-start" marginTop={4} gap={2}>
                <img
                  src={image}
                  style={{
                    width: "100%",
                    maxWidth: "250px",
                    borderRadius: 10,
                    objectFit: "cover",
                    objectPosition: "center",
                    ...(screenQuery ? { height: "250px" } : {}),
                  }}
                />

                <Box>
                  <h2>{payment?.Reservation?.Property?.name}</h2>
                  <p style={{ marginTop: 20, fontSize: ".9rem" }}>{payment?.Reservation?.Property?.description}</p>
                  <p style={{ fontWeight: "bold", marginTop: 10 }}>
                    {getDateAndMonth(payment?.Reservation?.startDate as Date)} - {getDateAndMonth(payment?.Reservation?.endDate as Date)}
                  </p>
                </Box>
              </Box>

              <Typography variant="h6" fontWeight={"bold"} component="h3" marginY={2}>
                Payment Information
              </Typography>

              <Stack gap={1} flexDirection="column">
                <Typography variant="body1" fontSize={".8rem"}>
                  Amount Received: <b>${payment?.amountReceived}</b>
                </Typography>
                <Typography variant="body1" fontSize={".8rem"}>
                  Status:{" "}
                  <b>{payment?.status === PaymentStatus.SUCCEEDED ? "Success" : payment?.status === PaymentStatus?.PENDING ? "Pending" : "Failed"}</b>
                </Typography>
                <Typography variant="body1" fontSize={".8rem"}>
                  Date: <b>{getDateAndMonth(payment?.updatedAt as Date)}</b>
                </Typography>
              </Stack>
            </>
          )}
        </div>
      </AdminProvider>
    </DashboardLayout>
  );
}
