import React, { DependencyList, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import secrets from "../constants/secrets.const";
import localStorageService from "../utils/localstorage.utils";

type Props = DependencyList;

export default function useSocket(dependencies: DependencyList = []): Socket | undefined {
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  useEffect(() => {
    const socket = io(secrets.serverUrl as string, { auth: { token: localStorageService.token.get() } });
    setSocket(socket);
    socket?.on("error", (e) => console.error(e));
  }, dependencies);
  return socket;
}
