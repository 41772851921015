import { Box, Card, Grid, Paper, Rating, Typography, Button, useMediaQuery, IconButton } from "@mui/material";
import { BiTrash } from "react-icons/bi";
import { BsArrowRight, BsStarFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { IProperty } from "../../schema/interfaces/property.interface";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";
import { IPropertyStatus } from "../../schema/enums/property.enum";
import { getDateAndMonth } from "../../utils/date.utils";
import localStorageService from "../../utils/localstorage.utils";
import Swal, { SweetAlertResult } from "sweetalert2";
import { useDispatch } from "react-redux";
import { deleteProperty, getProperties, getUserProperties } from "../../services/property.services";
import BookmarkIcon from "../PropertyDetails/BookmarkIcon";
import { IReservationStatus } from "../../schema/enums/reservations.enum";

interface Props extends IProperty {
  isReservation?: boolean;
  reservationStatus?: IReservationStatus;
  reservationPrice?: number;
  reservationStartDate?: Date;
  reservationEndDate?: Date;
  viewLink?: string;
}

const ResidencesItem = (property: Props) => {
  const { pathname } = useLocation();
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const dispatch = useDispatch();
  const images = property?.Documents?.filter((doc) => doc?.type?.includes("image"));
  const isOwnerListingPage = pathname === "/owner/listings";
  const loggedInUser = localStorageService.user.get();

  const deleteThis = async () => {
    await Swal.fire({
      title: "Delete Property",
      text: "Are you sure you want to delete this property?",
      icon: "warning",
      confirmButtonColor: "#53cbf0",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonColor: "darkred",
      showCancelButton: true,
    }).then(async (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        const data = await dispatch(deleteProperty(property?.id));
        if (!data?.error) {
          await Swal.fire({
            title: "Delete property",
            text: "You have successfully deleted this property",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          await dispatch(getUserProperties());
        }
      }
    });
  };

  return (
    <Grid item xs={12} md={3.8}>
      <Paper
        elevation={0}
        sx={{ padding: 1.4, cursor: "pointer", ":hover": { boxShadow: "1px 2px 3px rgba(0,0,0,.3)" } }}
        style={{ borderRadius: 10 }}
      >
        <Box position={"relative"}>
          <img
            src={images?.[0]?.url || PROPERTY_IMAGE}
            alt={property.name}
            loading="lazy"
            style={{ width: "100%", height: "250px", borderRadius: 10 }}
          />

          {loggedInUser && property?.status === IPropertyStatus.APPROVED && (
            <Box position="absolute" top={15} right={15} zIndex={1}>
              <BookmarkIcon property={property} userId={loggedInUser?.id as number} />
            </Box>
          )}

          {property.status === IPropertyStatus.INSPECTION && !property.isReservation && (
            <Box position={"absolute"} zIndex={1} top={15} right={15}>
              <Button variant="contained" size="small">
                {screenQuery ? "Pending..." : "Waiting for approval"}
              </Button>
            </Box>
          )}

          {isOwnerListingPage && (
            <Link to={`/owner/property/${property?.id}/reservations`} style={{ position: "absolute", zIndex: 1, bottom: 20, right: 20 }}>
              <Button variant="contained" size="small">
                View Reservations
              </Button>
            </Link>
          )}
        </Box>
        <Box paddingY={1}>
          <Typography variant="subtitle1" fontSize={".9rem"} lineHeight={"1.1"} component={"h6"} fontWeight={600}>
            {property?.name}
          </Typography>

          <Box sx={{ justifyContent: "space-between", alignItems: "center", display: "flex", gap: 1 }}>
            <Typography variant="body1" component="p" marginY={1} fontSize={".8rem"} fontWeight={"bold"}>
              {!property.isReservation ? ` $${property?.monthlyPrice?.toLocaleString()}/month` : `$${property.reservationPrice}`}
            </Typography>
            {property.isReservation && (
              <Button size="small" sx={{ fontSize: ".7rem" }} variant="contained">
                {property.reservationStatus}
              </Button>
            )}
          </Box>

          {property?.isReservation && (
            <>
              <Typography variant="body1" component="p" fontSize={14}>
                {getDateAndMonth(property?.reservationStartDate as Date)} - {getDateAndMonth(property?.reservationEndDate as Date)}
              </Typography>
            </>
          )}

          <Box display="flex" alignItems="center" justifyContent="space-between" gap={1.5}>
            <Link
              to={property?.viewLink ? property.viewLink : `/properties/${property?.id}`}
              style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: ".3rem" }}
            >
              <Button size="small" variant="contained" endIcon={<BsArrowRight />}>
                View
              </Button>
            </Link>

            {property?.User?.id === loggedInUser?.id && isOwnerListingPage && (
              <IconButton color="primary" size="small" onClick={deleteThis}>
                <BiTrash />
              </IconButton>
            )}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ResidencesItem;
