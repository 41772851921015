import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getDateAndMonth } from "../../utils/date.utils";

export default function AvailableReservations() {
  const property = useSelector((state: RootState) => state.properties.singleProperty);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 4,
        paddingX: 3,
        paddingBottom: 2,
        paddingTop: 1,
        marginTop: 2,
      }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        Available Reservations
      </Typography>

      <Box marginTop={2}>
        {property?.Reservations?.map((reservation, index) => {
          return (
            <Typography variant="body1" fontWeight="bold" marginY={2} key={index}>
              {getDateAndMonth(reservation.startDate as Date)} - {getDateAndMonth(reservation.endDate as Date)}
            </Typography>
          );
        })}
      </Box>
    </Paper>
  );
}
