import React, { FormEvent, useCallback, useMemo, useState } from "react";
import { Box, InputLabel, Stack, Typography, FormControl, TextField, Select, MenuItem, Button, Paper } from "@mui/material";
import { Currency, getAllISOCodes, getISOByParam } from "iso-country-currency";
import { useDispatch, useSelector } from "react-redux";
import { openErrorModal } from "../../redux/handlers.slice";
import { RootState } from "../../redux/store";
import { BiLoaderAlt } from "react-icons/bi";
import Swal, { SweetAlertResult } from "sweetalert2";
import { createPaymentProfile } from "../../services/payment-profile.services";
import { becomeAnOwner, editProfile, getProfile } from "../../services/profile.services";
import { IGeoCodeResult } from "../../schema/interfaces/map.interface";
import UserAddressForm from "./UserAddressForm";
import { DayPicker } from "react-day-picker";
import { formatDateIn_ } from "../../utils/date.utils";

interface Props {
  updateStep(): void;
}

export default function Step1({ updateStep }: Props) {
  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [dob, setDob] = useState(new Date(2002, 0, 1));
  const [postalCode, setPostalCode] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [idNumber, setIdNumber] = useState<string>("");
  const dispatch = useDispatch();
  const countries = useMemo(() => getAllISOCodes().map((iso) => iso.countryName), []);

  const selectLocation = async function (location: IGeoCodeResult) {
    setLine1(location?.address_line1 ?? "");
    setLine2(location?.address_line2 ?? "");
    setCity(location?.city ?? "");
    setCountry(location?.country ?? "");
    setPostalCode(location?.postcode ?? "");
    setState(location?.state ?? "");
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (idNumber.includes("-")) {
      return dispatch(openErrorModal(["Do not include '-'"]));
    }

    await Swal.fire({
      icon: "warning",
      title: "Confirm",
      text: "Are you sure you want to edit your profile?",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        const data = await dispatch(
          editProfile({
            line1,
            line2,
            city,
            state,
            country: getISOByParam("countryName", country as string) ?? country,
            phone,
            postalCode,
            idNumber,
            dob: formatDateIn_(dob as Date),
          })
        );
        if (!data?.error) {
          updateStep();
        }
      }
    });
  };

  return (
    <Box component="form" marginTop={5} width={"89vw"} maxWidth={"600px"} onSubmit={onSubmit}>
      <Stack component="section" flexDirection={"column"} gap={2} width={"100%"}>
        <UserAddressForm selectLocation={selectLocation} />
        {/* Address Line 1 */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine1">
            Address Line 1
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="addressLine1"
            name="addressLine1"
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
            required={true}
            type="text"
            placeholder="Address Line 1"
            size="small"
          />
        </Box>

        {/* Address Line 2 */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="addressLine2">
            Address Line 2 (optional)
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="addressLine2"
            name="addressLine2"
            value={line2}
            onChange={(e) => setLine2(e.target.value)}
            required={false}
            type="text"
            placeholder="Address Line 2"
            size="small"
          />
        </Box>

        {/* City */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="city">
            City
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="city"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required={true}
            type="text"
            placeholder="City"
            size="small"
          />
        </Box>

        {/* Address Line 2 */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="state">
            State
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="state"
            name="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required={true}
            type="text"
            placeholder="State"
            size="small"
          />
        </Box>

        {/* Zip Code*/}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="postalCode">
            Zip/Postal Code
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="postalCode"
            name="postalCode"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            required={true}
            type="text"
            placeholder="Postal Code"
            size="small"
          />
        </Box>

        {/* Country */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7, width: "100%" }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium">
            Country
          </Typography>
          <FormControl fullWidth sx={{ width: "100%" }}>
            <InputLabel id="country">Country</InputLabel>
            <Select
              label="Country"
              labelId="country"
              value={country || "Select Country"}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              size="small"
              fullWidth={true}
              style={{ fontSize: ".8rem", textTransform: "capitalize" }}
            >
              <MenuItem disabled={true} value={"Select Country"} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                Select Country
              </MenuItem>

              {countries?.map((country, index) => {
                return (
                  <MenuItem key={index} value={country} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {/* Phone */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="phone">
            Phone
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required={true}
            type="number"
            placeholder="Phone Number e.g 0123456789"
            helperText="Do not start phone number with 0 or country code"
            size="small"
          />
        </Box>

        {/* Id Number */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="idNumber">
            Identification Number
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="idNumber"
            name="idNumber"
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
            required={true}
            type="string"
            placeholder="Enter Driver's License Number"
            helperText="Please enter your Driver's License Number (Exclude '-')"
            size="small"
          />
        </Box>
        <Box>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="date-of-birth">
            Date of birth
          </Typography>
          <Paper square elevation={2} sx={{ padding: 1, maxWidth: "fit-content", marginTop: "1rem" }}>
            <DayPicker
              mode="single"
              captionLayout="dropdown"
              required={true}
              fromYear={1900}
              toYear={new Date().getFullYear()}
              defaultMonth={dob}
              selected={dob}
              onSelect={(e) => setDob(e as Date)}
            />
          </Paper>
        </Box>
      </Stack>

      {/* Submit Button */}
      <Button size="small" variant="contained" fullWidth type="submit" sx={{ marginTop: 2 }}>
        Submit
      </Button>
    </Box>
  );
}
