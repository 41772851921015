import { Box } from "@mui/material";
import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";

interface Props {
  images?: string[];
}

const ImageSlider = ({ images }: Props) => {
  images = images as string[];

  images = images?.length > 0 ? images : [PROPERTY_IMAGE];

  const [currentIndex, setCurrentIndex] = React.useState(0);

  return (
    <Box width="100%" height="100%" position="relative">
      <img
        src={images?.[currentIndex]}
        alt={images?.[currentIndex]}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      {/* Overlay */}
      <Box width="100%" height="100%" position="absolute" top="0" left="0" zIndex={1} sx={{ backgroundColor: "rgba(0,0,0,0.2)" }} />

      {/* Previous Button */}
      {images?.length > 1 && currentIndex !== 0 && (
        <Box
          position={"absolute"}
          zIndex={2}
          top={"50%"}
          left={"10px"}
          width={30}
          height={30}
          borderRadius={"50%"}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            background: "white",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => setCurrentIndex(currentIndex - 1)}
        >
          <BiChevronLeft style={{ fontSize: 21 }} cursor="pointer" />
        </Box>
      )}

      {/* Next Button */}
      {images?.length > 1 && currentIndex !== images?.length - 1 && (
        <Box
          position={"absolute"}
          zIndex={2}
          top={"50%"}
          right={"10px"}
          width={30}
          height={30}
          borderRadius={"50%"}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            background: "white",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => setCurrentIndex(currentIndex + 1)}
        >
          <BiChevronRight style={{ fontSize: 21 }} cursor="pointer" />
        </Box>
      )}

      {/* Counts */}
      <Box display="flex" alignItems="center" justifyContent="center" gap={1} position="absolute" width={"100%"} bottom={10} left={0}>
        {new Array(images?.length)?.fill(null)?.map((_, index) => {
          return (
            <Box
              component={"span"}
              width={8}
              height={8}
              borderRadius={"50%"}
              onClick={() => setCurrentIndex(index)}
              key={index}
              sx={{
                backgroundColor: currentIndex === index ? "black" : "white",
                cursor: "pointer",
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ImageSlider;
