import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { ChangeEvent, useState } from "react";
import { updatePropertyForm } from "../../redux/property-form.slice";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { IGeoCodeResult } from "../../schema/interfaces/map.interface";
import { autoCompleteLocations } from "../../services/map.services";
import LocationSelector from "../UI/LocationSelector";

export default function AddressSelector() {
  const { address } = useSelector((state: RootState) => state?.propertyForm);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [locations, setLocations] = useState<IGeoCodeResult[]>([]);
  const [fetchingLocation, setFetchingLocation] = useState<boolean>(false);
  const dispatch = useDispatch();

  const updateAddress = (city: string) => {
    dispatch(updatePropertyForm({ key: "address", value: city }));
  };

  const updateLongitudeAndLatitude = (longitude?: number, latitude?: number) => {
    dispatch(updatePropertyForm({ key: "longitude", value: longitude }));
    dispatch(updatePropertyForm({ key: "latitude", value: latitude }));
  };

  const fetchLocations = async (search: string) => {
    setIsOpen(true);
    setFetchingLocation(true);
    const result = await autoCompleteLocations(search);
    setLocations(result.slice(0, 5));
    setFetchingLocation(false);
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    updateAddress(e.target.value);
    // anytime the type, set the long and lat to a different coordinate since the location has changed
    updateLongitudeAndLatitude(undefined, undefined);

    if (e.target.value.length >= 3) {
      // fetch cities
      fetchLocations(e.target.value);
    }
  };

  const selectLocation = (location: IGeoCodeResult) => {
    dispatch(updatePropertyForm({ key: "address", value: location.formatted ?? location.name }));
    dispatch(updatePropertyForm({ key: "city", value: location.city ?? "" }));
    dispatch(updatePropertyForm({ key: "state", value: location.state ?? "" }));
    dispatch(updatePropertyForm({ key: "zipCode", value: location.postcode ?? "" }));
    updateLongitudeAndLatitude(location.lon, location.lat);
    setIsOpen(false);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
        Address
      </label>
      <TextField
        variant="outlined"
        fullWidth
        type="text"
        sx={{ marginTop: 1.4 }}
        label="Address"
        autoComplete={"off"}
        value={address}
        required
        onChange={onChangeInput}
      />

      {/* locations display */}
      {isOpen && (
        <LocationSelector closeBox={() => setIsOpen(false)} selectLocation={selectLocation} isLoading={fetchingLocation} locations={locations} />
      )}
    </Box>
  );
}
