import React, { useEffect, useMemo } from "react";
import ProtectedProvider from "../../components/Providers/ProtectedProvider";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getPropertyDocuments, getPropertyImages } from "../../utils/property.utils";
import SingleDocument from "../../components/PropertyDocuments/SingleDocument";
import { getSingleProperty } from "../../services/property.services";
import { useParams } from "react-router-dom";

export default function PropertyDocuments() {
  const property = useSelector((state: RootState) => state.properties.singleProperty);
  const image = useMemo(() => getPropertyImages(property)[0], [property]);
  const docs = useMemo(() => getPropertyDocuments(property), [property]);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleProperty(id));
  }, []);

  return (
    <ProtectedProvider>
      <Navbar />
      <Container maxWidth="lg" sx={{ paddingY: 4, flex: 1 }}>
        <Grid container justifyContent={"space-between"} gap={2} marginY={2}>
          <Grid item xs={12} md={5.6}>
            <img
              src={image}
              alt={property?.name}
              style={{ borderRadius: "6px", width: "100%", height: "300px", objectFit: "cover", objectPosition: "center" }}
            />
          </Grid>

          <Grid item xs={12} md={5.6}>
            <h2>{property?.name}</h2>
            <p style={{ marginTop: 20, fontSize: ".9rem" }}>{property?.description}</p>
            <p style={{ fontWeight: "bold", marginTop: 10 }}>
              ${property?.monthlyPrice}/Month, ${property?.dailyPrice}/day
            </p>
          </Grid>
        </Grid>

        <Box component="section" paddingY={4}>
          <Typography variant="h5" component="h1">
            Documents
          </Typography>

          {(!docs || docs?.length === 0) && (
            <Box sx={{ width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="h6" textAlign="center" fontWeight="bold">
                No ownership document was added
              </Typography>
            </Box>
          )}

          <Grid container gap={2} justifyContent={"flex-start"}>
            {docs?.map((doc, index) => {
              return (
                <Grid item xs={11} sm={5.6} md={3.8} lg={2.8} key={index}>
                  <SingleDocument {...doc} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
      <Footer />
    </ProtectedProvider>
  );
}
