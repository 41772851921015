import { configureStore } from '@reduxjs/toolkit';
import propertySearchReducer from './property-search.slice';
import handlersReducer from './handlers.slice';
import userReducer from './user.slice';
import propertyFormReducer from './property-form.slice';
import amenitiesReducer from './amenities.slice';
import propertiesReducer from './properties.slice';
import reservationReducer from './reservations.slice';
import contactReducer from './contact.slice';
import bookmarkReducer from './bookmarks.slice';
import chatReducer from './chat.slice';
import paymentReducer from './payment.slice';
import paymentProfileReducer from './payment-profile.slice';
import walletReducer from './wallet.slice';

const store = configureStore({
  reducer: {
    propertySearch: propertySearchReducer,
    propertyForm: propertyFormReducer,
    properties: propertiesReducer,
    handlers: handlersReducer,
    user: userReducer,
    amenities: amenitiesReducer,
    reservations: reservationReducer,
    bookmarks: bookmarkReducer,
    contact: contactReducer,
    chat: chatReducer,
    payment: paymentReducer,
    paymentProfile: paymentProfileReducer,
    wallet: walletReducer,
  },
});

export type ActionDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
