import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const PropertyAmenitiesDetails = () => {
  const property = useSelector((state: RootState) => state.properties.singleProperty);
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 4,
        paddingX: 3,
        paddingBottom: 2,
        paddingTop: 1,
        marginTop: 2,
        maxWidth: "100%",
      }}
    >
      <Stack gap={2} direction={"column"}>
        {property?.Amenities?.slice(0, showMore ? (property?.Amenities?.length as number) : 2)?.map((amenity, index) => {
          return (
            <Box key={index} maxWidth={"100%"}>
              <Typography variant="subtitle1" fontWeight="bold">
                {amenity?.text}
              </Typography>
              <Typography variant="body1" maxWidth={"100%"} fontSize={".8rem"} sx={{ wordWrap: "break-word" }}>
                {amenity.PropertyAmenity.description}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      {(property?.Amenities?.length as number) > 2 && (
        <Button size="small" variant="contained" sx={{ display: "block", marginLeft: "auto", marginTop: 1 }} onClick={toggleShowMore}>
          {showMore ? "show less" : "show more"}
        </Button>
      )}
    </Paper>
  );
};

export default PropertyAmenitiesDetails;
