import React, { ReactElement, useEffect } from "react";
import ReactGA4 from "react-ga4";
import secrets from "../../constants/secrets.const";
import { useLocation } from "react-router-dom";

interface Props {
  children?: ReactElement | ReactElement[];
}

export default function GA4Provider({ children }: Props) {
  const location = useLocation();
  ReactGA4.initialize(secrets.gaMeasurmentId as string);

  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + (location.search || ""),
      title: location.pathname,
    });
  }, [location]);

  return <>{children}</>;
}
