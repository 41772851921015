import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAmenitiesSlice, IAmenties } from '../schema/interfaces/amenities.interface';
import { getAmenities } from '../services/property.services';

const initialState: IAmenitiesSlice = {
  amenties: [],
};

const amenitiesSlice = createSlice({
  name: 'amenitiesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAmenities.fulfilled, (state, action: PayloadAction<IAmenties[]>) => {
      state.amenties = action.payload;
    });
  },
});

const amenitiesReducer = amenitiesSlice.reducer;
export const {} = amenitiesSlice.actions;
export default amenitiesReducer;
