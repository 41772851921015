import React, { ReactElement } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { blue, red } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    primary: {
      main: blue[900],
    },
    secondary: {
      main: "#53cbf0",
    },
  },
  mixins: {},
  components: {
    MuiIconButton: {
      variants: [
        {
          props: {
            color: "primary",
          },
          style: {
            backgroundColor: "#00b9f1",
            color: "white",
            "&:hover": {
              backgroundColor: "#00b9f1",
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            backgroundColor: "#65c5e2",
            "&:hover": {
              backgroundColor: "#55d0f6",
            },
          },
        },
        {
          props: {
            variant: "contained",
            color: "warning",
          },
          style: {
            backgroundColor: "#FDB022",
            "&:hover": {
              backgroundColor: "#f79009",
            },
          },
        },
        {
          props: {
            variant: "outlined",
          },
          style: {
            color: "#65c5e2",
            borderColor: "#65c5e2",

            "&:hover": {
              backgroundColor: "#65c5e2",
              borderColor: "#65c5e2",
              color: "white",
            },
          },
        },
        {
          props: {
            color: "error",
            variant: "contained",
          },
          style: {
            backgroundColor: red[700],
            "&:hover": {
              backgroundColor: red[800],
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        { props: { variant: "h1" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "h2" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "h3" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "h4" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "h5" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "h6" }, style: { fontFamily: "Inter Tight, sans-serif" } },
        { props: { variant: "body1" }, style: { fontFamily: "Inter, sans-serif" } },
        { props: { variant: "body2" }, style: { fontFamily: "Inter, sans-serif" } },
        { props: { variant: "subtitle1" }, style: { fontFamily: "Inter, sans-serif" } },
        { props: { variant: "subtitle2" }, style: { fontFamily: "Inter, sans-serif" } },
      ],
    },
  },
});

const MuiThemeProvider = ({ children }: { children: ReactElement[] | ReactElement }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;
