import { isEqual, max, parseISO } from 'date-fns';
import { IConversation, IMessage } from '../schema/interfaces/chat.interface';
import { IUser } from '../schema/interfaces/user.interface';
import localStorageService from './localstorage.utils';

export const compareConversationWithCurrent = (conversation: IConversation, currentConversation?: IConversation): boolean => {
  return conversation.id === currentConversation?.id;
};

export const getConversationOtherUser = (users: IUser[] = [], mainUser?: IUser): IUser => {
  if (!mainUser) mainUser = localStorageService.user.get();
  return users.find((user) => user.id != mainUser?.id) as IUser;
};

export const getConversationLatestMessage = (messages: IMessage[]): IMessage | undefined => {
  let message: IMessage | undefined;

  if (messages.length === 1) {
    message = messages[0];
  } else {
    const messages_timestamp = messages.map(({ createdAt }) => parseISO(createdAt as string));
    for (const _message of messages) {
      if (isEqual(parseISO(_message.createdAt as string), max(messages_timestamp))) message = _message;
    }
  }

  return message;
};

export const getMessageSenderFromConversation = (conversation: IConversation | undefined = undefined, userId: number): IUser => {
  const user = conversation?.Users?.find((user) => user?.id === userId) as IUser;
  return user;
};
