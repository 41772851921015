import React, { useState } from "react";
import { Box, Card, TextField, Typography, Button, useMediaQuery } from "@mui/material";
import CheckInCheckOut from "./CheckInCheckOut";
import Travelers from "./Travelers";
import WhereTo from "./WhereTo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import formatDate from "../../../utils/date.utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getProperties } from "../../../services/property.services";
import { PropertyFilterDTO } from "../../../schema/dto/property.dto";
import { stringify } from "querystring";
import { shortenText } from "../../../utils/text.utils";
import { Search } from "@mui/icons-material";

const PropertySearch = () => {
  const [dateOpen, setDateOpen] = useState<boolean>(false);
  const [whereToOpen, setWhereToOpen] = useState<boolean>(false);
  const [travellersOpen, setTravellersOpen] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { location, checkInDate, checkOutDate, guest, longitude, latitude } = useSelector((state: RootState) => state?.propertySearch);

  const screenQuery = useMediaQuery("(max-width: 1000px)");

  const search = async () => {
    const query: PropertyFilterDTO = {};
    if (longitude) query.longitude = longitude;
    if (latitude) query.latitude = latitude;
    if (guest) query.guest = guest;
    const queryString = `?${stringify(query as any)}`;
    navigate(`/properties${queryString}`);
  };

  return (
    <Box sx={{ maxWidth: "800px !important", width: screenQuery ? "90vw" : "fit-content" }}>
      <Card
        variant="elevation"
        sx={{
          display: "flex",
          borderRadius: 2,
          flexDirection: screenQuery ? "column" : "row",
        }}
      >
        {/* Location Selector */}
        <Box
          sx={{
            ...boxStyles,
          }}
        >
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={500}
            sx={{
              maxWidth: "100%",
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whitespace: "nowrap",
              textAlign: "center",
            }}
          >
            Where To
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: 1 }}
            inputProps={{ sx: { fontSize: ".8rem", textAlign: screenQuery ? "center" : "left" } }}
            placeholder="Select Location"
            onClick={() => setWhereToOpen(true)}
            value={location}
          ></TextField>
        </Box>

        {/* Date Picker */}
        {/* <Box
          sx={{
            ...boxStyles,
          }}
        >
          <Typography variant="body1" fontSize={14} fontWeight={500} sx={{ cursor: "pointer" }}>
            Check In - Check Out
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: 1 }}
            inputProps={{ sx: { fontSize: ".8rem", textAlign: screenQuery ? "center" : "left" } }}
            placeholder=""
            onClick={() => setDateOpen(true)}
            value={`${checkInDate ? formatDate(checkInDate as Date) : "Check In"} - ${checkOutDate ? formatDate(checkOutDate as Date) : "Check Out"}`}
          ></TextField>
        </Box> */}

        {/* Travelers Count */}

        <Box
          sx={{
            ...boxStyles,
          }}
        >
          <Typography variant="body1" fontSize={14} fontWeight={500} sx={{ cursor: "pointer" }}>
            {"How Many Travelers?"}
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: 1 }}
            inputProps={{ sx: { fontSize: ".8rem", textAlign: screenQuery ? "center" : "left" } }}
            placeholder="Select  Travelers"
            onClick={() => setTravellersOpen(true)}
            value={guest ? `${guest} traveler(s)` : ""}
          ></TextField>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: screenQuery ? "0px" : 1,
          }}
          onClick={search}
        >
          <Button endIcon={<Search />} fullWidth={screenQuery ? true : false} size="small" sx={{ paddingY: 1 }} variant="contained">
            Search
          </Button>
        </Box>
      </Card>

      <CheckInCheckOut isOpen={dateOpen} setIsOpen={setDateOpen} />
      <Travelers isOpen={travellersOpen} setIsOpen={setTravellersOpen} />
      <WhereTo isOpen={whereToOpen} setIsOpen={setWhereToOpen} />
      {/* <Filters isOpen={filtersOpen} setIsOpen={setFiltersOpen} /> */}
    </Box>
  );
};

const boxStyles = {
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export default PropertySearch;
