import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function Connect() {
  const screenQuery = useMediaQuery("(max-width: 1000px)");

  return (
    <Grid marginY={6} rowGap={4} columnGap={8} container alignItems={"center"} justifyContent={"center"}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" fontWeight={"bold"}>
          Get an amazing experience when you connect with us
        </Typography>

        <Typography variant="body1" fontSize={".8rem"} component={"p"} fontWeight={500} lineHeight={1.3} marginBottom={3}>
          Join the community of satisfied nurses who have entrusted us with their accommodation needs. Your comfort is our priority, and with our
          expertly managed properties, you can focus on what matters most – your work and well-being. Connect with us today and elevate your travel
          nurse housing experience to a whole new level!
        </Typography>
      </Grid>
      <Grid item xs={10} sm={4}>
        <Box
          position={"relative"}
          sx={{
            maxWidth: "300px",
            marginX: "auto",
          }}
        >
          <Box
            component="img"
            src="./images/home3.png"
            sx={{
              width: "100%",
              height: "350px",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: 4,
              position: "relative",
              zIndex: 4,
            }}
          ></Box>

          <Box sx={{ position: "absolute", top: "35%", left: "35%", width: "70%", height: "70%", background: "#53cbf0", borderRadius: 4 }}></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
