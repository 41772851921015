import { group } from 'console';
import { IUserGroup } from '../schema/interfaces/user.interface';
import { UserGroups } from '../schema/enums/groups.enum';
import localStorageService from './localstorage.utils';

const isOwner = (): boolean => {
  const currentGroup = localStorageService.currentGroup.get();
  return currentGroup === UserGroups.OWNER;
};

export const hasOwnerAccount = (groups?: IUserGroup[]): boolean => {
  return groups?.find((group) => group.name === UserGroups.OWNER) ? true : false;
};

export const isAdmin = (): boolean => {
  const user = localStorageService.user.get();
  return user ? user?.isAdmin : false;
};

export default isOwner;
