import React, { useState } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { addDocument, removeDocument, updateDocFile, updateDocName } from "../../redux/property-form.slice";
import { IOwnershipDocuments, IOwnershipDocumentsFile } from "../../schema/interfaces/property.interface";
import { openErrorModal } from "../../redux/handlers.slice";

interface IDocument {
  file?: File;
  name?: string;
}

const Step7 = () => {
  const dispatch = useDispatch();
  const { documents } = useSelector((state: RootState) => state?.propertyForm);

  const changeDocName = (index: number, value: string) => {
    dispatch(updateDocName({ index, value }));
  };

  const changeDocFile = (index: number, value: File) => {
    if (!value.type.includes("pdf")) {
      dispatch(openErrorModal(["Upload a pdf document"]));
      return;
    }
    dispatch(updateDocFile({ index, value }));
  };

  const addOne = () => {
    dispatch(addDocument({}));
  };

  const removeOne = (index: number) => {
    dispatch(removeDocument({ index }));
  };

  return (
    <>
      <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={1.4}
          justifyContent="center"
          alignItems={"center"}
          maxWidth={450}
          marginX={"auto"}
          textAlign="center"
        >
          <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
            Ownership Documents
          </Typography>

          <Typography variant="subtitle1" component={"p"}>
            Upload documents that prove that you own this property
          </Typography>
        </Box>

        <Stack
          direction="column"
          sx={{
            width: "100%",
            maxWidth: "450px",
            marginX: "auto",
            marginTop: 2,
            marginBottom: 2,
            gap: 2,
          }}
        >
          {documents?.map((document, index) => {
            return (
              <Box key={index}>
                <Box component="header" display="flex" justifyContent="space-between" alignItems="center">
                  <label htmlFor="" style={{ fontWeight: "bold", marginBottom: 2 }}>
                    Document {index + 1}
                  </label>

                  {/* Display only when the length is greater than one */}
                  {documents?.length > 1 && (
                    <span onClick={() => removeOne(index)}>
                      <BiTrash fontSize={24} cursor="pointer" color="darkred" />
                    </span>
                  )}
                </Box>

                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={document?.name}
                  onChange={(e) => changeDocName(index, e.target.value)}
                  size="small"
                  label="Document Name"
                />

                {/* Make the input field hidden */}
                <input
                  type="file"
                  id={`upload-document-${index}`}
                  style={{ display: "none" }}
                  onChange={(e) => changeDocFile(index, e.target?.files?.[0] as File)}
                />
                <Box
                  component="label"
                  htmlFor={`upload-document-${index}`}
                  sx={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                    border: "1.2px solid lightgray",
                    padding: "0 .8rem",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginTop: 1,
                    color: "#555",
                    "&:hover": {
                      borderColor: "#333741",
                    },
                    "&:active": {
                      borderColor: blue[800],
                    },
                  }}
                >
                  {/* If it is the uploaded file, display the name with the type (daniel.pdf) and redirect to the document when clicked */}
                  {(document as IOwnershipDocuments)?.url ? (
                    <a href={(document as IOwnershipDocuments)?.url} target="_blank">
                      {document.name}.{(document as IOwnershipDocuments)?.type}
                    </a>
                  ) : (document as IOwnershipDocumentsFile)?.file ? (
                    (document as IOwnershipDocumentsFile)?.file?.name
                  ) : (
                    "Select Document File (.pdf)"
                  )}
                </Box>
              </Box>
            );
          })}

          <Button fullWidth variant="contained" type="button" onClick={addOne}>
            Add new document
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default Step7;
