import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import localStorageService from "../../../utils/localstorage.utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { filterPropertiesByStatus } from "../../../utils/property.utils";
import { IPropertyStatus } from "../../../schema/enums/property.enum";
import { getProperties } from "../../../services/property.services";
import { Chart } from "react-google-charts";
import { blue, red, yellow } from "@mui/material/colors";
import "./index.css";

export default function AdminDashboardBanner() {
  const dispatch = useDispatch();
  const properties = useSelector((state: RootState) => state.properties?.properties);

  const user = useMemo(() => localStorageService.user.get(), []);
  const [inspection, approved, rejected] = useMemo(
    () => [
      filterPropertiesByStatus(properties, IPropertyStatus.INSPECTION),
      filterPropertiesByStatus(properties, IPropertyStatus.APPROVED),
      filterPropertiesByStatus(properties, IPropertyStatus.REJECTED),
    ],
    [properties]
  );

  const chartData = useMemo(
    () => [
      ["Properties", "Length"],
      [IPropertyStatus.INSPECTION, inspection.length],
      [IPropertyStatus.REJECTED, rejected.length],
      [IPropertyStatus.APPROVED, approved.length],
    ],
    [inspection, approved, rejected]
  );

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  return (
    <Box className="admin-dashboard-banner" sx={{ width: "100%", padding: "2rem", borderRadius: 2 }}>
      <Typography color="white" fontWeight="bold" variant="h5">
        Welcome back, {user?.firstName} 👋🏼
      </Typography>
      <Typography color="white" variant="subtitle1" fontSize=".9rem">
        This is ATPH properties portfolio report
      </Typography>
      <Box className="chart-container" sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box sx={{ flex: 1, width: "100%", height: "250px" }}>
          <Chart
            chartType="PieChart"
            data={chartData}
            width="100%"
            height="100%"
            style={{ background: "transparent !important", color: "white !important" }}
          />
        </Box>

        <Box component="aside" marginLeft={2} paddingLeft={2} sx={{ borderLeft: "1.5px solid white" }}>
          {inspection.length > 0 && (
            <Box display="flex" sx={{ cursor: "pointer" }} component="article" alignItems={"center"}>
              <Box component="span" sx={{ width: "4px", height: "20px", background: blue[800] }} />
              <Typography variant="body1" fontSize=".8rem" color="white" marginLeft="5px">
                Inspection
              </Typography>
            </Box>
          )}

          {approved.length > 0 && (
            <Box display="flex" sx={{ cursor: "pointer" }} component="article" alignItems={"center"}>
              <Box component="span" sx={{ width: "4px", height: "20px", background: yellow[800] }} />
              <Typography variant="body1" fontSize=".8rem" color="white" marginLeft="5px">
                Approved
              </Typography>
            </Box>
          )}

          {rejected.length > 0 && (
            <Box display="flex" sx={{ cursor: "pointer" }} component="article" alignItems={"center"}>
              <Box component="span" sx={{ width: "4px", height: "20px", background: red[800] }} />
              <Typography variant="body1" fontSize=".8rem" color="white" marginLeft="5px">
                Rejected
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
