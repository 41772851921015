import React, { ReactElement } from "react";
import localStorageService from "../../utils/localstorage.utils";
import { openErrorModal } from "../../redux/handlers.slice";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactElement | ReactElement[];
}

export default function ProtectedProvider({ children }: Props) {
  const user = localStorageService.user.get();
  const dispatch = useDispatch();

  if (!user) {
    dispatch(openErrorModal(["You're not authorized to access this route"]));
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}
