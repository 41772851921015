import { Grid, useMediaQuery } from "@mui/material";
import React from "react";
import UsersCount from "./UsersCount";
import PropertiesCount from "./PropertiesCount";
import ContactsCount from "./ContactsCount";

export default function AdminSummary() {
  const screenQuery = useMediaQuery("(max-width:900px)");

  return (
    <Grid container gap={2} justifyContent={"flex-start"} alignItems={"center"} sx={{ marginTop: 2 }}>
      <Grid item xs={8} md={4.2} lg={3.2}>
        <UsersCount />
      </Grid>
      <Grid item xs={8} md={4.2} lg={3.2}>
        <PropertiesCount />
      </Grid>
      <Grid item xs={8} md={4.2} lg={3.2}>
        <ContactsCount />
      </Grid>
    </Grid>
  );
}
