import { ChevronRightRounded } from "@mui/icons-material";
import { Box, IconButton, Link, Paper, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";
import CustomLoader from "../../UI/CustomLoader";

interface Props {
  icon: ReactElement;
  title: string;
  count: number | string;
  link?: string;
  iconBg: string;
  loading?: boolean;
}

export default function ContentBox({ icon, title, count, link, iconBg, loading }: Props) {
  return (
    <Paper
      component="article"
      elevation={1}
      sx={{
        borderRadius: 2,
        minHeight: "100px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        background: "#f8f8f8",
        padding: 1,
        border: "1.5px solid lightgray",
      }}
    >
      <Box component="header" sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Box
          component="span"
          sx={{
            display: "flex",
            width: "30px",
            height: "30px",
            color: "white",
            background: iconBg,
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>

        <Typography variant="body2" fontSize={".9rem"} fontWeight={"bold"} color={"#333741"}>
          {title}
        </Typography>
      </Box>

      {loading ? (
        <CustomLoader loading={loading ?? false} />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, alignItems: "center" }}>
            <Typography variant="h6" component="h4" fontSize={"1.3rem"} fontWeight={"bold"}>
              {count}
            </Typography>

            {link && (
              <Link component={RouterLink} to={link as string} color={"#333741"}>
                <IconButton>
                  <ChevronRightRounded fontSize="medium" />
                </IconButton>
              </Link>
            )}
          </Box>
        </>
      )}
    </Paper>
  );
}
