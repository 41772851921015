import { createAsyncThunk } from '@reduxjs/toolkit';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';

export const bookmarkProperty: any = createAsyncThunk('bookmarkProperty', async (id: number, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Saving Property'));

  try {
    const response = await http.post('/api/property/bookmark', { propertyId: id });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const deleteBookmark: any = createAsyncThunk('deleteBookmark', async (id: number, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Removing from bookmarks'));

  try {
    const response = await http.delete(`/api/property/bookmark/${id}`);
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getUserBookmarks: any = createAsyncThunk('getUserBookmarks', async (_, thunkApi) => {
  try {
    const response = await http.get('/api/property/bookmark');
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});
