import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { BiSearch } from "react-icons/bi";
import { GridViewOutlined, TableViewOutlined } from "@mui/icons-material";
import PropertiesTable from "../../components/Admin/Properties/PropertiesTable";
import PropertiesGrid from "../../components/Admin/Properties/PropertiesGrid";
import { getAdminProperties, getProperties } from "../../services/property.services";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { PaginatedResponse } from "../../schema/dto/query.dto";
import { IProperty } from "../../schema/interfaces/property.interface";
import PaginationContainer from "../../components/UI/Pagination";
import { useSearchParams } from "react-router-dom";
import { GetAdminPropertiesDTO } from "../../schema/dto/property.dto";
import CustomLoader from "../../components/UI/CustomLoader";
import AdminProvider from "../../components/Providers/AdminProvider";

enum Layouts {
  TABLE = "table",
  GRID = "grid",
}

export default function Properties() {
  const [search, setSearch] = useState<string>("");
  const [layout, setLayout] = useState<Layouts>(Layouts.TABLE);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.properties.handlers.isLoading);

  const page = searchParams.get("page") ?? 1;
  const searchInQuery = searchParams.get("search");

  const searchProperties = async () => {
    setSearchParams({ ...searchParams, page: "1", search });

    let data: ActionDispatchResponse<PaginatedResponse<IProperty>>;
    if (search.length === 0) data = await dispatch(getAdminProperties());
    else data = await dispatch(getAdminProperties({ name: search, page: 1 }));
  };

  const initialFetch = async () => {
    const data: ActionDispatchResponse<PaginatedResponse<IProperty>> = await dispatch(getAdminProperties());
    if (!data?.error) {
      setPagesCount(data?.payload?.totalPages);
    }
  };

  const pageSearch = async () => {
    const query: GetAdminPropertiesDTO = {
      page,
      ...(searchInQuery ? { name: searchInQuery } : {}),
    };

    await dispatch(getAdminProperties(query));
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    pageSearch();
  }, [page]);

  return (
    <DashboardLayout>
      <AdminProvider>
        <Box display="flex" flexDirection={"column"} gap={2} paddingTop={2.5} marginBottom={4}>
          <Typography variant="h5">Properties</Typography>
          <Box maxWidth={"500px"} width="100%" display="flex" gap={2} alignSelf={"flex-end"}>
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              size="small"
              label="Search properties"
              sx={{ flex: 1 }}
            />
            <IconButton onClick={searchProperties} color="primary" sx={{ minWidth: "fit-content", flex: 1, maxWidth: "fit-content" }}>
              <BiSearch />
            </IconButton>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} justifyContent={"flex-end"}>
            <Box
              component="span"
              width={"30px"}
              height={"30px"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={1.3}
              border="1.5px solid #333741"
              sx={{ cursor: "pointer", ...(layout === Layouts.TABLE ? { background: "#53cbf0", color: "white", borderColor: "#53cbf0" } : {}) }}
              onClick={() => setLayout(Layouts.TABLE)}
            >
              <TableViewOutlined />
            </Box>
            <Box
              component="span"
              width={"30px"}
              height={"30px"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={1.3}
              border="1.5px solid #333741"
              sx={{ cursor: "pointer", ...(layout === Layouts.GRID ? { background: "#53cbf0", color: "white", borderColor: "#53cbf0" } : {}) }}
              onClick={() => setLayout(Layouts.GRID)}
            >
              <GridViewOutlined />
            </Box>
          </Box>
        </Box>

        <CustomLoader loading={loading} text="Loading Properties" size={25} />
        <Box marginTop={3}>{layout === Layouts.TABLE ? <PropertiesTable /> : <PropertiesGrid />}</Box>

        <Box marginTop={2} maxWidth={"fit-content"} marginLeft={"auto"}>
          <PaginationContainer length={pagesCount} />
        </Box>
      </AdminProvider>
    </DashboardLayout>
  );
}
