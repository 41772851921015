import { Box, Card, Container, Grid, Paper, Rating, Typography } from "@mui/material";
import React from "react";
import Navbar from "../components/UI/Navbar/Navbar";
import Footer from "../components/UI/Footer";
import Testimonials from "../components/Home/Testimonials";
import Banner from "../components/UI/Banner";

const whatWeDo = [
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/suitcasesuitcase.png",
    text: "We create an online community of traveling medical professionals and educators.",
  },
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/key.png",
    text: "We keep you safe by examining proof of ownership and requiring verification badges.",
  },
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/money.png",
    text: "We protect your trip with unit inspections and we offer transaction insurance.",
  },
];

const homeOffers = [
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/lounge.png",
    text: "Fully furnished spaces",
  },
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/kitchentools.png",
    text: "Full kitchens",
  },
  {
    img: "https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/laundry.png",
    text: "Washer and dryer",
  },
];

const About = () => {
  return (
    <>
      <Navbar />

      <Banner text="About Us" />

      <section
        style={{
          background:
            "linear-gradient(180deg, rgba(119, 152, 206, 0.77) 0%, rgba(119, 152, 206, 0.538935) 29.66%, rgba(119, 152, 206, 0.456525) 48.66%, rgba(119, 152, 206, 0.34) 75.52%, rgba(119, 152, 206, 0.34) 90.45%)",
        }}
      >
        <Container maxWidth="lg" sx={{ paddingX: 3, paddingY: 5 }}>
          <Typography variant="h5" component="h2" fontWeight="bold" color={"white"}>
            What We Do
          </Typography>

          <Grid container gap={3} justifyContent={"flex-start"} marginTop={4}>
            {whatWeDo?.map((whatWeDo, index) => {
              return (
                <Grid item xs={12} sm={5} lg={3.5} key={index}>
                  <Paper
                    sx={{
                      borderRadius: 5,
                      display: "flex",
                      gap: 2.4,
                      padding: 3,
                      alignItems: "center",
                      minHeight: "150px",
                    }}
                    elevation={2}
                  >
                    <img src={whatWeDo?.img} alt={whatWeDo.text} style={{ height: "70px" }} />
                    <Typography variant="subtitle1" component="p" fontSize={14} fontWeight="500" lineHeight={1.4}>
                      {whatWeDo.text}
                    </Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </section>

      <Container maxWidth="lg" sx={{ paddingX: 3, paddingY: 3 }}>
        <Typography variant="h5" component="h2" fontWeight="bold" color={"primary"}>
          Each Home Offers
        </Typography>

        <Grid container gap={3} justifyContent={"flex-start"} marginTop={4}>
          {homeOffers?.map((whatWeDo, index) => {
            return (
              <Grid item xs={12} sm={5} lg={3.5} key={index}>
                <Paper
                  sx={{
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2.4,
                    padding: 3,
                    alignItems: "center",
                    minHeight: "150px",
                  }}
                  elevation={0}
                >
                  <img src={whatWeDo?.img} alt={whatWeDo.text} style={{ height: "70px" }} />
                  <Typography variant="subtitle1" component="p" fontSize={14} fontWeight="600" lineHeight={1.4}>
                    {whatWeDo.text}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ paddingX: 3, paddingY: 3 }}>
        <Typography variant="h5" component="h2" fontWeight="bold" color={"primary"}>
          {"Don't take our words for it"}
        </Typography>

        <Testimonials />
      </Container>
      <Footer />
    </>
  );
};

export default About;
