import { Search } from "@mui/icons-material";
import { Box, Icon, Input, TextField } from "@mui/material";
import React from "react";

export default function SearchConversation() {
  return (
    <Box width="100%" sx={{ position: "relative", marginTop: 1 }}>
      <Input
        fullWidth
        sx={{
          border: "1.5px solid #c1bcbc",
          outline: "none",
          borderRadius: 6,
          background: "#eeeeee",
          fontSize: ".8rem",
          paddingLeft: 1,
          paddingRight: 4,
          paddingY: 0.4,
        }}
        disableUnderline={true}
        placeholder="Search Messages"
        size="small"
      />

      <Search
        fontSize="small"
        sx={{ position: "absolute", right: "10px", zIndex: 2, top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
      />
    </Box>
  );
}
