import React, { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from "react";
import "./WhereTo.css";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import { updatePropertySearchBody } from "../../../redux/property-search.slice";
import { IGeoCodeResult } from "../../../schema/interfaces/map.interface";
import LocationSelector from "../../UI/LocationSelector";
import { autoCompleteLocations } from "../../../services/map.services";

interface Props {
  children?: ReactElement | ReactElement[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function WhereTo(props: Props) {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState<IGeoCodeResult[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const selectLocation = async (location: IGeoCodeResult) => {
    dispatch(updatePropertySearchBody({ key: "longitude", value: location.lon }));
    dispatch(updatePropertySearchBody({ key: "latitude", value: location.lat }));
    dispatch(updatePropertySearchBody({ key: "location", value: location.formatted }));
    setSearch(location.formatted);
    setIsOpen(false);
    props.setIsOpen(false);
  };

  const fetchLocations = async (search: string) => {
    setIsFetching(true);
    setIsOpen(true);
    const locations = await autoCompleteLocations(search);
    setLocations(locations.slice(0, 5));
    setIsFetching(false);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length >= 3) {
      await fetchLocations(value);
    }
  };

  if (!props.isOpen) return <></>;

  return (
    <div className="where">
      <div className="where-inner">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Select Your Facility</Typography>

          <Button variant="contained" onClick={() => props.setIsOpen(false)}>
            Close
          </Button>
        </Box>

        <Box position="relative" marginTop={4}>
          <TextField fullWidth variant="outlined" size="small" label="Location" value={search} onChange={onChange} />
          {isOpen && (
            <LocationSelector selectLocation={selectLocation} isLoading={isFetching} locations={locations} closeBox={() => setIsOpen(false)} />
          )}
        </Box>
      </div>
    </div>
  );
}

export default WhereTo;
