import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { Box, Container, useMediaQuery } from "@mui/material";
import Navbar from "../components/UI/Navbar/Navbar";
import Footer from "../components/UI/Footer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { IMapCenter } from "../schema/interfaces/map.interface";
import MapProperty from "../components/Map/MapProperty";
import LocationSearch from "../components/Map/LocationSearch";
import SearchIcon from "../components/Map/SearchIcon";
import { resetProperties } from "../redux/properties.slice";
import secrets from "../constants/secrets.const";
import { IPropertyStatus } from "../schema/enums/property.enum";
import MapPropertiesList from "../components/Map/Properties";

const Map = () => {
  const properties = useSelector((state: RootState) => state.properties.properties);
  const dispatch = useDispatch();
  const { isLoaded, loadError, url } = useLoadScript({
    googleMapsApiKey: secrets.googleMapApiKey as string,
  });
  // kansas coordinates!!!
  const center = useMemo(() => ({ lat: 38.27312, lng: -98.5821872 }), []);
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>(undefined);
  const [showLocationSearch, setShowLocationSearch] = useState<boolean>(false);
  const [showPropertiesList, setShowPropertiesList] = useState<boolean>(false);
  const screenQuery = useMediaQuery("(max-width: 1200px)");

  const setMapCenter = (data: IMapCenter) => {
    mapRef?.setCenter({ lng: data.lng, lat: data.lat });
  };

  const onLoad = (map: google.maps.Map) => {
    console.log("logeddf");
    setMapRef(map);
  };

  useEffect(() => {
    dispatch(resetProperties());
  }, []);

  return (
    <>
      <Navbar />
      <Container sx={{ position: "relative", height: "100vh", display: "flex" }} maxWidth={false}>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <React.Fragment>
            {((screenQuery && !showPropertiesList) || !screenQuery) && (
              <Box sx={{ flex: showPropertiesList ? 0.8 : 1 }}>
                {showLocationSearch ? (
                  <LocationSearch
                    onSearch={() => setShowPropertiesList(true)}
                    setMapCenter={setMapCenter}
                    closeBox={() => setShowLocationSearch(false)}
                  />
                ) : (
                  <SearchIcon openBox={() => setShowLocationSearch(true)} />
                )}
                <GoogleMap
                  onLoad={onLoad}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  mapContainerClassName="map-container"
                  center={center}
                  zoom={7}
                >
                  {properties
                    ?.filter((property) => property.status === IPropertyStatus.APPROVED)
                    ?.map((property, index) => {
                      return <MapProperty {...property} key={index} />;
                    })}
                </GoogleMap>
              </Box>
            )}

            {showPropertiesList && <MapPropertiesList close={() => setShowPropertiesList(false)} />}
          </React.Fragment>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Map;
