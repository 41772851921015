import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPayment, IPaymentSlice } from '../schema/interfaces/payment.interface';
import { getAdminPayments, getReservationPayment, getSinglePayment, getUserPayments, getUserPropertiesPayments } from '../services/payment.services';
import { PaginatedResponse } from '../schema/dto/query.dto';

const initialState: IPaymentSlice = {
  payments: [],
  userPayments: [],
  userPropertiesPayments: [],
  singlePayment: undefined,
  handlers: { isLoading: false },
};

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReservationPayment.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getReservationPayment.fulfilled, (state, action: PayloadAction<IPayment>) => {
        state.singlePayment = action.payload;
        state.handlers.isLoading = false;
      })
      .addCase(getReservationPayment.rejected, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getAdminPayments.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getAdminPayments.fulfilled, (state, action: PayloadAction<PaginatedResponse<IPayment>>) => {
        state.payments = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getAdminPayments.rejected, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getSinglePayment.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getSinglePayment.fulfilled, (state, action: PayloadAction<IPayment>) => {
        state.singlePayment = action.payload;
        state.handlers.isLoading = false;
      })
      .addCase(getSinglePayment.rejected, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getUserPayments.pending, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getUserPayments.fulfilled, (state, action: PayloadAction<PaginatedResponse<IPayment>>) => {
        state.userPayments = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getUserPayments.rejected, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getUserPropertiesPayments.pending, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getUserPropertiesPayments.fulfilled, (state, action: PayloadAction<PaginatedResponse<IPayment>>) => {
        state.userPropertiesPayments = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getUserPropertiesPayments.rejected, (state) => {
        state.handlers.isLoading = false;
      });
  },
});

const paymentReducer = paymentSlice.reducer;
export const {} = paymentSlice.actions;
export default paymentReducer;
