import React, { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { IOwnershipDocuments } from "../../schema/interfaces/property.interface";
import { OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";
import CustomLoader from "../UI/CustomLoader";
import { Box, IconButton, Typography, Link } from "@mui/material";
import { ArrowBack, ArrowForward, Download } from "@mui/icons-material";

// PDF worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props extends Partial<IOwnershipDocuments> {}

export default function PdfViewer({ name, url, type }: Props) {
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const onLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setPagesCount(numPages);
  }, []);

  const previous = useCallback(() => {
    setPage(page - 1);
  }, [page]);
  const next = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return (
    <Box component="article">
      <Box
        sx={{
          width: "100%",
          background: "white",
          padding: 1,
          borderRadius: 0.5,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", gap: 1.2 }}>
          <IconButton disabled={page === 1} onClick={previous}>
            <ArrowBack />
          </IconButton>
          <Typography fontSize={".8rem"}>
            Page <b>{page}</b> of <b>{pagesCount}</b>
          </Typography>{" "}
          <IconButton disabled={page === pagesCount} onClick={next}>
            <ArrowForward />
          </IconButton>
        </Box>
        <a href={url} download>
          <IconButton title="download">
            <Download />
          </IconButton>
        </a>
      </Box>

      {/* Document renderer */}
      <Document
        file={url}
        onLoadSuccess={onLoadSuccess}
        loading={<CustomLoader loading={true} text="Rendering Document" sx={{ color: "white" }} size={25} />}
      >
        <Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
      </Document>
    </Box>
  );
}
