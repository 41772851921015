import React from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AdminDashboardBanner from "../../components/Admin/Dashboard/Banner";
import AdminSummary from "../../components/Admin/Dashboard/Summary";
import { Box } from "@mui/material";
import PaymentLogs from "../../components/Admin/Dashboard/PaymentLogs";
import AdminProvider from "../../components/Providers/AdminProvider";

export default function AdminDashboard() {
  return (
    <DashboardLayout>
      <AdminProvider>
        <Box paddingBottom={3} component="section">
          <AdminDashboardBanner />
          <AdminSummary />
          <PaymentLogs />
        </Box>
      </AdminProvider>
    </DashboardLayout>
  );
}
