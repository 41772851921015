import { Box, Button, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CustomLoader from "../UI/CustomLoader";
import ResidencesItem from "../UI/ResidencesItem";
import { Link } from "react-router-dom";
import { BiTrash } from "react-icons/bi";
import { getDateAndMonth } from "../../utils/date.utils";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";
import { IPropertyStatus } from "../../schema/enums/property.enum";
import { BsArrowRight } from "react-icons/bs";
import { Close } from "@mui/icons-material";

interface Props {
  close(): void;
}

const MapPropertiesList = ({ close }: Props) => {
  const {
    properties,
    handlers: { isLoading },
  } = useSelector((state: RootState) => state.properties);

  const screenQuery = useMediaQuery("(max-width: 1200px)");

  return (
    <Box sx={{ flex: screenQuery ? 1 : 0.25, paddingX: "1rem", paddingY: "1rem", maxHeight: "100vh", overflowY: "scroll" }}>
      <CustomLoader loading={isLoading} text="Loading..." />
      <Box>
        <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h5" marginY={2}>
            Properties in this location
          </Typography>

          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Box>
        {properties?.length > 0 ? (
          <Grid gap={2} flex={1} container marginTop={4}>
            {properties?.map((property, index) => {
              const images = property?.Documents?.filter((doc) => doc?.type?.includes("image"));

              return (
                <Grid item xs={12} md={12}>
                  <Paper
                    elevation={0}
                    sx={{ padding: 1.4, cursor: "pointer", ":hover": { boxShadow: "1px 2px 3px rgba(0,0,0,.3)" } }}
                    style={{ borderRadius: 10 }}
                  >
                    <Box position={"relative"}>
                      <img
                        src={images?.[0]?.url || PROPERTY_IMAGE}
                        alt={property.name}
                        loading="lazy"
                        style={{ width: "100%", height: "250px", borderRadius: 10 }}
                      />
                    </Box>
                    <Box paddingY={1}>
                      <Typography variant="subtitle1" fontSize={".9rem"} lineHeight={"1.1"} component={"h6"} fontWeight={600}>
                        {property?.name}
                      </Typography>

                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1.5}>
                        <Link to={`/properties/${property?.id}`} style={{ fontWeight: "bold", display: "flex", alignItems: "center", gap: ".3rem" }}>
                          <Button size="small" variant="contained" endIcon={<BsArrowRight />}>
                            View
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1" textAlign="center" marginTop={20}>
            {" "}
            No properties available in this location
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MapPropertiesList;
