import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOwnershipDocuments, IProperty, IPropertySlice } from '../schema/interfaces/property.interface';
import { getAdminProperties, getProperties, getSingleDocument, getSingleProperty, getUserProperties } from '../services/property.services';
import { PaginatedResponse } from '../schema/dto/query.dto';

const initialState: IPropertySlice = {
  properties: [],
  singleProperty: undefined,
  singleDocument: undefined,
  handlers: { isLoading: false },
};

const propertiesSlice = createSlice({
  name: 'propertiesSlice',
  initialState: initialState,
  reducers: {
    resetProperties: (state) => {
      state.properties = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProperties.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getProperties.fulfilled, (state, action: PayloadAction<IProperty[]>) => {
        state.properties = action.payload;
        state.handlers.isLoading = false;
      })
      .addCase(getProperties.rejected, (state) => {
        state.handlers.isLoading = false;
      })
      .addCase(getUserProperties.fulfilled, (state, action: PayloadAction<IProperty[]>) => {
        state.properties = action.payload;
      })
      .addCase(getSingleProperty.fulfilled, (state, action: PayloadAction<IProperty>) => {
        state.singleProperty = action.payload;
      })
      .addCase(getAdminProperties.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getAdminProperties.fulfilled, (state, action: PayloadAction<PaginatedResponse<IProperty>>) => {
        state.properties = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getAdminProperties.rejected, (state) => {
        state.handlers.isLoading = false;
      })

      .addCase(getSingleDocument.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getSingleDocument.fulfilled, (state, action: PayloadAction<IOwnershipDocuments>) => {
        state.singleDocument = action.payload;
        state.handlers.isLoading = false;
      })
      .addCase(getSingleDocument.rejected, (state) => {
        state.handlers.isLoading = false;
      });
  },
});

const propertiesReducer = propertiesSlice.reducer;
export const { resetProperties } = propertiesSlice.actions;
export default propertiesReducer;
