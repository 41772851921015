import React, { useEffect, useState } from "react";
import ContentBox from "./ContentBox";
import { People } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../services/profile.services";
import { ActionDispatchResponse } from "../../../schema/interfaces/redux.interface";
import { PaginatedResponse } from "../../../schema/dto/query.dto";
import { IUser } from "../../../schema/interfaces/user.interface";
import { RootState } from "../../../redux/store";

export default function UsersCount() {
  const loading = useSelector((state: RootState) => state?.user?.handlers?.isLoading);
  const [count, setCount] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUsers = async function () {
      const data: ActionDispatchResponse<PaginatedResponse<IUser>> = await dispatch(getAllUsers());
      if (!data?.error) {
        setCount(data?.payload?.totalCount);
      }
    };

    getUsers();
  }, []);

  return <ContentBox iconBg={blue[900]} title="Users" icon={<People fontSize="small" />} count={count} loading={loading} link="/admin/users" />;
}
