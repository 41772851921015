import React from "react";

function SingleFamilyIcon() {
  return (
    <>
      <img
        alt="single family home icon"
        width="70%"
        height="70%"
        src="https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/SingleFamily.png"
      />
    </>
  );
}

export default SingleFamilyIcon;
