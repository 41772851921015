import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPropertySearchSlice } from '../schema/interfaces/property.interface';

const initialState: IPropertySearchSlice = {
  guest: undefined,
  checkInDate: '',
  checkOutDate: '',
  location: '',
  longitude: undefined,
  latitude: undefined,
};

const propertySearchSlice = createSlice({
  name: 'propertySearchSlice',
  initialState,
  reducers: {
    updatePropertySearchBody: (state: IPropertySearchSlice, action: PayloadAction<{ key: keyof IPropertySearchSlice; value: any }>) => {
      state[action.payload.key] = action.payload.value as never;
    },
  },
});

const propertySearchReducer = propertySearchSlice.reducer;

export const { updatePropertySearchBody } = propertySearchSlice.actions;

export default propertySearchReducer;
