import React, { useMemo } from "react";
import { Box, Button, FormControl, InputLabel, Link, MenuItem, Select, Stack, Typography, useMediaQuery } from "@mui/material";
import ImageSlider from "../UI/ImageSlider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { IPropertyStatus } from "../../schema/enums/property.enum";
import { isAdmin as checkIsAdmin } from "../../utils/user-groups.utils";
import Swal, { SweetAlertResult } from "sweetalert2";
import { getSingleProperty, updatePropertyStatus } from "../../services/property.services";
import { useParams, Link as RouterLink } from "react-router-dom";
import { getPropertyImages } from "../../utils/property.utils";
import localStorageService from "../../utils/localstorage.utils";

const PropertyBasicInfo = () => {
  const screenQuery = useMediaQuery("(max-width:900px)");
  const property = useSelector((state: RootState) => state.properties?.singleProperty);
  const dispatch = useDispatch();
  const { id } = useParams();
  // return a string of image urls
  const images = getPropertyImages(property);
  const isAdmin = checkIsAdmin();
  const loggedInUser = useMemo(() => localStorageService.user.get(), []);

  const editStatus = (status: IPropertyStatus) => {
    Swal.fire({
      title: "Edit Status",
      text: "Are you sure you want to edit this property status?",
      icon: "warning",
      confirmButtonText: "Yes",
      confirmButtonColor: "#53cbf0",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "darkred",
    }).then(async (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        const data = await dispatch(updatePropertyStatus({ id, status }));
        if (!data?.error) {
          await Swal.fire({
            title: "Edit Property Status",
            text: "You have successfully edited this property status",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          await dispatch(getSingleProperty(id));
        }
      }
    });
  };

  return (
    <>
      {isAdmin && (
        <Box
          display="flex"
          gap={1.5}
          alignItems="center"
          justifyContent="space-between"
          margin="0px auto 1rem auto"
          maxWidth={screenQuery ? "100%" : "350px"}
        >
          <Typography variant="subtitle2" fontWeight={600} marginBottom={1} component="p">
            Property Status
          </Typography>

          <FormControl>
            <InputLabel id="property-status">Status</InputLabel>
            <Select
              label="Status"
              labelId="property-status"
              value={property?.status || "inspection"}
              onChange={(e) => {
                if (property?.status != e.target.value) {
                  editStatus(e.target.value as IPropertyStatus);
                }
              }}
              size="small"
              style={{ width: "150px", fontSize: ".8rem", textTransform: "capitalize" }}
            >
              <MenuItem value={IPropertyStatus.INSPECTION} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                {IPropertyStatus.INSPECTION}
              </MenuItem>
              <MenuItem value={IPropertyStatus.APPROVED} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                {IPropertyStatus.APPROVED}
              </MenuItem>
              <MenuItem value={IPropertyStatus.REJECTED} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                {IPropertyStatus.REJECTED}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      <Box width="100%" maxWidth={screenQuery ? "100%" : "350px"} height="350px" borderRadius={6} overflow="hidden" marginX={"auto"}>
        <ImageSlider images={images} />
      </Box>

      <Typography variant="subtitle1" fontWeight={600} marginTop={1} component="p" maxWidth={screenQuery ? "100%" : "350px"} marginX="auto">
        {property?.name}
      </Typography>

      <Box maxWidth={screenQuery ? "100%" : "350px"} marginX="auto">
        <Typography variant="subtitle1" marginTop={0.5} component="p" maxWidth={screenQuery ? "100%" : "350px"} marginX="auto" fontSize={14}>
          {property?.beds} Beds • {property?.bedroom} Bedrooms • {property?.bathroom} Bathroom • {property?.guest} Guests
        </Typography>

        <Typography
          variant="subtitle1"
          marginTop={0.5}
          component="p"
          maxWidth={screenQuery ? "100%" : "350px"}
          marginX="auto"
          fontSize={14}
          lineHeight={1.2}
        >
          {property?.description}
        </Typography>

        <Stack flexDirection={"column"} marginTop={0.5} gap={1.2} maxWidth={screenQuery ? "100%" : "350px"} marginX="auto">
          <Typography variant="subtitle1" component="p" fontSize={14} lineHeight={1.2}>
            <b>Address: </b> {property?.address}
          </Typography>
          <Typography variant="subtitle1" component="p" fontSize={14} lineHeight={1.2}>
            <b>State: </b> {property?.state}
          </Typography>
          <Typography variant="subtitle1" component="p" fontSize={14} lineHeight={1.2}>
            <b>City: </b> {property?.city}
          </Typography>

          {(loggedInUser?.isAdmin || loggedInUser?.id === property?.User?.id) && (
            <Link component={RouterLink} to={`/properties/${id}/documents`}>
              <Button variant="contained">View Ownership Documents</Button>
            </Link>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default PropertyBasicInfo;
