import { Box, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import localStorageService from "../../utils/localstorage.utils";
import PropertySearch from "./PropertySearch/PropertySearch";
import secrets from "../../constants/secrets.const";

const HomeBanner = () => {
  const screenQuery = useMediaQuery("(max-width: 1000px)");

  return (
    <Box className="banner" sx={{ minHeight: "100vh", paddingY: 10 }}>
      <Box maxWidth={"1200px"} marginX={"auto"} paddingX={"1rem"}>
        <Box maxWidth={"800px"}>
          <Typography color={"white"} variant="h3" fontWeight={"bold"}>
            A Traveled Path Homes
          </Typography>
          <Typography color={"white"} variant="h5" marginY={1}>
            A Space For You!
          </Typography>
          <Typography
            variant="body1"
            fontSize={screenQuery ? ".8rem" : "1rem"}
            color="white"
            component={"p"}
            fontWeight={500}
            lineHeight={1.3}
            marginBottom={3}
          >
            We take care of you so you can take care of us. At A Traveled Path Homes we want you to take a load off so we offer exclusive housing to
            traveling medical professionals and educators.
          </Typography>
          <PropertySearch />
          <a href={secrets.waitlistUrl} style={{ display: "block", maxWidth: "fit-content", marginTop: "1rem" }} target="_blank">
            <Button variant="contained">Join Our Waitlist</Button>
          </a>{" "}
        </Box>
      </Box>
    </Box>
  );
};

const imagesStyle = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center",
};

export default HomeBanner;
