import { createAsyncThunk } from '@reduxjs/toolkit';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { LoginDTO, ResetPasswordDTO, SignUpDTO } from '../schema/dto/auth.dto';
import localStorageService from '../utils/localstorage.utils';
import { removeUser } from '../redux/user.slice';

export const login: any = createAsyncThunk('login', async (data: LoginDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  // the major field is the username field
  if (data.username?.includes('@')) {
    data = { email: data.username, username: '', password: data.password };
  } else {
    data = { username: data.username, email: '', password: data.password };
  }
  dispatch(openPreloader('Logging you in'));

  try {
    const response = await http.post('/api/auth/login', data);
    dispatch(closePreloader({}));
    return { token: response?.data?.token };
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const register: any = createAsyncThunk('register', async (data: SignUpDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Creating your account'));

  try {
    const response = await http.post('/api/auth/register', data);
    dispatch(closePreloader({}));
    return { token: response?.data?.token };
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const forgotPassword: any = createAsyncThunk('forgot-password', async (email: string, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Sending password reset link'));

  try {
    const response = await http.post('/api/auth/forgot-password', { email });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const resetPassword: any = createAsyncThunk('resetPassword', async (data: ResetPasswordDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Resetting password'));

  try {
    const response = await http.post('/api/auth/reset-password', {
      password: data.password,
      code: data.code,
      token: data.token,
    });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const loginWithGoogle: any = createAsyncThunk('loginWithGoogle', async (access_token: string, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Authenticating account'));

  try {
    const response = await http.post('/api/auth/google', { token: access_token });
    dispatch(closePreloader({}));
    return { token: response?.data?.token, isNew: response?.data?.isNew };
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const logout: any = createAsyncThunk('logOut', async (_, thunkApi) => {
  /**
   * remove the token
   * remove the user
   * clear the user from the reducer
   * redirect to login
   */

  window.location.href = '/';
  await localStorageService.token.delete();
  await localStorageService.user.delete();
  await localStorageService.currentGroup.delete();
  await thunkApi.dispatch(removeUser());
});
