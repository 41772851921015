import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReservation, IReservationSlice } from '../schema/interfaces/reservation.interface';
import { getPropertyReservations, getSingleReservation, getUserReservations } from '../services/reservations.services';
import { getPaymentIntent } from '../services/payment.services';

const initialState: IReservationSlice = {
  reservations: [],
  singleReservation: undefined,
  handlers: { payment: { isLoading: false }, reservation: { isLoading: false } },
};

const reservationSlice = createSlice({
  name: 'reservationSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserReservations.fulfilled, (state, action: PayloadAction<IReservation[]>) => {
        state.reservations = action.payload;
      })
      .addCase(getPropertyReservations.fulfilled, (state, action: PayloadAction<IReservation[]>) => {
        state.reservations = action.payload;
      })
      .addCase(getSingleReservation.pending, (state) => {
        state.handlers.reservation.isLoading = true;
      })
      .addCase(getSingleReservation.fulfilled, (state, action: PayloadAction<IReservation>) => {
        state.singleReservation = action.payload;
        state.handlers.reservation.isLoading = false;
      })
      .addCase(getSingleReservation.rejected, (state) => {
        state.handlers.reservation.isLoading = false;
      })
      .addCase(getPaymentIntent.pending, (state) => {
        state.handlers.payment.isLoading = true;
      })
      .addCase(getPaymentIntent.fulfilled, (state) => {
        state.handlers.payment.isLoading = false;
      })
      .addCase(getPaymentIntent.rejected, (state) => {
        state.handlers.payment.isLoading = false;
      });
  },
});

const reservationReducer = reservationSlice.reducer;
export const {} = reservationSlice.actions;
export default reservationReducer;
