import React, { useEffect, useMemo } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";
import SingleReservation from "../../components/UI/SingleReservation";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyReservations } from "../../services/reservations.services";
import { getSingleProperty } from "../../services/property.services";
import { RootState } from "../../redux/store";
import { getPropertyImages } from "../../utils/property.utils";

function PropertyReservations() {
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const mediumScreen = useMediaQuery("(max-width:1000px)");
  const property = useSelector((state: RootState) => state.properties.singleProperty);
  const reservations = useSelector((state: RootState) => state.reservations.reservations);

  const image = useMemo(() => getPropertyImages(property)?.[0], [property]);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleProperty(id));
    dispatch(getPropertyReservations(id));
  }, []);

  return (
    <DashboardLayout>
      <div className="dashboard">
        <h2>Property Reservations</h2>

        <Box display="flex" justifyContent="flex-start" flexDirection={mediumScreen ? "column" : "row"} marginTop={4} gap={2}>
          <img
            src={image}
            style={{
              width: "350px",
              maxWidth: "100%",
              borderRadius: 10,
              objectFit: "cover",
              objectPosition: "center",
              ...(smallScreen ? { height: "250px" } : {}),
            }}
          />

          <Box>
            <h2>{property?.name}</h2>
            <p style={{ marginTop: 20, fontSize: ".9rem" }}>{property?.description}</p>
            <p style={{ fontWeight: "bold", marginTop: 10 }}>
              ${property?.monthlyPrice}/Month, ${property?.dailyPrice}/day
            </p>
          </Box>
        </Box>

        {reservations && reservations?.length > 0 && (
          <Grid container gap={4} sx={{ marginTop: 5 }}>
            {reservations?.map((reservation, index) => {
              return <SingleReservation {...reservation} key={index} />;
            })}
          </Grid>
        )}

        {(!reservations || reservations.length === 0) && (
          <Box width="100%" height="200px" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1" fontWeight="bold">
              This property has no reservation
            </Typography>
          </Box>
        )}
      </div>
    </DashboardLayout>
  );
}

export default PropertyReservations;
