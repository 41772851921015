import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { TableHeader, TableHeaderEntry, TableLayout, TableRow, TableRowEntry, TableRowsContainer } from "../../UI/Table";
import CustomLoader from "../../UI/CustomLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetAdminPaymentDTO } from "../../../schema/dto/payment.dto";
import { getAdminPayments } from "../../../services/payment.services";
import { PaymentStatus as IPaymentStatus } from "../../../schema/enums/payment.enum";
import { ActionDispatchResponse } from "../../../schema/interfaces/redux.interface";
import { PaginatedResponse } from "../../../schema/dto/query.dto";
import { IPayment } from "../../../schema/interfaces/payment.interface";
import PaginationContainer from "../../UI/Pagination";
import PaymentStatus from "./PaymentStatus";

const headers = [
  { name: "User", width: 200 },
  { name: "Property Name", width: 200 },
  { name: "Price", width: 100 },
  { name: "Status", width: 100 },
  { name: "", width: 150 },
];

interface Props {
  displayPagination: boolean;
}

export default function PaymentsTable({ displayPagination }: Props) {
  const [status, setStatus] = useState<IPaymentStatus | "all">("all");
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = searchParams.get("page") ?? 1;

  const payments = useSelector((state: RootState) => state.payment.payments);
  const loading = useSelector((state: RootState) => state.payment.handlers.isLoading);

  useEffect(() => {
    const getPaymentLogs = async function () {
      const query: GetAdminPaymentDTO = { page, ...(status != "all" ? { status: status as IPaymentStatus } : {}) };
      const data: ActionDispatchResponse<PaginatedResponse<IPayment>> = await dispatch(getAdminPayments(query));
      if (!data?.error) {
        setPagesCount(data?.payload?.totalPages);
      }
    };

    getPaymentLogs();
  }, [page, status]);

  return (
    <Box>
      <CustomLoader loading={loading} text="Fetching payment logs" />

      <FormControl sx={{ marginY: 3, maxWidth: "fit-content", marginLeft: "auto", display: "block" }}>
        <InputLabel id="payment-status">Status</InputLabel>
        <Select
          label="Status"
          labelId="payment-status"
          // @ts-ignore
          value={status as any}
          onChange={(e) => {
            setStatus(e.target.value as IPaymentStatus);
          }}
          size="small"
          style={{ width: "150px", fontSize: ".8rem", textTransform: "capitalize" }}
        >
          <MenuItem value={"all"} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
            All
          </MenuItem>
          <MenuItem value={IPaymentStatus.SUCCEEDED} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
            {IPaymentStatus.SUCCEEDED}
          </MenuItem>
          <MenuItem value={IPaymentStatus.PENDING} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
            {IPaymentStatus.PENDING}
          </MenuItem>
        </Select>
      </FormControl>

      <TableLayout>
        <TableHeader>
          {headers?.map((header, index) => {
            return (
              <TableHeaderEntry width={header.width} key={index}>
                {header.name}
              </TableHeaderEntry>
            );
          })}
        </TableHeader>

        <TableRowsContainer>
          {payments?.map((payment, index) => {
            return (
              <TableRow key={index} InnerProps={{ style: { alignItems: "center" } }}>
                <TableRowEntry width={headers[0]?.width} InnerProps={{ style: { display: "flex", alignItems: "center", gap: "15px" } }}>
                  <Box
                    component="img"
                    src={payment?.User?.profilePicture}
                    sx={{ width: "30px", height: "30px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    fontSize=".8rem"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {payment?.User?.firstName} {payment?.User?.lastName}
                  </Typography>
                </TableRowEntry>

                <TableRowEntry width={headers[1]?.width}>
                  <>{payment?.Reservation?.Property?.name}</>
                </TableRowEntry>

                <TableRowEntry width={headers[2]?.width}>${(payment?.amountReceived / 100).toLocaleString()}</TableRowEntry>

                <TableRowEntry width={headers[3]?.width}>
                  <PaymentStatus status={payment?.status} />
                </TableRowEntry>
                <TableRowEntry width={headers[4]?.width}>
                  <Button size="small" variant="contained" onClick={() => navigate(`/admin/payments/${payment?.id}`)}>
                    View Payment
                  </Button>
                </TableRowEntry>
              </TableRow>
            );
          })}
        </TableRowsContainer>
      </TableLayout>

      {displayPagination && (
        <Box marginTop={2} maxWidth={"fit-content"} marginLeft={"auto"}>
          <PaginationContainer length={pagesCount} />
        </Box>
      )}
    </Box>
  );
}
