import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IHandlers } from '../schema/interfaces/handlers.interface';

const initialState: IHandlers = {
  errorModal: {
    isOpen: false,
    text: [],
  },
  preloader: {
    isOpen: false,
    text: '',
  },
};

const handlersSlice = createSlice({
  name: 'handlersSlice',
  initialState,
  reducers: {
    openErrorModal: (state, action: PayloadAction<string[]>) => {
      state.errorModal = { isOpen: true, text: action.payload };
    },

    closeErrorModal: (state, action) => {
      state.errorModal = initialState.errorModal;
    },

    openPreloader: (state, action: PayloadAction<string>) => {
      state.preloader = { isOpen: true, text: action.payload };
    },

    closePreloader: (state, action) => {
      state.preloader = initialState.preloader;
    },
  },
});

const handlersReducer = handlersSlice.reducer;
export const { closeErrorModal, closePreloader, openErrorModal, openPreloader } = handlersSlice.actions;

export default handlersReducer;
