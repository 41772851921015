import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  isOpen: boolean;
  close(): void;
}

export default function VerificationModal({ isOpen, close }: Props) {
  if (!isOpen) return <></>;

  return (
    <Modal open={true} onClose={close}>
      <Box
        sx={{
          width: "90vw",
          maxWidth: "400px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: 1,
          borderRadius: 1,
        }}
      >
        <IconButton onClick={close} sx={{ maxWidth: "fit-content", marginLeft: "auto", display: "flex" }}>
          <Close />
        </IconButton>

        <Box marginTop={1}>
          <Typography component="h2" textAlign={"center"} fontSize={"1rem"} marginBottom={1} fontWeight={"bold"}>
            Verification Required
          </Typography>
          <Typography variant="body1" textAlign={"center"} fontSize={".8rem"}>
            Unfortunately, your identification information was not approved, click on the button below to update your identification information
          </Typography>

          <Link
            to={"/profile/edit/identification"}
            style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "fit-content", marginTop: 20, marginBottom: 15, display: "block" }}
          >
            <Button variant="contained" size="small">
              Update Information
            </Button>
          </Link>
        </Box>
      </Box>
    </Modal>
  );
}
