import React, { FormEvent, useState } from "react";
import { IUser } from "../../schema/interfaces/user.interface";
import { Box, Button, Icon, Modal, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { sendMessage } from "../../services/chat.services";
import { BiLoaderAlt } from "react-icons/bi";
import checkIsOwner, { isAdmin as checkIsAdmin } from "../../utils/user-groups.utils";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../UI/CustomLoader";

interface Props {
  user?: IUser;
  propertyId?: number;
  closeModal(): void;
  isOpen: boolean;
}

export default function SendMessageModal({ isOpen, closeModal, propertyId = undefined, user }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state: RootState) => state.chat?.handlers?.sendMessage?.isLoading);
  const [text, setText] = useState<string>("");
  const isOwner = checkIsOwner();
  const isAdmin = checkIsAdmin();

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!text || !text.trim()) return;
    const data = await dispatch(sendMessage({ receiverId: user?.id, propertyId, text }));
    if (!data.error) {
      window.location.href = isAdmin ? `/admin/messages` : `/${isOwner ? "owner" : "renter"}/messages`;
    }
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        sx={{
          width: "90vw",
          maxWidth: "400px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 1.5,
          background: "white",
          borderRadius: 1.5,
        }}
      >
        <Close fontSize="medium" sx={{ display: "block", maxWidth: "fit-content", marginLeft: "auto" }} cursor="pointer" onClick={closeModal} />

        <Box sx={{ display: "flex", gap: 1.1 }}>
          <img
            src={user?.profilePicture}
            alt={user?.firstName}
            style={{ width: "50px", height: "50px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
          />
          <Typography variant="subtitle1">
            {user?.firstName} {user?.lastName}
          </Typography>
        </Box>

        <Box component="form" onSubmit={submit} marginTop={2} width="100%">
          <TextField
            rows={4}
            size="small"
            multiline={true}
            variant="outlined"
            placeholder="Send Message..."
            InputProps={{ sx: { fontSize: ".8rem" } }}
            fullWidth
            value={text}
            onChange={(e) => setText(e.target.value)}
          />

          <Button
            variant="contained"
            disabled={loading}
            size="small"
            type="submit"
            sx={{ maxWidth: "fit-content", display: "block", marginLeft: "auto", marginTop: 2, ":disabled": {} }}
          >
            {loading ? (
              <>
                <CustomLoader text="Sendiing Message" loading={loading} />
              </>
            ) : (
              "Send Message"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
