import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Pagination } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  length: number;
  customUpdate?: (page: number) => void;
}

export default function PaginationContainer({ length, customUpdate }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  if (!length) return <></>;
  return (
    <Pagination
      sx={{ all: "unset", background: "white" }}
      count={length}
      variant="outlined"
      shape="rounded"
      size="small"
      siblingCount={1}
      onChange={(e, page) => {
        if (customUpdate) {
          customUpdate(page);
        } else {
          setSearchParams({ ...searchParams, page: page.toString() });
        }
      }}
    />
  );
}
