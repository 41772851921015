import { Typography } from "@mui/material";
import React from "react";

const Step1 = () => {
  return (
    <section
      style={{
        minHeight: 350,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        fontWeight={"bold"}
        marginBottom={4}
        component="h2"
      >
        Welcome!
      </Typography>

      <p
        style={{
          maxWidth: 500,
          marginLeft: "auto",
          textAlign: "center",
          marginRight: "auto",
        }}
      >
        Whether you{"'"}re an experienced landlord or a first time property
        renter, A Traveled Path Homes is here to get your property into the
        hearts and lives of traveling professionals. We{"'"}ll walk you through
        the steps to add your property and get your property looking like the
        home it is.
      </p>
    </section>
  );
};

export default Step1;
