import {
  CurrencyExchange,
  Dashboard,
  Edit,
  FavoriteBorder,
  HouseOutlined,
  MailOutline,
  MessageOutlined,
  MoneyOutlined,
  PeopleOutline,
  PersonOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import { Link } from "../../schema/interfaces/link.interface";

export const ownerLinks: Link[] = [
  {
    text: "Home",
    href: "/",
    isNavbarLink: true,
  },

  {
    icon: <Edit />,
    text: "Manage Properties",
    href: "/owner/listings",
    isNavbarLink: false,
  },

  {
    icon: <PersonOutline />,
    text: "Profile",
    href: "/profile",
    isNavbarLink: false,
  },
  {
    icon: <CurrencyExchange />,
    text: "Manage Financials",
    href: "/owner/wallet",
    isNavbarLink: false,
  },
  {
    icon: <MailOutline />,
    text: "Messages",
    href: "/owner/messages",
    isNavbarLink: false,
  },
  {
    text: "Properties",
    isNavbarLink: true,
    sublinks: [
      { text: "Properties", href: "/properties" },
      { text: "Map", href: "/map" },
    ],
  },

  {
    text: "About",
    isNavbarLink: true,
    sublinks: [
      { text: "About Us", href: "/about" },
      { text: "Blogs", href: "/blogs" },
      { text: "Contact", href: "/contact" },
    ],
  },
];

export const renterLinks: Link[] = [
  {
    text: "Home",
    href: "/",
    isNavbarLink: true,
  },

  {
    icon: <HouseOutlined />,
    text: "Current Stays",
    href: "/renter/dashboard",
    isNavbarLink: false,
  },
  {
    icon: <FavoriteBorder />,
    text: "Saved",
    href: "/renter/saved",
    isNavbarLink: false,
  },
  {
    icon: <PersonOutline />,
    text: "Profile",
    href: "/profile",
    isNavbarLink: false,
  },
  {
    icon: <MailOutline />,
    text: "Messages",
    href: "/renter/messages",
    isNavbarLink: false,
  },

  {
    text: "Properties",
    isNavbarLink: true,
    sublinks: [
      { text: "Properties", href: "/properties" },
      { text: "Map", href: "/map" },
    ],
  },

  {
    text: "About",
    isNavbarLink: true,
    sublinks: [
      { text: "About Us", href: "/about" },
      { text: "Blogs", href: "/blogs" },
      { text: "Contact", href: "/contact" },
    ],
  },
];

export const adminLinks: Link[] = [
  {
    text: "Home",
    href: "/",
    isNavbarLink: true,
  },
  {
    icon: <Dashboard />,
    text: "Dashboard",
    href: "/admin/dashboard",
    isNavbarLink: false,
  },
  {
    icon: <PeopleOutline />,
    text: "Users",
    href: "/admin/users",
    isNavbarLink: false,
  },
  {
    icon: <HouseOutlined />,
    text: "Properties",
    href: "/admin/properties",
    isNavbarLink: false,
  },
  {
    icon: <CurrencyExchange />,
    text: "Payments",
    href: "/admin/payments",
    isNavbarLink: false,
  },
  {
    icon: <MessageOutlined />,
    text: "Messages",
    href: "/admin/messages",
    isNavbarLink: false,
  },
  {
    icon: <PhoneOutlined />,
    text: "Contact Messages",
    href: "/admin/contact",
    isNavbarLink: false,
  },
];
