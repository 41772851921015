import React from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Button, Grid } from "@mui/material";
import FinancialsSummary from "../../components/Financials/Summary";
import MonthlyFinanceChart from "../../components/Financials/MonthlyFinance";
import YearlyFinanceChart from "../../components/Financials/YearlyFinance";
import LatePayments from "../../components/Financials/LatePayments";
import UpcomingPayments from "../../components/Financials/UpcomingPayments";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function ManageFinancials() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <Box component="section" paddingY={2}>
        <Box component={"header"} width="100%" display={"flex"} justifyContent={"flex-end"} marginBottom={2}>
          <Button
            endIcon={<ArrowForward fontSize="small" />}
            variant="contained"
            size="small"
            onClick={() => {
              navigate("/owner/wallet");
            }}
          >
            View Wallet
          </Button>
        </Box>
        {/* Header */}
        <Grid container justifyContent="space-between" flexWrap="wrap">
          <Grid item xs={3.5}>
            <FinancialsSummary value="$230,400" title="Total Earnings" />
          </Grid>
          <Grid item xs={3.5}>
            <FinancialsSummary value="80" title="Renters" />
          </Grid>
          <Grid item xs={3.5}>
            <FinancialsSummary value="5" title="Active Listings" />
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"} gap={3} flexWrap={"wrap"} sx={{ marginTop: 4 }}>
          <Grid item xs={12} lg={6.5}>
            <MonthlyFinanceChart />
            <YearlyFinanceChart />
          </Grid>
          <Grid item xs={12} lg={5}>
            <LatePayments />
            <UpcomingPayments />
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}
