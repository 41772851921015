import { createAsyncThunk } from '@reduxjs/toolkit';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { closePreloader, openPreloader } from '../redux/handlers.slice';

export const getUserWallet: any = createAsyncThunk('getUserWallet', async (_, thunkApi) => {
  try {
    const response = await http.get(`/api/wallet`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getWalletLogs: any = createAsyncThunk('getWalletLogs', async function (_, thunkApi) {
  try {
    const response = await http.get(`/api/wallet/transactions`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const withdraw: any = createAsyncThunk('withdraw', async function (amount: string, thunkApi) {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Processing payment'));

  try {
    const response = await http.post(`/api/wallet/withdraw`, { amount });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
