import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IChatSlice, IConversation, IMessage } from '../schema/interfaces/chat.interface';
import { getConversations, getMessages, sendMessage } from '../services/chat.services';

const initialState: IChatSlice = {
  messages: [],
  conversations: [],
  currentConversation: undefined,
  handlers: {
    messages: { isLoading: false },
    conversations: { isLoading: false },
    sendMessage: { isLoading: false },
  },
};

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    updateCurrentConversation(state, action: PayloadAction<IConversation | undefined>) {
      state.currentConversation = action.payload;
    },
    updateMessages(state, action: PayloadAction<IMessage>) {
      state.messages = [...state.messages, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.handlers.sendMessage.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.handlers.sendMessage.isLoading = false;
      })
      .addCase(sendMessage.rejected, (state) => {
        state.handlers.sendMessage.isLoading = false;
      })
      .addCase(getConversations.pending, (state) => {
        state.handlers.conversations.isLoading = true;
      })
      .addCase(getConversations.fulfilled, (state, action: PayloadAction<IConversation[]>) => {
        state.conversations = action.payload;
        state.handlers.conversations.isLoading = false;
      })
      .addCase(getConversations.rejected, (state) => {
        state.handlers.conversations.isLoading = false;
      })
      .addCase(getMessages.pending, (state) => {
        state.handlers.messages.isLoading = true;
      })
      .addCase(getMessages.fulfilled, (state, action: PayloadAction<IMessage[]>) => {
        state.messages = action.payload;
        state.handlers.messages.isLoading = false;
      })
      .addCase(getMessages.rejected, (state) => {
        state.handlers.messages.isLoading = false;
      });
  },
});

const chatReducer = chatSlice.reducer;
export const { updateCurrentConversation, updateMessages } = chatSlice.actions;
export default chatReducer;
