import React from "react";
import { IBlog } from "../../schema/interfaces/blog.interface";
import { Card, CardActions, CardContent, CardMedia, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props extends IBlog {}

export default function SingleBlog({ title, subtitle, link, src, tag, date }: Props) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} md={3.8}>
      <Card sx={{ maxWidth: "300px", marginX: "auto" }}>
        <CardMedia image={`/images/blog/${src}`} title={title} sx={{ height: 140 }} />
        <CardContent sx={{ height: "210px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Typography gutterBottom variant="h6" fontSize={"1.1rem"} lineHeight={1.1} component="div">
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary" flex={1}>
            {subtitle.length > 100 ? `${subtitle.slice(0, 100)}...` : subtitle}
          </Typography>

          <CardActions sx={{ paddingLeft: 0 }}>
            <Button size="small" variant="contained" onClick={() => navigate(link)}>
              View
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
}
