import React, { ChangeEvent, useState } from "react";
import Navbar from "../components/UI/Navbar/Navbar";
import { Box, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetPassword } from "../services/auth.services";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ResetForgottenPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const slug = useParams();
  const { code, token } = slug;

  const isMatch: boolean = password === confirmPassword;

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = await dispatch(
      resetPassword({
        password,
        code,
        token,
      })
    );

    if (!data?.error) {
      await Swal.fire({
        title: "Password Reset",
        text: "Your password has been changed successfully, login again to go to your dashboard",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      navigate("/login");
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ paddingY: "1.4rem" }}>
        <Paper
          elevation={1}
          sx={{
            padding: "1rem",
            width: "90vw",
            maxWidth: "500px",
            marginX: "auto",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"} color="primary" marginBottom={2}>
            Reset Password
          </Typography>

          <Stack gap={3} component={"form"} onSubmit={submit}>
            <Box>
              <label htmlFor="password">Enter Password</label>
              <TextField
                variant="outlined"
                label="Password"
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
              />
            </Box>

            <Box>
              <label htmlFor="confirm-password">Confirm Password</label>
              <TextField
                variant="outlined"
                label="Confirm password"
                id="confirm-password"
                type="password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
              />
            </Box>
            {!isMatch && <p style={{ marginTop: 1, color: "red" }}>Passwords do not match</p>}

            <Button
              fullWidth
              color="primary"
              type="submit"
              disabled={!isMatch}
              variant="contained"
              sx={{ paddingY: 1.2 }}
            >
              Submit
            </Button>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default ResetForgottenPassword;
