import { Box, Typography } from "@mui/material";
import React from "react";
import { IOwnershipDocuments } from "../../schema/interfaces/property.interface";
import { ImFilePdf } from "react-icons/im";
import { red } from "@mui/material/colors";
import { shortenText } from "../../utils/text.utils";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";

interface Props extends IOwnershipDocuments {}

export default function SingleDocument({ name, id }: Props) {
  const { id: propertyId } = useParams();

  return (
    <Box
      component={Link}
      to={`/properties/${propertyId}/documents/${id}`}
      sx={{
        display: "block",
        background: "white",
        boxShadow: "0px 2px 2px rgba(0,0,0,.2)",
        ":hover": { boxShadow: "0px 3px 3px rgba(0,0,0,.2)" },
        padding: 1,
        borderRadius: 0.4,
        cursor: "pointer",
      }}
    >
      <Box sx={{ background: "#f2f2f2", height: "150px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ImFilePdf size={32} color={red[400]} />
      </Box>

      <Box component={"footer"} sx={{ paddingTop: 1 }}>
        <Typography variant="subtitle1" component="p" fontSize=".9rem" fontWeight="bold">
          <span style={{ display: "inline-block", verticalAlign: "middle", marginRight: "5px" }}>
            <BsFileEarmarkPdfFill size={25} color={red[400]} />
          </span>{" "}
          {shortenText(name, 100)}
        </Typography>
      </Box>
    </Box>
  );
}
