import React, { useEffect } from "react";
import ProtectedProvider from "../../components/Providers/ProtectedProvider";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import { Box, Container, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link, useParams } from "react-router-dom";
import { getSingleDocument } from "../../services/property.services";
import { ArrowBack } from "@mui/icons-material";
import CustomLoader from "../../components/UI/CustomLoader";
import PdfViewer from "../../components/PropertyDocuments/PdfViewer";

export default function SingleDocument() {
  const {
    singleDocument: document,
    handlers: { isLoading: loading },
  } = useSelector((state: RootState) => state.properties);
  const { id, propertyId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleDocument(id));
  }, []);
  return (
    <ProtectedProvider>
      <Navbar />
      <Container maxWidth="lg" sx={{ paddingY: 4, flex: 1 }}>
        {loading ? (
          <CustomLoader size={32} text="Fetch Document" loading={loading} />
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Link to={`/properties/${propertyId}/documents`}>
                <IconButton>
                  <ArrowBack />
                </IconButton>
              </Link>
              <Typography variant="h6" fontWeight={"bold"} component="h1">
                {document?.name} - (PDF)
              </Typography>
            </Box>

            <Box sx={{ marginTop: 3, padding: 1, width: "100%", backgroundColor: "#333741" }}>
              <Box sx={{ width: "700px", maxWidth: "90%", marginX: "auto", overflowX: "scroll" }}>
                <PdfViewer {...document} />
              </Box>
            </Box>
          </>
        )}
      </Container>
      <Footer />
    </ProtectedProvider>
  );
}
