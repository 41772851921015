import { Button, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import React, { useMemo } from "react";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { loginWithGoogle } from "../../services/auth.services";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getProfile } from "../../services/profile.services";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import useGoogleAuth from "../../hooks/useGoogleAuth";

const GoogleButton = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = useMemo(() => searchParams.get("redirect"), [searchParams]);
  const { handleGoogleLogin } = useGoogleAuth({ onSuccess, redirectRoute: pathname });

  const isLogin = pathname === "/login";

  async function onSuccess(access_token: string) {
    console.log(access_token);
    const data: ActionDispatchResponse<{ token: string; isNew: boolean }> = await dispatch(loginWithGoogle(access_token));
    if (!data.error) {
      let title: string | undefined;
      let text: string | undefined;
      if (isLogin) {
        if (data.payload.isNew === true) {
          title = "Success";
          text = "You have successfully signed in with google and your account has been created";
        } else if (data.payload.isNew === false) {
          title = "Success";
          text = "You have successfully signed in to your account, you'll be redirected to your dashboard now";
        }
      } else {
        if (data.payload.isNew === true) {
          title = "Success";
          text = "You have successfully created your account";
        } else {
          title = "Success";
          text = "This account already exists and you have been successfully logged in";
        }
      }

      await Swal.fire({
        title,
        text,
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      if (redirect) {
        await dispatch(getProfile(data?.payload?.token));
        navigate(redirect);
      } else {
        window.location.href = "/";
      }
    }
  }

  return (
    <Button
      sx={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        marginTop: 0.2,
        paddingY: 1,
        boxShadow: "0px 1px 2px rgba(0,0,0,0.3)",
        backgroundColor: blue[700],
        "&:hover": {
          backgroundColor: blue[800],
        },
      }}
      type="button"
      fullWidth
      onClick={handleGoogleLogin}
    >
      <FcGoogle />

      <Typography variant="subtitle1" component={"p"}>
        {isLogin ? "Sign in with google" : "Sign up with google"}
      </Typography>
    </Button>
  );
};

export default GoogleButton;
