import { Box, Typography, TypographyTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React, { HTMLAttributes, ReactElement } from "react";

interface BaseProps<C = any> {
  children?: C | ReactElement | ReactElement[];
  InnerProps?: HTMLAttributes<any>;
}

interface TableLayoutProps extends BaseProps {}

export const TableLayout = ({ children, InnerProps }: TableLayoutProps) => {
  return (
    <Box
      width="100%"
      {...InnerProps}
      sx={{
        borderRadius: 3,
        borderTop: "1.5px solid lightgray",
        borderLeft: "1.5px solid lightgray",
        borderRight: "1.5px solid lightgray",
        maxWidth: "100%",
        overflowX: "scroll",
        boxShadow: "0px 2px 3px rgba(0,0,0,.2)",
      }}
    >
      {children}
    </Box>
  );
};

interface TableHeaderProps extends BaseProps {}

export const TableHeader = ({ children, InnerProps }: TableHeaderProps) => {
  return (
    <Box
      component="header"
      {...InnerProps}
      sx={{
        minWidth: "fit-content",
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: "#53cbf0",
        justifyContent: "space-between",
        color: "white",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};

interface TableHeaderEntryProps extends BaseProps<string> {
  width: number;
}

export const TableHeaderEntry = ({ width, InnerProps, children }: TableHeaderEntryProps) => {
  return (
    <Typography
      variant="body1"
      fontWeight="bold"
      {...InnerProps}
      sx={{
        textAlign: "left",
        fontSize: ".8rem",
        padding: ".4rem",
        cursor: "pointer",
        width,
        minWidth: width,
        maxWidth: width,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Typography>
  );
};

interface TableRowsContainerProps extends BaseProps {}

export const TableRowsContainer = ({ InnerProps, children }: TableRowsContainerProps) => {
  return (
    <Box
      {...InnerProps}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: "fit-content",
        background: "white",
      }}
    >
      {children}
    </Box>
  );
};

interface TableRowProps extends BaseProps {}

export const TableRow = ({ InnerProps, children }: TableRowProps) => {
  return (
    <Box {...InnerProps} sx={{ minWidth: "fit-content", justifyContent: "space-between", borderBottom: "1.5px solid lightgray", display: "flex" }}>
      {children}
    </Box>
  );
};

interface TableRowEntryProps extends BaseProps<string | string[]> {
  width: number;
  component?: any;
}

export const TableRowEntry = ({ children, width, InnerProps, component }: TableRowEntryProps) => {
  return (
    <Typography
      variant="body1"
      fontWeight="500"
      component={component ?? "div"}
      {...InnerProps}
      sx={{
        flex: 1,
        textAlign: "left",
        fontSize: ".8rem",
        padding: ".4rem",
        cursor: "pointer",
        width,
        minWidth: width,
        maxWidth: width,
      }}
    >
      {children}
    </Typography>
  );
};
