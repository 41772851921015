import React, { Dispatch, ReactElement, SetStateAction } from "react";
import "./Travelers.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import { RootState } from "../../../redux/store";
import { updatePropertySearchBody } from "../../../redux/property-search.slice";

interface Props {
  children?: ReactElement | ReactElement[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function Travelers(props: Props) {
  const dispatch = useDispatch();

  const { guest } = useSelector((state: RootState) => state?.propertySearch);

  if (!props.isOpen) return <></>;

  return (
    <div className="travelers">
      <div className="travelers-inner">
        <h3>Number of Travelers:</h3>
        <TextField
          type="number"
          sx={{ border: "none", "& fieldset": { border: "none" } }}
          value={guest}
          onChange={(e) =>
            dispatch(
              updatePropertySearchBody({
                key: "guest",
                value: e.target.value,
              })
            )
          }
          InputProps={{
            inputProps: {
              min: 0,
              style: { textAlign: "right", width: "35px", fontSize: "32px" },
            },
          }}
          required
        />
        <Button
          variant="contained"
          className="close-btn"
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Close
        </Button>
        {props.children}
      </div>
    </div>
  );
}

export default Travelers;
