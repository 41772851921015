import React, { useEffect, useMemo, useState } from "react";
import ContentBox from "./ContentBox";
import { Phone } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { getContactMessages } from "../../../services/contact.services";
import { ActionDispatchResponse } from "../../../schema/interfaces/redux.interface";
import { IContact } from "../../../schema/interfaces/contact.interface";
import { RootState } from "../../../redux/store";

export default function ContactsCount() {
  const contacts = useSelector((state: RootState) => state.contact.contacts);
  const loading = useSelector((state: RootState) => state.contact.handlers.isLoading);
  const dispatch = useDispatch();
  const count = useMemo(() => contacts.length, [contacts]);

  useEffect(() => {
    dispatch(getContactMessages());
  }, []);

  return (
    <ContentBox iconBg={grey[800]} title="Contact Messages" count={count} icon={<Phone fontSize="small" />} loading={loading} link="/admin/contact" />
  );
}
