import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getSingleReservation, updateReservationStatus } from "../../services/reservations.services";
import { useParams } from "react-router-dom";
import { Box, Stack, Typography, useMediaQuery, Button } from "@mui/material";
import { getDateAndMonth } from "../../utils/date.utils";
import { getPropertyImages } from "../../utils/property.utils";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { IReservationStatus } from "../../schema/enums/reservations.enum";
import Swal from "sweetalert2";

export default function SingleReservation() {
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const mediumScreen = useMediaQuery("(max-width:1000px)");
  const reservation = useSelector((state: RootState) => state?.reservations?.singleReservation);
  const dispatch = useDispatch();
  const { id } = useParams();

  const image = useMemo(() => getPropertyImages(reservation?.Property)?.[0], [reservation?.Property]);

  const markAsCompleted = async function () {
    const data = await dispatch(updateReservationStatus({ id, status: IReservationStatus.COMPLETED }));
    if (!data.error) {
      await Swal.fire({
        title: "Reservation Status",
        text: "You have successfully marked this reservation as completed",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });
      await dispatch(getSingleReservation(id));
    }
  };

  useEffect(() => {
    dispatch(getSingleReservation(id));
  }, []);

  return (
    <DashboardLayout>
      <Box display="flex" justifyContent="flex-start" flexDirection={mediumScreen ? "column" : "row"} marginTop={4} gap={2}>
        <img
          src={image}
          style={{
            width: "350px",
            maxWidth: "100%",
            borderRadius: 10,
            objectFit: "cover",
            objectPosition: "center",
            ...(smallScreen ? { height: "250px" } : {}),
          }}
        />

        <Box>
          <h2>{reservation?.Property?.name}</h2>
          <p style={{ marginTop: 20, fontSize: ".9rem" }}>{reservation?.Property?.description}</p>
          <p style={{ fontWeight: "bold", marginTop: 10 }}>
            {getDateAndMonth(reservation?.startDate)} - {getDateAndMonth(reservation?.endDate)}
          </p>
        </Box>
      </Box>

      <Box component="section" marginTop={2}>
        <Typography variant="h6" fontWeight="bold">
          Reservation Information
        </Typography>

        <Stack flexDirection={"column"} gap={0.7}>
          <Typography variant="subtitle1" fontSize=".8rem">
            Amount: <b>${reservation?.totalPrice.toLocaleString()} </b>
          </Typography>
          <Typography variant="subtitle1" fontSize=".8rem">
            Status: <b style={{ textTransform: "capitalize" }}> {reservation?.status} </b>
          </Typography>

          {reservation?.status === IReservationStatus.CONFIRMED && (
            <Button variant="contained" size="small" sx={{ maxWidth: "250px" }} onClick={markAsCompleted}>
              Mark as completed
            </Button>
          )}
        </Stack>
      </Box>
    </DashboardLayout>
  );
}
