import React, { FormEvent, useCallback, useMemo, useState } from "react";
import { Box, InputLabel, Stack, Typography, FormControl, TextField, Select, MenuItem, Button } from "@mui/material";
import { Currency, getAllISOCodes } from "iso-country-currency";
import { useDispatch, useSelector } from "react-redux";
import { openErrorModal } from "../../redux/handlers.slice";
import { RootState } from "../../redux/store";
import { BiLoaderAlt } from "react-icons/bi";
import Swal, { SweetAlertResult } from "sweetalert2";
import { createPaymentProfile } from "../../services/payment-profile.services";
import { becomeAnOwner, getProfile } from "../../services/profile.services";

interface Props {
  updateStep(): void;
}

export default function Step2({ updateStep }: Props) {
  const dispatch = useDispatch();
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [isoCode, setIsoCode] = useState<string | undefined>(undefined);
  const [routingNumber, setRoutingNumber] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState<string>("");
  const [accountHolderName, setAccountHolderName] = useState<string>("");
  const countries = useMemo(() => getAllISOCodes().map((iso) => iso.countryName), []);
  const loading = useSelector((state: RootState) => state.paymentProfile.handlers.paymentProfile.isLoading);

  const selectCountry = useCallback((country: string) => {
    const countryIso = getAllISOCodes().find((iso) => iso.countryName === country) as Currency;
    setCountry(countryIso.countryName);
    setCurrency(countryIso.currency);
    setIsoCode(countryIso.iso);
  }, []);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!country || !currency || !isoCode) {
      dispatch(openErrorModal(["Select Country & Currency"]));
      return;
    }
    if (accountNumber != confirmAccountNumber) {
      dispatch(openErrorModal(["Account numbers do not match"]));
      return;
    }

    if (routingNumber.length != 9) {
      dispatch(openErrorModal(["Routing number must not be lesser/greater than 9 digits"]));
      return;
    }
    Swal.fire({
      icon: "warning",
      title: "Confirm",
      text: "Are you sure you want to become an owner?",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result: SweetAlertResult) => {
      if (result?.isConfirmed) {
        const paymentProfileData = await dispatch(
          createPaymentProfile({ accountHolderName, accountNumber, routingNumber, currency, country: isoCode })
        );
        if (!paymentProfileData?.error) {
          const data = await dispatch(becomeAnOwner());
          if (!data?.error) {
            await Swal.fire({
              title: "Success",
              text: "You are now an owner",
              timer: 2000,
              icon: "success",
              showConfirmButton: false,
            });
            await dispatch(getProfile());
            // route to owner's dashboard
            window.location.href = "/owner/listings";
          }
        }
      }
    });
  };

  return (
    <Box component="form" marginTop={5} width={"90vw"} maxWidth={"600px"} onSubmit={onSubmit}>
      <Stack component="section" flexDirection={"column"} gap={2} width={"100%"}>
        {/* Routing Number */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="routingNumber">
            Routing Number
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="routingNumber"
            name="routingNumber"
            value={routingNumber}
            onChange={(e) => setRoutingNumber(e.target.value)}
            required={true}
            type="number"
            helperText="Routing number must not be lesser/greater than 9 digits"
            placeholder="110000000"
            size="small"
          />
        </Box>

        {/* Country */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7, width: "100%" }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium">
            Country
          </Typography>
          <FormControl fullWidth sx={{ width: "100%" }}>
            <InputLabel id="country">Country</InputLabel>
            <Select
              label="Country"
              labelId="country"
              value={country || "Select Country"}
              onChange={(e) => {
                selectCountry(e.target.value);
              }}
              size="small"
              fullWidth={true}
              style={{ fontSize: ".8rem", textTransform: "capitalize" }}
            >
              <MenuItem disabled={true} value={"Select Country"} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                Select Country
              </MenuItem>

              {countries?.map((country, index) => {
                return (
                  <MenuItem key={index} value={country} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                    {country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        {/* Currency */}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="currency">
            Currency
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="currency"
            disabled={true}
            name="currency"
            required={true}
            value={currency}
            placeholder="Select Country"
            size="small"
          />
        </Box>

        {/* Account Number */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="accountNumber">
            Account Number
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="accountNumber"
            name="accountNumber"
            required={true}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            type="number"
            placeholder="000123456789"
            size="small"
            helperText="Your account number must be a checking account"
          />
        </Box>

        {/* Confirm Account Number */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="confirmAccountNumber">
            Confirm Account Number
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="confirmAccountNumber"
            name="confirmAccountNumber"
            value={confirmAccountNumber}
            onChange={(e) => setConfirmAccountNumber(e.target.value)}
            required={true}
            type="number"
            placeholder="000123456789"
            size="small"
          />
        </Box>

        {/* Account Holder Name */}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.7 }}>
          <Typography sx={{ display: "block", fontSize: ".8rem" }} fontWeight="medium" component="label" htmlFor="accountHolderName">
            Account Holder Name
          </Typography>
          <TextField
            InputProps={{ sx: { fontSize: ".8rem" } }}
            variant="outlined"
            id="accountHolderName"
            name="accountHolderName"
            value={accountHolderName}
            onChange={(e) => setAccountHolderName(e.target.value)}
            required={true}
            type="text"
            placeholder="E.g John Doe"
            size="small"
          />
        </Box>
      </Stack>

      {/* Submit Button */}
      <Box width={"100%"} display="flex" gap={2} flexWrap="wrap">
        <Button size="small" disabled={loading} onClick={updateStep} variant="outlined" fullWidth type="button" sx={{ marginTop: 2, flex: 1 }}>
          Back
        </Button>
        <Button
          size="small"
          disabled={loading}
          startIcon={loading ? <BiLoaderAlt size={24} className="animate-spin" /> : <></>}
          variant="contained"
          fullWidth
          type="submit"
          sx={{ marginTop: 2, flex: 1 }}
        >
          {loading ? "Submitting" : "Submit"}
        </Button>
      </Box>
    </Box>
  );
}
