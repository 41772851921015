import React from "react";
import { PaymentStatus as IPaymentStatus } from "../../../schema/enums/payment.enum";
import { Box } from "@mui/material";
import { green, grey } from "@mui/material/colors";

interface Props {
  status: IPaymentStatus;
}

export default function PaymentStatus({ status }: Props) {
  return (
    <Box
      component="span"
      sx={{ padding: 0.5, borderRadius: 1, color: "white", background: status === IPaymentStatus.SUCCEEDED ? green["A700"] : grey[800] }}
      fontSize=".8rem"
    >
      {status === IPaymentStatus?.SUCCEEDED ? "Done" : "Pending"}
    </Box>
  );
}
