import axios from 'axios';
import { IGeoCodeResult } from '../schema/interfaces/map.interface';
import { FetchByCoordinatesDTO } from '../schema/dto/map.dto';
import secrets from '../constants/secrets.const';

// check => https://geoapify.com/
const apiKey = secrets.geoapifyKey;
const getForwardApiUrl = (text: string) => `https://api.geoapify.com/v1/geocode/search?text=${text}&apiKey=${apiKey}`;
const getReverseApiUrl = (lat: number, lon: number) => `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lon}&apiKey=${apiKey}`;
const getAutocompleteUrl = (text: string) => `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&apiKey=${apiKey}`;

interface RequestReturnType {
  properties: IGeoCodeResult;
}

export const fetchLocationByCoordinates = async (data: FetchByCoordinatesDTO): Promise<IGeoCodeResult | undefined> => {
  const response = await axios.get<{ features: RequestReturnType }>(getReverseApiUrl(data?.lat, data?.lon));

  return response?.data?.features?.properties;
};

export const autoCompleteLocations = async (search: string): Promise<IGeoCodeResult[]> => {
  const { data } = await axios.get<{ features: RequestReturnType[] }>(getAutocompleteUrl(search));

  return data?.features?.map((dto) => dto.properties);
};
