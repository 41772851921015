import React, { ReactElement } from "react";
import localStorageService from "../../utils/localstorage.utils";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openErrorModal } from "../../redux/handlers.slice";

interface Props {
  children?: ReactElement | ReactElement[];
}

export default function AdminProvider({ children }: Props) {
  const dispatch = useDispatch();
  const user = localStorageService.user.get();

  if (!user?.isAdmin) {
    dispatch(openErrorModal(["You do not have access to this resource"]));
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}
