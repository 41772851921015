import { Box, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "../components/UI/Navbar/Navbar";
import GoogleButton from "../components/UI/GoogleButton";
import Footer from "../components/UI/Footer";
import { useDispatch } from "react-redux";
import { login } from "../services/auth.services";
import Swal from "sweetalert2";
import { getProfile } from "../services/profile.services";
import { ActionDispatchResponse } from "../schema/interfaces/redux.interface";
import Banner from "../components/UI/Banner";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirect = useMemo(() => searchParams.get("redirect"), [searchParams]);

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    let data: ActionDispatchResponse<{ token: string }> = await dispatch(login({ username, password }));

    if (!data.error) {
      await Swal.fire({
        title: "Log in",
        text: "You have successfully logged in to your account, you'll  be redirected to your dashboard now",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      if (redirect) {
        await dispatch(getProfile(data?.payload?.token));
        navigate(redirect);
      } else {
        window.location.href = "/";
      }
    }
  };

  return (
    <>
      <Box component="section">
        <Navbar />
        <Banner text="Login" />
        <Container maxWidth="lg" sx={{ paddingY: "1.4rem", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Paper
            elevation={1}
            sx={{
              padding: "1rem",
              width: "90vw",
              maxWidth: "500px",
              marginX: "auto",
            }}
          >
            <Typography variant="h5" fontWeight={"bold"} color="primary" marginBottom={2}>
              Log In
            </Typography>

            <Stack spacing={3} component={"form"} onSubmit={submit}>
              <Box>
                <label htmlFor="username">Enter Email/Username</label>
                <TextField
                  variant="outlined"
                  label="Email/Username"
                  id="username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  color="primary"
                  sx={{ width: "100%", marginTop: "10px" }}
                />
              </Box>

              <Box>
                <label htmlFor="password">Enter Password</label>
                <TextField
                  variant="outlined"
                  label="Password"
                  id="password"
                  type="password"
                  color="primary"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ width: "100%", marginTop: "10px" }}
                />
              </Box>

              <Button type="submit" fullWidth color="primary" variant="contained" sx={{ paddingY: 1.2 }}>
                Login
              </Button>
              <GoogleButton />

              <Box display="flex" marginTop={1} alignItems="center" justifyContent="space-between" gap={2} flexWrap="wrap">
                <Typography>
                  Don't have an account?{" "}
                  <Link to={"/registration"} color="darkblue" style={{ textDecoration: "underline" }}>
                    Register
                  </Link>
                </Typography>

                <Typography>
                  <Link to={"/forgot-password"} color="darkblue" style={{ textDecoration: "underline" }}>
                    Forgot Password?
                  </Link>
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Login;
