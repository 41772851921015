import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IWallet, IWalletLog, IWalletSlice } from '../schema/interfaces/wallet.interface';
import { getUserWallet, getWalletLogs } from '../services/wallet.services';

const initialState: IWalletSlice = {
  singleWallet: undefined,
  walletLogs: [],
  handlers: { wallet: { isLoading: false }, walletLogs: { isLoading: false } },
};

const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getUserWallet.pending, (state) => {
        state.handlers.wallet.isLoading = true;
      })
      .addCase(getUserWallet.fulfilled, (state, action: PayloadAction<IWallet>) => {
        state.singleWallet = action.payload;
        state.handlers.wallet.isLoading = false;
      })
      .addCase(getUserWallet.rejected, (state) => {
        state.handlers.wallet.isLoading = false;
      })
      .addCase(getWalletLogs.pending, (state) => {
        state.handlers.walletLogs.isLoading = true;
      })
      .addCase(getWalletLogs.fulfilled, (state, action: PayloadAction<IWalletLog[]>) => {
        state.walletLogs = action.payload;
        state.handlers.walletLogs.isLoading = false;
      })
      .addCase(getWalletLogs.rejected, (state) => {
        state.handlers.walletLogs.isLoading = false;
      }),
});

const walletReducer = walletSlice.reducer;
export const {} = walletSlice.actions;
export default walletReducer;
