import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Container, Typography } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import BlogSubtitle from "../../components/Blog/Subtitle";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

export default function GetYourShot() {
  return (
    <>
      <Navbar />
      <BlogTheme>
        <Container maxWidth="md" sx={{ paddingY: 5 }} component={"article"}>
          <BlogBanner
            src="get-your-shot.png"
            imageStyle={{ objectPosition: "top" }}
            title="Taking the First Step: Why Getting the COVID-19 Shot Matters"
            date="December 12, 2023"
            tag="Health"
          />

          <Typography component={"p"} variant="body1">
            In the face of the COVID-19 pandemic, the development and distribution of vaccines have been pivotal in our collective fight against this
            global health crisis. Getting vaccinated against COVID-19 is not only an act of personal responsibility but also a significant
            contribution to our community's well-being and a step towards reclaiming a sense of normalcy in our lives.
          </Typography>

          <BlogSubtitle header="Why Get Vaccinated?">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Protecting Yourself and Others"
              answer="COVID-19 vaccines have undergone rigorous testing and are proven to be safe and effective. By getting vaccinated, you significantly lower your risk of severe illness or complications from the virus. Additionally, you play a vital role in reducing the spread of COVID-19 to those around you, especially the more vulnerable members of our community."
            />
            <PrivacyPolicySubtopicsList
              count={2}
              question="Building Herd Immunity"
              answer="Vaccination creates a shield of immunity within the community. When a significant portion of the population is vaccinated, it helps prevent the spread of the virus, protecting those who cannot receive the vaccine due to medical reasons or age."
            />
          </BlogSubtitle>

          <BlogSubtitle header="What to Expect When Getting Vaccinated">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Safety Measures"
              answer="Vaccination sites follow strict safety protocols to ensure a safe environment for everyone. Wear a mask, practice social distancing, and follow all guidelines provided at the vaccination center."
            />
            <PrivacyPolicySubtopicsList
              count={2}
              question="Minimal Discomfort"
              answer="The vaccination process itself is quick and relatively painless. You may experience mild side effects like soreness at the injection site, fatigue, or a slight fever, which are normal signs that your body is building immunity."
            />
            <PrivacyPolicySubtopicsList
              count={3}
              question="Post-Vaccination Care"
              answer="Stay hydrated, get plenty of rest, and monitor any side effects. Most side effects are short-lived and can be managed with rest and over-the-counter medications if needed."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Final Thoughts">
            <Typography component={"p"} variant="body1" marginTop={1}>
              The decision to get vaccinated is a personal one, but it's also a powerful choice that affects our collective ability to overcome this
              pandemic. Each vaccination brings us closer to a world where we can hug loved ones, gather with friends, and enjoy the activities we
              cherish.
            </Typography>

            <Typography component={"p"} variant="body1" marginTop={1}>
              If you have any concerns or questions about the COVID-19 vaccine, consult trusted healthcare professionals or reliable sources to get
              accurate information.
            </Typography>

            <Typography component={"p"} variant="body1" marginTop={1}>
              Remember, by taking this simple step of getting vaccinated, we're not only protecting ourselves but also contributing to the well-being
              of our entire community. Together, let's embrace this opportunity to move forward towards a healthier and safer future.
            </Typography>

            <Typography component={"p"} variant="subtitle1" marginTop={1}>
              Stay safe, stay informed, and consider getting vaccinated today.
            </Typography>
            <Typography component={"p"} variant="subtitle1" marginTop={1} color={"#53cbf0"}>
              #VaccinateToWin #COVID19Vaccine
            </Typography>
          </BlogSubtitle>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
