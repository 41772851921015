import React, { useState } from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { Box, Grid, useMediaQuery } from "@mui/material";
import Conversations from "../components/Chat/Conversations";
import ChatRoom from "../components/Chat/ChatRoom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import useSocket from "../hooks/useSocket";

/**
 * @mobile => display ChatRoom when there's an openedChat, if there's no openedChat display Conversation
 * @non_mobile => display ChatRoom and Conversations side by side
 */

export default function Chat() {
  const currentConversation = useSelector((state: RootState) => state.chat?.currentConversation);
  const screenQuery = useMediaQuery("(max-width: 1000px)");

  return (
    <DashboardLayout>
      <Box paddingY={screenQuery ? 0 : 3} minHeight="100%">
        {!screenQuery && (
          <Grid container gap={4} height="100%" sx={{ minHeight: "calc(100vh - 150px)" }}>
            <Grid item lg={3.5} xs={4.5}>
              <Conversations />
            </Grid>
            <Grid item lg={7.5} xs={6.5}>
              <ChatRoom />
            </Grid>
          </Grid>
        )}

        {screenQuery && <>{!currentConversation ? <Conversations /> : <ChatRoom />}</>}
      </Box>
    </DashboardLayout>
  );
}
