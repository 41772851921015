import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import PropertySearch from "../components/Home/PropertySearch/PropertySearch";
import Navbar from "../components/UI/Navbar/Navbar";
import ResidencesItem from "../components/UI/ResidencesItem";
import Footer from "../components/UI/Footer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getProperties } from "../services/property.services";
import { useLoaderData, useLocation, useSearchParams } from "react-router-dom";
import CustomLoader from "../components/UI/CustomLoader";
import Banner from "../components/UI/Banner";
import { IPropertyStatus } from "../schema/enums/property.enum";

const PropertiesList = () => {
  const dispatch = useDispatch();
  const sliceProperties = useSelector((state: RootState) => state.properties.properties);
  const loading = useSelector((state: RootState) => state.properties.handlers.isLoading);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const guest = searchParams.get("guest");
  const longitude = searchParams.get("longitude");
  const latitude = searchParams.get("latitude");

  const properties = useMemo(() => sliceProperties.filter((property) => property.status === IPropertyStatus.APPROVED), [sliceProperties]);

  useEffect(() => {
    if (location.pathname === "/properties" && !location.search) {
      dispatch(getProperties());
    } else {
      dispatch(getProperties({ guest, longitude, latitude }));
    }
  }, [location]);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar />
      <Banner>
        <Typography color={"white"} variant="h3" fontWeight={"bold"} marginBottom={3}>
          Properties
        </Typography>
        <PropertySearch />
      </Banner>
      <Container maxWidth={"lg"} sx={{ paddingX: 2, paddingY: 5, flex: 1 }}>
        <CustomLoader size={26} text="Loading Properties" loading={loading} sx={{ marginTop: 2 }} />

        {properties?.length > 0 && (
          <Grid gap={2} flex={1} container marginTop={4}>
            {properties?.map((property, index) => {
              return <ResidencesItem key={index} {...property} />;
            })}
          </Grid>
        )}

        {!loading && (!properties || properties?.length === 0) && (
          <Box flex={1} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={2}>
            <p style={{ fontWeight: "bold" }}>Properties not available</p>
          </Box>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

export default PropertiesList;
