import { Box, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  question: string;
  answer: string;
  count?: number;
  children?: ReactElement | ReactElement[];
}

export default function PrivacyPolicySubtopicsList({ question, answer, children, count }: Props) {
  return (
    <Box>
      <Typography variant="body1" marginY={0.4}>
        <b style={{ textDecoration: "underline" }}>
          {count && `${count}.)  `}
          {question}:
        </b>{" "}
        {answer}
      </Typography>
      {children && (
        <Stack component={"ul"} gap={1} flexDirection={"column"} paddingLeft={2}>
          {children}
        </Stack>
      )}
    </Box>
  );
}
