import React from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Typography } from "@mui/material";
import PaymentsTable from "../../components/Admin/Payment/PaymentsTable";
import AdminProvider from "../../components/Providers/AdminProvider";

export default function Payments() {
  return (
    <DashboardLayout>
      <AdminProvider>
        <Box sx={{ marginBottom: 5 }}>
          <Typography variant="h5">Payment Logs</Typography>
        </Box>

        <PaymentsTable displayPagination={true} />
      </AdminProvider>
    </DashboardLayout>
  );
}
