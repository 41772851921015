import { Box, Typography } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  children?: ReactElement | ReactElement[];
  header: string;
}

export default function BlogSubtitle({ children, header }: Props) {
  return (
    <Box component="section">
      <Typography component={"h2"} variant="subtitle1" marginY={1}>
        {header}
      </Typography>
      {/* Body */}
      {children && <Box component={"main"}>{children}</Box>}
    </Box>
  );
}
