import React, { useEffect } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProperties } from "../../services/property.services";
import ResidencesItem from "../../components/UI/ResidencesItem";
import { RootState } from "../../redux/store";
import { getUserReservations } from "../../services/reservations.services";

function Dashboard() {
  const dispatch = useDispatch();
  const { reservations } = useSelector((state: RootState) => state.reservations);

  useEffect(() => {
    dispatch(getUserReservations());
  }, []);
  return (
    <DashboardLayout>
      <div className="dashboard">
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
          <h2>Current Stays</h2>
        </Box>

        {reservations && reservations?.length > 0 && (
          <Grid container gap={4} sx={{ marginTop: 2 }}>
            {reservations?.map(({ Property, startDate, endDate, totalPrice, status, id }, index) => {
              return (
                <ResidencesItem
                  {...Property}
                  key={index}
                  isReservation={true}
                  reservationEndDate={endDate as Date}
                  reservationPrice={totalPrice}
                  reservationStartDate={startDate as Date}
                  reservationStatus={status}
                  viewLink={`/renter/reservation/${id}`}
                />
              );
            })}
          </Grid>
        )}

        {(!reservations || reservations?.length == 0) && (
          <Box minHeight="60vh" display="flex" alignItems="center" justifyContent="center">
            <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>You have not rented any property</p>
          </Box>
        )}
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
