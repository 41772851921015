import { green } from "@mui/material/colors";
import { Stripe, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import secrets from "../constants/secrets.const";

interface Response {
  stripe: Stripe | PromiseLike<Stripe | null> | null;
  options: StripeElementsOptions;
}

export default function useInitStripe(amount: number): Response {
  const stripe = useMemo(() => loadStripe(secrets.stripeKey.dev as string), []);

  const options = useMemo(
    (): StripeElementsOptions => ({
      mode: "payment",
      currency: "usd",
      payment_method_types: ["card"],
      amount: Math.round(amount * 100),
      appearance: {
        theme: "flat",
        variables: {
          colorPrimary: "#53cbf0",
          fontFamily: "Poppins, sans-serif",
          colorDanger: "darkred",
          colorSuccess: green[500],
          borderRadius: "6px",
        },
      },
    }),
    [amount]
  );

  return { stripe, options };
}
