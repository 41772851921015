import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IContact, IContactSlice } from '../schema/interfaces/contact.interface';
import { getContactMessages } from '../services/contact.services';
import { PaginatedResponse } from '../schema/dto/query.dto';

export const initialState: IContactSlice = {
  contacts: [],
  singleContact: undefined,
  handlers: { isLoading: false },
};

const contactSlice = createSlice({
  name: 'contacrSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactMessages.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getContactMessages.fulfilled, (state, action: PayloadAction<PaginatedResponse<IContact>>) => {
        state.contacts = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getContactMessages.rejected, (state) => {
        state.handlers.isLoading = false;
      });
  },
});

const contactReducer = contactSlice.reducer;
export const {} = contactSlice.actions;
export default contactReducer;
