import React, { useState, ReactElement, Dispatch, SetStateAction } from "react";
import "./CheckInCheckOut.css";
import { useDispatch, useSelector } from "react-redux";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Box, Button, Grid } from "@mui/material";
import { m } from "framer-motion";
import { RootState } from "../../../redux/store";
import { updatePropertySearchBody } from "../../../redux/property-search.slice";

interface Props {
  children?: ReactElement | ReactElement[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function CheckInCheckOut(props: Props) {
  const dispatch = useDispatch();
  const { checkInDate, checkOutDate } = useSelector((state: RootState) => state?.propertySearch);

  if (!props.isOpen) return <></>;

  return (
    <div className="daypicker">
      <div className="daypicker-inner">
        <Button variant="contained" sx={{ marginLeft: "auto", display: "block", marginBottom: 2 }} onClick={() => props.setIsOpen(false)}>
          Close
        </Button>

        <Grid container spacing={2}>
          <Grid item columns={6}>
            <h2>Check-In Date</h2>
            <DayPicker
              mode="single"
              defaultMonth={(checkInDate as Date) || new Date()}
              selected={(checkInDate as Date) || new Date()}
              style={{ maxWidth: "100%", overflow: "hidden" }}
              onSelect={(e) => {
                dispatch(updatePropertySearchBody({ key: "checkInDate", value: e }));
              }}
            />
          </Grid>
          <Grid item columns={6}>
            <h2>Check-Out Date</h2>
            <DayPicker
              mode="single"
              defaultMonth={(checkOutDate as Date) || new Date()}
              style={{ maxWidth: "100%", overflow: "hidden" }}
              selected={(checkOutDate as Date) || new Date()}
              onSelect={(e) => dispatch(updatePropertySearchBody({ key: "checkOutDate", value: e }))}
            />
          </Grid>
        </Grid>
        {props.children}
      </div>
    </div>
  );
}

export default CheckInCheckOut;
