import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";

export default function UpcomingPayments() {
  return (
    <Box borderRadius={5} border={"1.5px solid gray"} paddingX={1.5} paddingY={3} marginTop={3}>
      <Box component="header" display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Typography variant="h6" fontWeight="bold">
          Upcoming Payments
        </Typography>
        <Typography variant="body2" color="primary" sx={{ cursor: "pointer" }}>
          See All
        </Typography>
      </Box>

      <Stack flexDirection={"column"} gap={2} marginTop={2} sx={{ maxHeight: "150px", overflowY: "scroll" }} justifyContent={"space-between"}>
        {new Array(8).fill(null).map((_, index) => {
          return (
            <Box key={index} component="article" display="flex" alignItems="center" justifyContent="center" gap={1.5}>
              <img
                src={PROPERTY_IMAGE}
                alt=""
                style={{ width: "40px", height: "40px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
              />
              <Typography variant="subtitle1" fontWeight={".8rem"}>
                $1,200 for property at abule egba
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
