import React, { FormEvent, useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Button } from "@mui/material";
import { openErrorModal } from "../../redux/handlers.slice";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { GetPaymentIntentResponse } from "../../schema/dto/payment.dto";
import { getPaymentIntent } from "../../services/payment.services";
import { useLocation, useParams } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";

export default function PaymentForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { id: reservationId } = useParams();

  const submit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();

    if (!elements || !stripe) {
      return;
    }
    const { error: submissionError } = await elements.submit();
    if (submissionError?.message) {
      setLoading(false);
      return dispatch(openErrorModal([submissionError.message]));
    }
    const payment_intent: ActionDispatchResponse<GetPaymentIntentResponse> = await dispatch(getPaymentIntent(reservationId));
    if (!payment_intent.error) {
      const clientSecret = payment_intent.payload.client_secret;
      const response = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/properties/reservation/${reservationId}/payment/success`,
        },
      });

      setLoading(false);
      if (response?.error) {
        return dispatch(openErrorModal([response?.error?.message as string]));
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit}>
      <PaymentElement />
      <Button
        disabled={loading}
        startIcon={loading ? <BiLoaderAlt className="animate-spin" /> : <></>}
        fullWidth
        variant="contained"
        sx={{ marginTop: 2 }}
        type="submit"
      >
        {loading ? "Paying" : "Pay"}
      </Button>
    </form>
  );
}
