import React, { useEffect } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUserBookmarks } from "../../services/bookmark.services";
import { Box, Container, Grid } from "@mui/material";
import ResidencesItem from "../../components/UI/ResidencesItem";
import { RootState } from "../../redux/store";
import { BiLoaderAlt } from "react-icons/bi";
import CustomLoader from "../../components/UI/CustomLoader";

export default function SavedProperties() {
  const { bookmarks, handlers } = useSelector((state: RootState) => state.bookmarks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserBookmarks());
  }, []);

  return (
    <DashboardLayout>
      <div className="dashboard">
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} flexWrap="wrap">
          <h2>Saved Properties</h2>
        </Box>

        {handlers.isLoading ? (
          <>
            <CustomLoader loading={handlers?.isLoading} sx={{ marginTop: 1 }} />
          </>
        ) : (
          <>
            {" "}
            {bookmarks && bookmarks?.length > 0 && (
              <Grid container gap={4} sx={{ marginTop: 2 }}>
                {bookmarks?.map(({ Property }, index) => {
                  return <ResidencesItem {...Property} key={index} />;
                })}
              </Grid>
            )}
            {(!bookmarks || bookmarks?.length == 0) && (
              <Box minHeight="60vh" display="flex" alignItems="center" justifyContent="center">
                <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>You have not saved any property</p>
              </Box>
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
}
