import React, { useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import WalletBanner from "../../components/Wallet/Banner";
import { WalletTable } from "../../schema/enums/wallet.enum";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import UserPayments from "../../components/Wallet/UserPayments";
import UserPropertiesPayment from "../../components/Wallet/UserPropertiesPayment";
import Withdrawals from "../../components/Wallet/Withdrawals";

export default function Wallet() {
  const [table, setTable] = useState<WalletTable>(WalletTable.USER_PAYMENTS);

  return (
    <DashboardLayout>
      <WalletBanner />

      <FormControl sx={{ display: "block", maxWidth: "fit-content", marginLeft: "auto", marginY: 2 }}>
        <Select sx={{ fontSize: ".8rem" }} size="small" value={table} onChange={(e) => setTable(e.target.value as WalletTable)}>
          <MenuItem sx={{ fontSize: ".8rem" }} value={WalletTable.USER_PAYMENTS}>
            My Reservations
          </MenuItem>
          <MenuItem sx={{ fontSize: ".8rem" }} value={WalletTable.USER_PROPERTIES_PAYMENTS}>
            My Properties Payments
          </MenuItem>
          <MenuItem sx={{ fontSize: ".8rem" }} value={WalletTable.USER_WITHDRAWALS}>
            My Withdrawals
          </MenuItem>
        </Select>
      </FormControl>

      <>
        {table === WalletTable.USER_PAYMENTS && <UserPayments />}
        {table === WalletTable.USER_PROPERTIES_PAYMENTS && <UserPropertiesPayment />}
        {table === WalletTable.USER_WITHDRAWALS && <Withdrawals />}
      </>
    </DashboardLayout>
  );
}
