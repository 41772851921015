import { Box, Button, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import Step1 from "../../components/AddPropertySteps/Step1";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Step2 from "../../components/AddPropertySteps/Step2";
import Step3 from "../../components/AddPropertySteps/Step3";
import Step4 from "../../components/AddPropertySteps/Step4";
import Step5 from "../../components/AddPropertySteps/Step5";
import Step6 from "../../components/AddPropertySteps/Step6";
import Step7 from "../../components/AddPropertySteps/Step7";
import Step8 from "../../components/AddPropertySteps/Step8";
import Step9 from "../../components/AddPropertySteps/Step9";
import Step10 from "../../components/AddPropertySteps/Step10";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useDispatch } from "react-redux";
import { addProperty } from "../../services/property.services";
import Swal from "sweetalert2";

const allSteps = ["describe", "address", "basics", "amenities", "photos", "ownership documents", "stay length", "price", "review"];

const AddProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const screenQuery = useMediaQuery("(max-width: 1000px)");

  const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (currentStep != allSteps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      const data = await dispatch(addProperty());

      if (!data?.error) {
        await Swal.fire({
          title: "Add Property",
          text: "You have successfully added a new property",
          timer: 2000,
          showConfirmButton: false,
          icon: "success",
        });

        navigate("/owner/listings");
      }
    }
  };

  return (
    <DashboardLayout>
      <Box display="flex" gap={2}>
        {allSteps?.map((item, index) => {
          return (
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} textAlign={"center"} key={index} flex={1}>
              <Box
                component={"span"}
                marginBottom={1.3}
                height={6}
                width={"100%"}
                display={"block"}
                borderRadius={30}
                sx={{
                  backgroundColor: currentStep === index + 1 ? "#333741" : "#d9d9d9",
                }}
              />
              {!screenQuery && (
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  component={"p"}
                  textTransform={"capitalize"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  color={currentStep === index + 1 ? "#333741" : "#535353"}
                >
                  {item}
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>

      <form onSubmit={submitForm}>
        {currentStep === 0 && <Step1 />}
        {currentStep === 1 && <Step2 />}
        {currentStep === 2 && <Step3 />}
        {currentStep === 3 && <Step4 />}
        {currentStep === 4 && <Step5 />}
        {currentStep === 5 && <Step6 />}
        {currentStep === 6 && <Step7 />}
        {currentStep === 7 && <Step8 />}
        {currentStep === 8 && <Step9 />}
        {currentStep === 9 && <Step10 />}

        {/* Buttons */}

        <Box display="flex" justifyContent={"space-between"} alignItems={"flex-end"} marginTop={4} gap={4.5}>
          <Box>
            <Link to="/owner/listings">
              <Typography variant="subtitle1" sx={{ cursor: "pointer" }} color="#333741" fontWeight="bold" component="p">
                Cancel
              </Typography>
            </Link>
            {currentStep !== 0 && (
              <Button
                variant="contained"
                sx={{ fontWeight: "bold", weight: 150, marginTop: 1.4 }}
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Previous
              </Button>
            )}
          </Box>

          <Button variant="contained" sx={{ fontWeight: "bold", minWeight: 150 }} type="submit">
            {currentStep === allSteps?.length ? "Submit" : "Next"}
          </Button>
        </Box>
      </form>
    </DashboardLayout>
  );
};

export default AddProperty;
