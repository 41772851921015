import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { Dispatch, SetStateAction, useState } from "react";
import { IUser } from "../../schema/interfaces/user.interface";
import Swal, { SweetAlertResult } from "sweetalert2";
import { useDispatch } from "react-redux";
import { getAllUsers, updateUserStatus } from "../../services/profile.services";
import { useSearchParams } from "react-router-dom";

interface Props {
  user: IUser;
}

export default function UserStatus({ user }: Props) {
  const [value, setValue] = useState<string>(user?.isActive ? "Active" : "Disabled");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const page = searchParams.get("page") ?? 1;
  const searchInQuery = searchParams.get("search");

  const onChange = async () => {
    const status = user?.isActive ? "Disable" : "Activate";
    Swal.fire({
      title: `${status} user account`,
      text: `Are you sure you want to ${status.toLowerCase()} this user's account?`,
      icon: "warning",
      confirmButtonText: "Yes",
      confirmButtonColor: "#53cbf0",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "darkred",
    }).then(async (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        await dispatch(updateUserStatus({ id: user.id, isActive: !user?.isActive }));
        await Swal.fire({
          title: `${status} user account`,
          text: `You have successfully ${status.toLowerCase()}d this user's account`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        await dispatch(getAllUsers({ page, username: searchInQuery }));
      }
    });
  };

  return (
    <Box position="relative">
      <FormControl fullWidth>
        <InputLabel id="user-status-label">Status</InputLabel>
        <Select onChange={onChange} size="small" labelId="user-status-label" label="Status" value={value} sx={{ fontSize: ".8rem" }}>
          <MenuItem value={"Active"}>Active</MenuItem>
          <MenuItem value={"Disabled"}>Disabled</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
