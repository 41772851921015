import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { TableHeader, TableHeaderEntry, TableLayout, TableRow, TableRowEntry, TableRowsContainer } from "../../components/UI/Table";
import { getDateAndMonth } from "../../utils/date.utils";
import { getContactMessages } from "../../services/contact.services";
import ContactRow from "../../components/Admin/Contact/ContactRow";
import { GetContactDTO } from "../../schema/dto/contact.dto";
import PaginationContainer from "../../components/UI/Pagination";
import { useSearchParams } from "react-router-dom";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { PaginatedResponse } from "../../schema/dto/query.dto";
import { IContact } from "../../schema/interfaces/contact.interface";
import AdminProvider from "../../components/Providers/AdminProvider";

export default function AdminContact() {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  const [loading, setLoading] = useState<boolean>(false);
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { contacts } = useSelector((state: RootState) => state.contact);
  const dispatch = useDispatch();

  const page = searchParams.get("page") ?? 1;

  const headers = useMemo(() => {
    return [
      { name: "Email", width: 200 },
      ...(screenQuery
        ? []
        : [
            { name: "Subject", width: 200 },
            { name: "Message", width: 150 },
            { name: "Date", width: 150 },
            { name: "", width: 20 },
          ]),
    ];
  }, [screenQuery]);

  useEffect(() => {
    const pageSearch = async () => {
      const query: GetContactDTO = { page };
      const data: ActionDispatchResponse<PaginatedResponse<IContact>> = await dispatch(getContactMessages(query));
      if (!data?.error) {
        setPagesCount(data?.payload?.totalPages);
      }
    };

    pageSearch();
  }, [page]);

  return (
    <DashboardLayout>
      <AdminProvider>
        <Box display="flex" flexDirection={"column"} gap={2} paddingTop={2.5} marginBottom={4}>
          <Typography variant="h5">Messages</Typography>
        </Box>

        <Box>
          <TableLayout>
            <TableHeader>
              {headers?.map((header, index) => {
                return (
                  <TableHeaderEntry width={header?.width} key={index}>
                    {header.name}
                  </TableHeaderEntry>
                );
              })}
            </TableHeader>
            <TableRowsContainer>
              {contacts?.length === 0 && (
                <Typography
                  variant="body1"
                  width={"100%"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  padding={2}
                  fontWeight={"bold"}
                  fontSize=".8rem"
                >
                  No Message Found
                </Typography>
              )}
              {contacts?.length > 0 &&
                contacts?.map((contact, index) => {
                  return <ContactRow contact={contact} key={index} headers={headers} />;
                })}
            </TableRowsContainer>
          </TableLayout>
        </Box>

        <Box marginTop={2} maxWidth={"fit-content"} marginLeft={"auto"}>
          <PaginationContainer length={pagesCount} />
        </Box>
      </AdminProvider>
    </DashboardLayout>
  );
}
