import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Amenity from "../AddPropertySteps/Amenity";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const PropertyAmenities = () => {
  const screenQuery = useMediaQuery("(max-width:900px)");
  const property = useSelector((state: RootState) => state?.properties?.singleProperty);

  return (
    <Box marginTop={4} maxWidth={screenQuery ? "100%" : "350px"} marginX="auto">
      <Typography fontSize={14} fontWeight="bold" variant="subtitle1" component="p">
        Amenities
      </Typography>
      <Box display="flex" gap={1} flexWrap="wrap" marginX={"auto"} alignItems={"center"} marginTop={1.3}>
        {property?.Amenities?.map((amenity, index) => {
          return <Amenity amenity={{ icon: amenity?.icon, text: amenity?.text }} key={index} isSelected={false} />;
        })}
      </Box>
    </Box>
  );
};

export default PropertyAmenities;
