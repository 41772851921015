import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser, IUserGroup, IUserSlice } from '../schema/interfaces/user.interface';
import { login, loginWithGoogle, register } from '../services/auth.services';
import localStorageService from '../utils/localstorage.utils';
import { becomeAnOwner, getAllUsers, getProfile } from '../services/profile.services';
import { UserGroups } from '../schema/enums/groups.enum';
import { PaginatedResponse } from '../schema/dto/query.dto';

const initialState: IUserSlice = {
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  profession: '',
  profilePicture: '',
  groups: [],
  dob: undefined,
  id: 0,
  isActive: false,
  isAdmin: false,
  isStaff: false,
  line1: '',
  line2: '',
  phone: '',
  postalCode: '',
  city: '',
  state: '',
  idNumber: '',
  country: '',
  handlers: {
    isLoading: false,
  },
  users: [],
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    removeUser: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (_, action: PayloadAction<{ token: string }>) => {
        localStorageService.token.store(action.payload.token);
        localStorageService.currentGroup.delete();
      })
      .addCase(register.fulfilled, (_, action: PayloadAction<{ token: string }>) => {
        localStorageService.token.store(action.payload.token);
        localStorageService.currentGroup.delete();
      })
      .addCase(loginWithGoogle.fulfilled, (_, action: PayloadAction<{ token: string }>) => {
        localStorageService.token.store(action.payload.token);
        localStorageService.currentGroup.delete();
      })
      .addCase(getProfile.fulfilled, (_, action: PayloadAction<IUser>) => {
        if (action.payload) {
          localStorageService.user.store(action.payload);
          const currentGroup = localStorageService.currentGroup.get();
          if (!currentGroup) {
            // add them as renters first
            localStorageService.currentGroup.store(UserGroups.RENTER);
          }
        }
        return { ..._, ...action.payload };
      })
      .addCase(becomeAnOwner.fulfilled, () => {
        localStorageService.currentGroup.store(UserGroups.OWNER);
      })
      .addCase(getAllUsers.pending, (state) => {
        state.handlers.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action: PayloadAction<PaginatedResponse<IUser>>) => {
        state.users = action.payload.data;
        state.handlers.isLoading = false;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.handlers.isLoading = false;
      });
  },
});

const userReducer = userSlice.reducer;
export const { removeUser } = userSlice.actions;
export default userReducer;
