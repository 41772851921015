import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//mui components
import { Stack, Grid, Card, CardHeader, CardContent, Container, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import getDashboardType from "../utils/dashboard-type.utils";
import { getProfile } from "../services/profile.services";
import { RootState } from "../redux/store";
import formatDate from "../utils/date.utils";
import { hasOwnerAccount } from "../utils/user-groups.utils";
import UserInformation from "../components/Profile/UserInformation";
import ProfilePicture from "../components/Profile/ProfilePicture";
import AddressInformation from "../components/Profile/AddressInformation";
import { getBankAccount, getPaymentProfile } from "../services/payment-profile.services";
import PaymentInformation from "../components/Profile/BankInformation";
import localStorageService from "../utils/localstorage.utils";

function ProfilePage() {
  const dispatch = useDispatch();
  const user = useMemo(() => localStorageService.user.get(), []);
  const hasOwner = useMemo(() => hasOwnerAccount(user?.groups), []);

  useEffect(() => {
    dispatch(getProfile());
    if (hasOwner) {
      dispatch(getBankAccount());
      dispatch(getPaymentProfile());
    }
  }, [hasOwner]);

  return (
    <DashboardLayout>
      <ProfilePicture />
      <Grid container gap={2} justifyContent={"center"}>
        <Grid item xs={11} md={7.2}>
          <UserInformation />
        </Grid>
        <Grid item xs={11} md={4.5}>
          <AddressInformation />
          <PaymentInformation />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ProfilePage;
