import { Box } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  children: ReactElement | ReactElement[];
}

export default function ChartOverlay({ children }: Props) {
  return <Box sx={{ width: "100%", maxWidth: "100%", overflowX: "scroll", minHeight: "fit-content" }}>{children}</Box>;
}
