import axios from 'axios';
import secrets from '../constants/secrets.const';

export const uploadToCloud = async (media: string | File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', media);
  formData.append('upload_preset', 'atph-images');

  const name = secrets.cloudinary.cloudName;
  // use raw for documents
  const url = (media as File).type.includes('image')
    ? `https://api.cloudinary.com/v1_1/${name}/upload`
    : `https://api.cloudinary.com/v1_1/${name}/raw/upload`;

  const response = await axios.post(url, formData);

  return response.data.secure_url;
};
