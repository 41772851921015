import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../services/profile.services";
import { RootState } from "../../redux/store";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { BiLoaderAlt, BiSearch } from "react-icons/bi";
import { PaginatedResponse } from "../../schema/dto/query.dto";
import { IUser } from "../../schema/interfaces/user.interface";
import PaginationContainer from "../../components/UI/Pagination";
import { useSearchParams } from "react-router-dom";
import UserTable from "../../components/Admin/Users/UserTable";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { GetAllUsersDTO } from "../../schema/dto/profile.dto";
import CustomLoader from "../../components/UI/CustomLoader";
import AdminProvider from "../../components/Providers/AdminProvider";

export default function Users() {
  const [search, setSearch] = useState<string>("");
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.user.handlers);

  const page = searchParams.get("page") ?? 1;
  const searchInQuery = searchParams.get("search");

  const searchUsers = async () => {
    setSearchParams({ ...searchParams, page: "1", search });

    let data: ActionDispatchResponse<PaginatedResponse<IUser>>;
    if (search.length === 0) data = await dispatch(getAllUsers());
    else {
      data = await dispatch(getAllUsers({ username: search, page: 1 }));
    }
    if (!data?.error) {
      setPagesCount(data?.payload?.totalPages as number);
    }
  };

  const initialFetch = async () => {
    const data: ActionDispatchResponse<PaginatedResponse<IUser>> = await dispatch(getAllUsers());
    if (!data?.error) {
      setPagesCount(data?.payload?.totalPages as number);
    }
  };

  const pageSearch = async () => {
    const query: GetAllUsersDTO = { page, ...(searchInQuery ? { username: searchInQuery } : {}) };
    await dispatch(getAllUsers(query));
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    pageSearch();
  }, [page]);

  return (
    <DashboardLayout>
      <AdminProvider>
        <Box display="flex" flexDirection={"column"} gap={2} paddingTop={2.5} marginBottom={4}>
          <Typography variant="h5">Users</Typography>
          <Box maxWidth={"500px"} width="100%" display="flex" gap={2} alignSelf={"flex-end"}>
            <TextField
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              size="small"
              label="Search users"
              sx={{ flex: 1 }}
            />
            <IconButton onClick={searchUsers} color="primary" sx={{ minWidth: "fit-content", flex: 1, maxWidth: "fit-content" }}>
              <BiSearch />
            </IconButton>
          </Box>
        </Box>
        <CustomLoader loading={isLoading} text="Loading users" size={25} />
        <UserTable />

        <Box marginTop={2} maxWidth={"fit-content"} marginLeft={"auto"}>
          <PaginationContainer length={pagesCount} />
        </Box>
      </AdminProvider>
    </DashboardLayout>
  );
}
