import React, { FormEvent } from "react";
import Navbar from "../components/UI/Navbar/Navbar";
import { Box, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import Footer from "../components/UI/Footer";
import { useDispatch } from "react-redux";
import { contactATP } from "../services/contact.services";
import Swal from "sweetalert2";
import Banner from "../components/UI/Banner";

function Contact() {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = await dispatch(contactATP({ email, subject, message }));
    if (!data?.error) {
      await Swal.fire({
        title: "Contact ATP",
        text: "Your message has been received successfully, we'll reply shortly",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      setEmail("");
      setSubject("");
      setMessage("");
    }
  };

  return (
    <Box component="section">
      <Navbar />
      <Banner text="Contact Us" />

      <Container maxWidth="lg" sx={{ paddingY: "1.4rem", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Paper
          elevation={1}
          sx={{
            padding: "1rem",
            width: "90vw",
            maxWidth: "500px",
            marginX: "auto",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"} color="primary" marginBottom={2}>
            Contact Us
          </Typography>

          <Stack spacing={3} component={"form"} onSubmit={submit}>
            <Box>
              <label htmlFor="email">Enter Email</label>
              <TextField
                variant="outlined"
                label="Email"
                id="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
              />
            </Box>

            <Box>
              <label htmlFor="subject">Enter Subject</label>
              <TextField
                variant="outlined"
                label="Subject"
                id="subject"
                type="text"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
              />
            </Box>

            <Box>
              <label htmlFor="message">Enter Message</label>
              <TextField
                variant="outlined"
                label="Message"
                id="message"
                required
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
                InputProps={{
                  sx: {
                    height: "120px",
                    alignItems: "flex-start",
                  },
                  placeholder: "Your message...",
                }}
              />
            </Box>

            <Button fullWidth color="primary" variant="contained" sx={{ paddingY: 1.2 }} type="submit">
              Submit
            </Button>
          </Stack>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}

export default Contact;
