import React from "react";
import ChartOverlay from "./ChartOverlay";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { BarChart, XAxis, Label, YAxis, Tooltip, Legend, Bar, Text } from "recharts";
import { yellow } from "@mui/material/colors";

const data = [
  { year: 2020, amount: 1200 },
  { year: 2021, amount: 1430 },
  { year: 2022, amount: 1350 },
  { year: 2023, amount: 2981 },
];

export default function YearlyFinanceChart() {
  const smallScreen = useMediaQuery(`(max-width: 900px)`);
  const mediumScreen = useMediaQuery(`(max-width: 1200px)`);
  const largerScreen = useMediaQuery(`(min-width: 2000px)`);

  return (
    <ChartOverlay>
      <Box sx={{ paddingLeft: 5, marginTop: 3 }}>
        <Typography variant="h6" fontWeight={"bold"}>
          {new Date().getFullYear() - 3} - {new Date().getFullYear()}
        </Typography>
      </Box>
      <BarChart
        style={{
          minHeight: "fit-content",
          cursor: "pointer",
          fontSize: ".8rem",
          overflowY: "hidden",
          transform: "scale(.9)",
          fontWeight: "bold",
          color: "#111",
        }}
        width={largerScreen ? 900 : smallScreen ? 500 : mediumScreen ? 700 : 500}
        height={250}
        data={data}
      >
        <Tooltip />
        <Legend />
        <Bar dataKey={"amount"} fill={"#53cbf0"} />

        <XAxis dataKey={"year"} />
        <YAxis dataKey={"amount"}>
          <Label value={"Amount($)"} angle={-90} position={"insideLeft"} fontWeight={"bold"} color="#111" />
        </YAxis>
      </BarChart>
    </ChartOverlay>
  );
}
