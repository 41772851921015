import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IBankAccount, IPaymentProfile, IPaymentProfileSlice } from '../schema/interfaces/payment-profile.interface';
import { createPaymentProfile, getBankAccount, getPaymentProfile } from '../services/payment-profile.services';

const initialState: IPaymentProfileSlice = {
  bankAccount: undefined,
  paymentProfile: undefined,
  handlers: {
    paymentProfile: { isLoading: false },
    bankAccount: { isLoading: false },
  },
};

const paymentProfileSlice = createSlice({
  name: 'paymentProfileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPaymentProfile.pending, (state) => {
        state.handlers.paymentProfile.isLoading = true;
      })
      .addCase(createPaymentProfile.fulfilled, (state) => {
        state.handlers.paymentProfile.isLoading = false;
      })
      .addCase(createPaymentProfile.rejected, (state) => {
        state.handlers.paymentProfile.isLoading = false;
      })
      .addCase(getBankAccount.pending, (state) => {
        state.handlers.bankAccount.isLoading = true;
      })
      .addCase(getBankAccount.fulfilled, (state, action: PayloadAction<IBankAccount>) => {
        state.bankAccount = action.payload;
        state.handlers.bankAccount.isLoading = false;
      })
      .addCase(getBankAccount.rejected, (state) => {
        state.handlers.bankAccount.isLoading = false;
      })
      .addCase(getPaymentProfile.pending, (state) => {
        state.handlers.paymentProfile.isLoading = true;
      })
      .addCase(getPaymentProfile.fulfilled, (state, action: PayloadAction<IPaymentProfile>) => {
        state.paymentProfile = action.payload;
        state.handlers.paymentProfile.isLoading = false;
      })
      .addCase(getPaymentProfile.rejected, (state, action: PayloadAction<IPaymentProfile>) => {
        state.handlers.paymentProfile.isLoading = false;
      });
  },
});

const paymentProfileReducer = paymentProfileSlice.reducer;
export const {} = paymentProfileSlice.actions;
export default paymentProfileReducer;
