import { Box, Container, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Amenity from "./Amenity";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Step10 = () => {
  const screenQuery = useMediaQuery("(max-width: 800px)");

  const { name, description, monthlyPrice, dailyPrice, minStay, address, photos, amenities } = useSelector((state: RootState) => state?.propertyForm);

  return (
    <Container maxWidth="md">
      <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
        <Box
          display="flex"
          flexDirection={"column"}
          gap={1.4}
          justifyContent="center"
          alignItems={"center"}
          maxWidth={450}
          marginX={"auto"}
          textAlign="center"
        >
          <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
            Review
          </Typography>

          <Typography variant="subtitle1" component={"p"} lineHeight={1.2}>
            Dust the shelves and clean the windows. Does everthing look like it should? This is what renters will see when looking at your property
            page.
          </Typography>
        </Box>

        <Box display="flex" gap={4} flexDirection={screenQuery ? "column" : "row"} flexWrap={"wrap"} marginTop={4}>
          <Box flex={1} width="100%" maxWidth="400px">
            <img
              src={
                photos?.length > 0
                  ? URL.createObjectURL(photos?.[0] as File)
                  : "https://media.istockphoto.com/id/1026205392/photo/beautiful-luxury-home-exterior-at-twilight.jpg?s=612x612&w=0&k=20&c=HOCqYY0noIVxnp5uQf1MJJEVpsH_d4WtVQ6-OwVoeDo="
              }
              alt="images"
              style={{
                width: "100%",
                height: "300px",
                borderRadius: 20,
                objectFit: "cover",
                objectPosition: "center",
                border: "2px solid lightgray",
                cursor: "pointer",
              }}
            />
          </Box>

          <Stack direction="column" gap={2} sx={{ flex: 1 }}>
            <Typography variant="h6" textAlign="center" fontWeight={"bold"}>
              {name}
            </Typography>
            <Stack
              direction="column"
              gap={1}
              sx={{
                border: "1.5px solid lightgray",
                padding: 2,
                borderRadius: 5,
              }}
            >
              <Typography variant="subtitle1" fontSize=".85rem" fontWeight={"bold"}>
                ${monthlyPrice} per Month
              </Typography>

              <Typography variant="subtitle1" fontSize=".85rem" fontWeight={"bold"}>
                ${dailyPrice} per day
              </Typography>

              <Typography variant="subtitle1" fontSize=".85rem" fontWeight={"bold"}>
                {minStay} {"Month(s)"} Stay Minimum
              </Typography>

              <Typography variant="subtitle1" fontSize=".85rem" fontWeight={"bold"}>
                {address}
              </Typography>

              <Typography variant="body1" fontSize=".8rem">
                {description}
              </Typography>
            </Stack>

            <Box>
              <Typography variant="subtitle1" fontWeight={"bold"} marginBottom={2} textAlign={"center"}>
                Amenities
              </Typography>

              <Box display="flex" gap={1} flexWrap="wrap" marginX={"auto"}>
                {amenities?.map((amenity, index) => {
                  return <Amenity amenity={{ icon: amenity.icon, text: amenity.text }} key={index} isSelected={true} />;
                })}
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Step10;
