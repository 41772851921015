import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";

export default function LatePayments() {
  return (
    <Box borderRadius={5} border={"1.5px solid gray"} paddingX={1.5} paddingY={3}>
      <Box component="header" display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Typography variant="h6" fontWeight="bold">
          Late Payments
        </Typography>
        <Typography variant="body2" color="primary" sx={{ cursor: "pointer" }}>
          See All
        </Typography>
      </Box>

      <Grid container gap={2} marginTop={2} sx={{ maxHeight: "150px", overflowY: "scroll" }} justifyContent={"space-between"}>
        {new Array(12).fill(null).map((_, index) => {
          return (
            <Grid item xs={"auto"} key={index}>
              <Box display="flex" gap={1}>
                <img
                  src={PROPERTY_IMAGE}
                  style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover", objectPosition: "center" }}
                  alt=""
                />
                <Box>
                  <Typography variant="subtitle1" fontSize={".8rem"} fontWeight="bold" lineHeight={1.2}>
                    $1,200
                  </Typography>
                  <Typography variant="subtitle1" fontSize={".8rem"} fontWeight="300" color="primary" lineHeight={1.2}>
                    charge
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
