import React, { useState } from "react";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { IProperty } from "../../schema/interfaces/property.interface";
import { IconButton } from "@mui/material";
import { isPropertySavedByUser } from "../../utils/property.utils";
import localStorageService from "../../utils/localstorage.utils";
import Swal, { SweetAlertResult } from "sweetalert2";
import { useDispatch } from "react-redux";
import { bookmarkProperty, deleteBookmark, getUserBookmarks } from "../../services/bookmark.services";
import { useLocation } from "react-router-dom";

interface Props {
  property: IProperty;
  userId: number;
}

export default function BookmarkIcon({ property, userId }: Props) {
  const [isSaved, setIsSaved] = useState<boolean>(isPropertySavedByUser(property, userId));
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const action = async () => {
    const text = isSaved ? "Are you sure you want to remove this property from your bookmarks" : "Are you sure you want to save this property";
    const title = isSaved ? "Remove from bookmarks" : "Save Property";

    Swal.fire({
      title,
      text,
      icon: "warning",
      confirmButtonText: "Yes",
      confirmButtonColor: "#53cbf0",
      showCancelButton: true,
      cancelButtonText: "No",
      cancelButtonColor: "darkred",
    }).then(async (result: SweetAlertResult) => {
      if (result.isConfirmed) {
        const data = await dispatch(isSaved ? deleteBookmark(property.id) : bookmarkProperty(property.id));
        if (!data?.error) {
          const text = isSaved ? "You have successfully removed this property from your bookmarks" : "You have successfully saved this property";
          Swal.fire({
            title,
            text: text,
            timer: 2000,
            showConfirmButton: false,
            icon: "success",
          });
          // if it's in the saved property page, refresh the bookmarks so the property removed won't show
          if (pathname.includes("/saved")) {
            await dispatch(getUserBookmarks());
          } else {
            setIsSaved(!isSaved);
          }
        }
      }
    });
  };

  return (
    <IconButton color="primary" onClick={action}>
      {isSaved ? <Favorite /> : <FavoriteBorderOutlined />}
    </IconButton>
  );
}
