import React from "react";
import ContentBox from "./ContentBox";
import { MapOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Information from "./Information";

export default function AddressInformation() {
  const user = useSelector((state: RootState) => state.user);

  return (
    <ContentBox header="Address Information" headerIcon={<MapOutlined />}>
      <Box sx={{ padding: 2 }}>
        <Grid container columnGap={1} rowGap={1.5}>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Address Line 1" info={user?.line1 || "Not Specified"} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Address Line 2" info={user?.line2 || "Not Specified"} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="State" info={user?.state || "Not Specified"} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Country" info={user?.country || "Not Specified"} />
          </Grid>
        </Grid>
      </Box>
    </ContentBox>
  );
}
