import React, { FormEvent, useState } from "react";
import { TableRowEntry, TableRow } from "../../UI/Table";
import { IContact } from "../../../schema/interfaces/contact.interface";
import { getDateAndMonth } from "../../../utils/date.utils";
import { Box, Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { ActionDispatchResponse } from "../../../schema/interfaces/redux.interface";
import Swal from "sweetalert2";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { replyContact } from "../../../services/contact.services";
const style: React.CSSProperties = { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

interface Props {
  headers: { name: string; width: number }[];
  contact: IContact;
}

export default function ContactRow({ headers, contact }: Props) {
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [response, setResponse] = useState<string>("");
  const dispatch = useDispatch();

  const onSubmit = async function (e: FormEvent) {
    e.preventDefault();
    let data: ActionDispatchResponse = await dispatch(replyContact({ response, id: contact?.id }));
    if (!data?.error) {
      await Swal.fire({
        title: "Contact Message",
        text: "You have successfully replied to this message",
        timer: 2000,
        icon: "success",
        showConfirmButton: false,
      });
      setIsOpen(false);
      setResponse("");
    }
  };

  return (
    <Box component="article" sx={{ borderBottom: "1.5px solid lightgray" }}>
      <TableRow InnerProps={{ style: { borderBottom: 0 } }}>
        <TableRowEntry width={headers[0]?.width} InnerProps={{ style }}>
          {contact.email}
        </TableRowEntry>

        {!screenQuery && (
          <>
            <TableRowEntry width={headers[1]?.width} InnerProps={{ style }}>
              {contact?.subject}
            </TableRowEntry>
            <TableRowEntry width={headers[2]?.width} InnerProps={{ style }}>
              {contact?.message}
            </TableRowEntry>
            <TableRowEntry width={headers[3]?.width} InnerProps={{ style }}>
              {getDateAndMonth(contact.createdAt as Date)}
            </TableRowEntry>
          </>
        )}
        <Box component="span" onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <ExpandMore cursor="pointer" /> : <ExpandLess cursor="pointer" />}
        </Box>
      </TableRow>

      <Box sx={{ overflowY: isOpen ? "scroll" : "hidden", height: isOpen ? (screenQuery ? "160px" : "120px") : "0px", transition: "all 400ms" }}>
        <>
          <Box width="100%" padding=".4rem">
            {screenQuery && (
              <>
                <Typography variant="body1" fontSize={".8rem"} marginBottom={0.5}>
                  <b>Subject: </b> {contact?.subject}
                </Typography>
                <Typography variant="body1" fontSize={".8rem"} marginBottom={0.5}>
                  <b>Date: </b> {getDateAndMonth(contact.createdAt as Date)}
                </Typography>
              </>
            )}

            <Typography variant="body1" fontSize=".8rem">
              <b>Message:</b> {contact?.message}
            </Typography>

            <Box component="form" onSubmit={onSubmit} sx={{ width: "90%", maxWidth: "300px", display: "flex", alignItems: "flex-end", gap: 2 }}>
              <TextField
                fullWidth
                label="Reply"
                multiline={true}
                required={true}
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                size="small"
                rows={2}
                variant="outlined"
                placeholder="Reply..."
                sx={{ marginTop: 2, flex: 1 }}
              />
              <Button variant="contained" size="small" type="submit">
                Send Email
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  );
}
