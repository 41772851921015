import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updatePropertyForm } from "../../redux/property-form.slice";

const Step9 = () => {
  const dispatch = useDispatch();
  const { dailyPrice, monthlyPrice } = useSelector((state: RootState) => state?.propertyForm);

  const updateDailyPrice = (price: string) => {
    dispatch(updatePropertyForm({ key: "dailyPrice", value: price }));
  };
  const updateMonthlyPrice = (price: string) => {
    dispatch(updatePropertyForm({ key: "monthlyPrice", value: price }));
  };

  return (
    <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={1.4}
        justifyContent="center"
        alignItems={"center"}
        maxWidth={450}
        marginX={"auto"}
        textAlign="center"
      >
        <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
          Price
        </Typography>

        <Typography variant="subtitle1" component={"p"} lineHeight={1.2}>
          Prices are recommended based on similar housing in your area. These prices also more accurately represent the cost of housing that a
          traveling professional is expected to pay.
        </Typography>
      </Box>

      <Box display="flex" gap={1} flexWrap={"wrap"} width="100%" justifyContent={"center"}>
        <Box
          width={"100%"}
          maxWidth={"250px"}
          height={"250px"}
          border={`2px solid ${blue[900]}`}
          display="flex"
          marginTop={3}
          flexDirection="column"
          gap={3}
          justifyContent={"center"}
          alignItems="center"
          borderRadius={3}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h5" fontWeight="bold">
            Daily
          </Typography>

          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ fontSize: "60px", color: "#121957" }}>
                  $
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "120px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            required
            value={dailyPrice}
            onChange={(e) => updateDailyPrice(e.target.value)}
          />

          <Typography variant="subtitle1" fontWeight="bold">
            Average: $70 - $90
          </Typography>
        </Box>
        <Box
          width={"100%"}
          maxWidth={"250px"}
          height={"250px"}
          border={`2px solid ${blue[900]}`}
          display="flex"
          marginTop={3}
          flexDirection="column"
          gap={3}
          justifyContent={"center"}
          alignItems="center"
          borderRadius={3}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h5" fontWeight="bold">
            Monthly
          </Typography>

          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ fontSize: "60px", color: "#121957" }}>
                  $
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "120px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            required
            value={monthlyPrice}
            onChange={(e) => updateMonthlyPrice(e.target.value)}
          />

          <Typography variant="subtitle1" fontWeight="bold">
            Average: $2100 - $2700
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Step9;
