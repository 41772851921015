import React from "react";

function CondoIcon() {
  return (
    <>
      <img
        className="icon"
        alt="condo"
        width="70%"
        height="70%"
        src="https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/Condo.png"
      />
    </>
  );
}

export default CondoIcon;
