import React from "react";

function TownhouseIcon() {
  return (
    <>
      <img
        className="icon"
        alt="townhouse"
        width="70%"
        height="70%"
        src="https://atph-image-uploads.s3.us-east-2.amazonaws.com/Icons/TownHouse.png"
      />
    </>
  );
}

export default TownhouseIcon;
