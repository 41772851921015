import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactDTO, GetContactDTO, ReplyContactDTO } from '../schema/dto/contact.dto';
import errorHandler from '../utils/error-handler.utils';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import http from '../axios.config';
import { stringify } from 'querystring';

export const contactATP: any = createAsyncThunk('contactATP', async (data: ContactDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Sending Message'));

  try {
    const response = await http.post('/api/contact', data);
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getContactMessages: any = createAsyncThunk('getContactMessages', async (query: GetContactDTO, thunkApi) => {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);
  try {
    const response = await http.get(`/api/contact/admin?${queryString}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const replyContact: any = createAsyncThunk('replyContact', async (data: ReplyContactDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;
  dispatch(openPreloader('Sending Message'));

  try {
    const response = await http.post(`/api/contact/admin/${data?.id}/reply`, { response: data?.response });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
