import { Box } from "@mui/material";
import React from "react";
import Title from "./Title";
import Testimonials from "./Testimonials";

export default function TestimonialsContainer() {
  return (
    <Box component="section" marginY={6}>
      <Title text="See What People Are Saying" />
      <Testimonials />
    </Box>
  );
}
