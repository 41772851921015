import React, { useMemo } from "react";
import { IConversation } from "../../schema/interfaces/chat.interface";
import { Box, Typography } from "@mui/material";
import { shortenText } from "../../utils/text.utils";
import moment from "moment";
import { compareConversationWithCurrent, getConversationLatestMessage, getConversationOtherUser } from "../../utils/chat.utils";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentConversation } from "../../redux/chat.slice";
import { RootState } from "../../redux/store";

interface Props extends IConversation {}

export default function SingleConversation(props: Props) {
  const currentConversation = useSelector((state: RootState) => state.chat?.currentConversation);
  const user = useMemo(() => getConversationOtherUser(props.Users), []);
  const message = useMemo(() => getConversationLatestMessage(props.Messages), []);
  const isCurrentConvo = useMemo(() => compareConversationWithCurrent(props, currentConversation), [currentConversation]);
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(updateCurrentConversation(props));
  };

  return (
    <Box
      paddingX={2}
      paddingY={0.5}
      borderTop={"1.5px solid #c1bcbc"}
      sx={{ ":hover": { backgroundColor: "#eaeaea" }, cursor: "pointer", background: isCurrentConvo ? "#eaeaea" : "whihte" }}
      onClick={onClick}
    >
      {message && (
        <Typography fontWeight={"300"} fontSize={".65rem"} maxWidth={"fit-content"} marginLeft={"auto"} component={"p"} sx={{ cursor: "pointer" }}>
          {moment(message?.createdAt).fromNow()}
        </Typography>
      )}

      <Box display="flex" gap={1} flexWrap="nowrap">
        <Box maxWidth="fit-content">
          <img
            src={user?.profilePicture}
            style={{ width: "40px", height: "40px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
            alt=""
          />
        </Box>
        <Box flex={1}>
          <Typography variant="subtitle1" component="h4" fontSize=".8rem" color="#222" fontWeight="bold" lineHeight={1.3}>
            {user?.firstName} {user?.lastName}
          </Typography>
          {message && (
            <Typography variant="body1" component="p" fontSize=".65rem" color="#222" lineHeight={1.2}>
              {shortenText(message?.text, 50)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
