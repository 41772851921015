import React, { useEffect, useMemo } from "react";
import ContentBox from "./ContentBox";
import { House } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getAdminProperties } from "../../../services/property.services";

export default function PropertiesCount() {
  const loading = useSelector((state: RootState) => state?.properties?.handlers?.isLoading);
  const properties = useSelector((state: RootState) => state?.properties?.properties);
  const count = useMemo(() => properties?.length, [properties]);
  //  no need to fetch, the banner is doing the fetching for the properties

  return (
    <ContentBox
      iconBg={yellow[800]}
      icon={<House fontSize="small" />}
      title="Properties"
      count={count}
      loading={loading}
      link={"/admin/properties"}
    />
  );
}
