import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
import LogoutButton from "../UI/LogoutButton";
import localStorageService from "../../utils/localstorage.utils";
import { hasOwnerAccount } from "../../utils/user-groups.utils";
import GroupSwitch from "../UI/GroupSwitch";
import Sublink from "../UI/Navbar/Sublinks";
import { Link as ILink } from "../../schema/interfaces/link.interface";

const Sidebar = ({ links, sidebarOpened }: { links: ILink[]; sidebarOpened: boolean }) => {
  const screenQuery = useMediaQuery("(max-width:1000px)");

  const { pathname } = useLocation();
  const user = localStorageService.user.get();
  const hasOwnerGroup = hasOwnerAccount(user?.groups);
  const isAdminPage = pathname.includes("admin");

  return (
    <Box
      component="aside"
      width={screenQuery ? "100%" : "300px"}
      borderRight={screenQuery ? "0px" : "1.5px solid lightgray"}
      height={"100%"}
      overflow={"scroll"}
      position={"absolute"}
      top={0}
      left={0}
      paddingY={2}
      paddingX={0}
      zIndex={2}
      sx={{
        background: "white",
        transform: screenQuery ? (sidebarOpened ? "translateX(0)" : "translateX(-100%)") : "translateX(0)",
        transition: "all .4s",
      }}
    >
      {/* Display greetings on desktop view only */}
      {!screenQuery && (
        <>
          <Typography
            variant="h5"
            component="h3"
            sx={{
              maxWidth: "250px",
              marginX: "auto",
              textAlign: "center",
            }}
          >
            Hi {user?.firstName}!
            <br />
            Welcome in.
          </Typography>
          <Box component="hr" maxWidth={"250px"} marginX={"auto"} sx={{ background: "black", height: 2.5, marginTop: 1 }} />
        </>
      )}

      <Stack gap={2} direction={"column"} sx={{ marginTop: 1 }}>
        {/* Map links */}
        {links?.map((link, index) => {
          // if it is not a navbar link or (it is a navbar link and we're on a smaller device)
          return !link.isNavbarLink || (screenQuery && link.isNavbarLink && !link?.sublinks) ? (
            <Link to={link?.href as string} style={{ width: "100%" }} key={index}>
              <Box
                display="flex"
                gap={2}
                alignItems={"center"}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f3f3f3",
                  },
                  backgroundColor: link?.href === pathname ? "#f3f3f3" : "transparent",
                }}
                paddingY={1}
                paddingX={2}
                width={"100%"}
                maxWidth={screenQuery ? "100%" : "250px"}
                marginX={"auto"}
              >
                {/* Display icons on larger screens only */}
                {!screenQuery && link?.icon}
                <Typography variant="body1">{link?.text}</Typography>
              </Box>
            </Link>
          ) : screenQuery && link.sublinks ? (
            <Box paddingX={2} key={index}>
              <Sublink title={link.text} sublinks={link.sublinks} className="dashboard-navLink" />
            </Box>
          ) : (
            <Fragment key={index}></Fragment>
          );
        })}
        {/* Groups Swicth Button */}
        {hasOwnerGroup && !isAdminPage && <GroupSwitch />}
        {/* Add A Log out button */}
        <LogoutButton />
      </Stack>
    </Box>
  );
};

export default Sidebar;
