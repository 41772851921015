import { createAsyncThunk } from '@reduxjs/toolkit';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { GetAdminPaymentDTO, GetPaymentIntentResponse, GetPaymentsDTO } from '../schema/dto/payment.dto';
import { PaginatedResponse } from '../schema/dto/query.dto';
import { IPayment } from '../schema/interfaces/payment.interface';
import { stringify } from 'querystring';

export const getPaymentIntent: any = createAsyncThunk('getPaymentIntent', async function (reservationId: number, thunkApi) {
  try {
    const response = await http.get<GetPaymentIntentResponse>(`/api/payment/initiate/${reservationId}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getReservationPayment: any = createAsyncThunk('getReservationPayment', async function (reservationId: number, thunkApi) {
  try {
    const response = await http.get(`/api/payment/reservation/${reservationId}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getAdminPayments: any = createAsyncThunk('getAdminPayments', async function (query: GetAdminPaymentDTO, thunkApi) {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);

  try {
    const response = await http.get<PaginatedResponse<IPayment>>(`/api/payment/admin?${queryString}`);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getSinglePayment: any = createAsyncThunk('getSinglePayment', async function (id: number, thunkApi) {
  try {
    const response = await http.get<IPayment>(`/api/payment/admin/${id}`);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getUserPayments: any = createAsyncThunk('getUserPayments', async function (query: GetPaymentsDTO, thunkApi) {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);

  try {
    const response = await http.get<PaginatedResponse<IPayment>>(`/api/payment?${queryString}`);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getUserPropertiesPayments: any = createAsyncThunk('getUserPropertiesPayments', async function (query: GetPaymentsDTO, thunkApi) {
  query = query ? { ...query, limit: 10 } : { limit: 10 };
  let queryString = stringify(query as any);

  try {
    const response = await http.get<PaginatedResponse<IPayment>>(`/api/payment/property?${queryString}`);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});
