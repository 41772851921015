import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { MdClose } from "react-icons/md";
import { IGeoCodeResult, IMapCenter } from "../../schema/interfaces/map.interface";
import LocationSelector from "../UI/LocationSelector";
import { autoCompleteLocations } from "../../services/map.services";
import { useDispatch } from "react-redux";
import { getProperties } from "../../services/property.services";

interface Props {
  closeBox(): void;
  setMapCenter(center: IMapCenter): void;
  onSearch(): void;
}

function LocationSearch({ closeBox, setMapCenter, onSearch }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [locations, setLocations] = useState<IGeoCodeResult[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();

  const selectLocation = async (location: IGeoCodeResult) => {
    // fetch properties
    setSearch(location.formatted);
    await dispatch(getProperties({ longitude: location.lon, latitude: location.lat }));
    setMapCenter({ lat: parseFloat(location.lat.toString()), lng: parseFloat(location.lon.toString()) });
    setIsOpen(false);
    setLocations([]);
    onSearch();
  };

  const fetchLocations = async (search: string) => {
    setIsOpen(true);
    setIsFetching(true);
    const locations = await autoCompleteLocations(search);
    setLocations(locations.slice(0, 5));
    setIsFetching(false);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length >= 3) {
      await fetchLocations(value);
    }
  };

  return (
    <Box
      width={"80vw"}
      maxWidth={350}
      sx={{ background: "white", padding: 1.2, position: "absolute", top: "10px", left: "30px", zIndex: 2, borderRadius: 2 }}
    >
      <Box component="header" display="flex" alignItems="center" justifyContent="space-between" gap={3} marginBottom={2.5}>
        <p>Enter Location</p>
        <MdClose size={25} color="darkred" cursor="pointer" onClick={closeBox} />
      </Box>
      <Box width={"100%"} display="flex" gap={1.5}>
        <TextField
          label="Location"
          placeholder="Enter Location"
          variant="outlined"
          size="small"
          sx={{ flex: 1 }}
          value={search}
          required
          onChange={onChange}
        />
      </Box>
      {isOpen && <LocationSelector selectLocation={selectLocation} isLoading={isFetching} locations={locations} closeBox={() => setIsOpen(false)} />}
    </Box>
  );
}

export default LocationSearch;
