import { Box, SxProps, Theme, Typography } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  sx?: SxProps<Theme>;
  text?: string;
  children?: ReactElement | ReactElement[];
  containerSx?: SxProps<Theme>;
}

export default function Banner({ sx, text, children, containerSx }: Props) {
  return (
    <Box className="banner" sx={{ height: "80vh", maxHeight: "500px", paddingTop: 15, ...sx }}>
      <Box maxWidth={"1200px"} marginX={"auto"} paddingX={"1rem"}>
        <Box maxWidth={"800px"} sx={{ ...containerSx }}>
          {text && (
            <Typography color={"white"} textAlign={"left"} variant="h3" fontWeight={"bold"}>
              {text}
            </Typography>
          )}

          {children}
        </Box>
      </Box>
    </Box>
  );
}
