import React, { useEffect } from "react";
import { TableHeader, TableHeaderEntry, TableLayout, TableRowEntry, TableRowsContainer, TableRow } from "../UI/Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Box, Typography } from "@mui/material";
import { getDateAndMonth } from "../../utils/date.utils";
import { getWalletLogs } from "../../services/wallet.services";
import { WalletDirection } from "../../schema/enums/wallet.enum";

const headers = [
  { name: "Amount", width: 100 },
  { name: "Date", width: 150 },
  { name: "Status", width: 100 },
];

export default function Withdrawals() {
  const withdrawals = useSelector((state: RootState) => state?.wallet?.walletLogs);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletLogs());
  }, []);

  return (
    <TableLayout>
      <TableHeader>
        {headers.map(({ width, name }, index) => (
          <TableHeaderEntry width={width} key={index}>
            {name}
          </TableHeaderEntry>
        ))}
      </TableHeader>

      <TableRowsContainer>
        {withdrawals
          ?.filter((withdrawal) => withdrawal.direction === WalletDirection.DEBIT)
          ?.map((withdrawal, index) => {
            return (
              <TableRow key={index} InnerProps={{ style: { alignItems: "center" } }}>
                <TableRowEntry width={headers[0]?.width}>
                  <> ${withdrawal?.amount.toLocaleString()}</>
                </TableRowEntry>

                <TableRowEntry width={headers[1]?.width}>
                  <>{getDateAndMonth(withdrawal?.updatedAt as Date)}</>
                </TableRowEntry>
                <TableRowEntry InnerProps={{ style: { textTransform: "capitalize" } }} width={headers[2]?.width}>
                  {withdrawal?.status}
                </TableRowEntry>
              </TableRow>
            );
          })}
      </TableRowsContainer>
    </TableLayout>
  );
}
