import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Navbar from "./components/UI/Navbar/Navbar";
import ProfilePage from "./pages/ProfilePage";
import EditProfileForm from "./pages/EditProfile";
import Contact from "./pages/Contact";
import About from "./pages/About";
import ForgotPassword from "./pages/ForgotPassword";
import ResetForgottenPassword from "./pages/ResetForgottenPassword";
import ErrorModal from "./components/UI/ErrorModal";
import Preloader from "./components/UI/Preloader";
import CurrentStays from "./pages/Renter/CurrentStays";
import OwnerListings from "./pages/Owner/OwnerListings";
import AddProperty from "./pages/Owner/AddProperty";
import PropertiesList from "./pages/PropertiesList";
import PropertyDetails from "./pages/PropertyDetails";
import Error404 from "./pages/404";
import Map from "./pages/Map";
import PropertyReservations from "./pages/Owner/PropertyReservations";
import AdminDashboard from "./pages/Admin/Dashboard";
import Users from "./pages/Admin/Users";
import Properties from "./pages/Admin/Properties";
import AdminContact from "./pages/Admin/Contact";
import SavedProperties from "./pages/Renter/SavedProperties";
import UserAgreement from "./pages/Policies/UserAgreement";
import RefundPolicy from "./pages/Policies/RefundPolicy";
import Blog from "./pages/Blog";
import GetYourShot from "./pages/Blog/get-your-shot";
import PowerOfEatingHealthy from "./pages/Blog/the-power-of-eating-healthy";
import EmbracingFitness from "./pages/Blog/embracing-fitness";
import VisionaryJourneyOfSharpree from "./pages/Blog/visionary-journey-of-shapree-marshall";
import ATPHPledge from "./pages/Blog/atph-pledge";
import ReservationPayment from "./pages/ReservationPayment";
import ManageFinancials from "./pages/Owner/ManageFinancials";
import Chat from "./pages/Chat";
import Wallet from "./pages/Owner/Wallet";
import PaymentSuccess from "./pages/PaymentSuccess";
import Payments from "./pages/Admin/Payment";
import SinglePayment from "./pages/Admin/SinglePayment";
import BecomeOwner from "./pages/Renter/BecomeOwner";
import Withdraw from "./pages/Owner/Withdraw";
import SingleReservation from "./pages/Renter/SingleReservation";
import PropertyDocuments from "./pages/Admin/PropertyDocuments";
import SingleDocument from "./pages/Admin/SingleDocument";
import EditIdInfo from "./pages/EditIdInfo";

function App() {
  return (
    <>
      <ErrorModal />
      <Preloader />
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:token/:code" element={<ResetForgottenPassword />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/map" element={<Map />} />
        <Route path="/user-agreement" element={<UserAgreement />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/properties" element={<PropertiesList />} />
        <Route path="/properties/:id" element={<PropertyDetails />} />
        <Route path="/properties/:id/documents" element={<PropertyDocuments />} />
        <Route path="/properties/:propertyId/documents/:id" element={<SingleDocument />} />
        <Route path="/properties/reservation/:id/payment" element={<ReservationPayment />} />
        <Route path="/properties/reservation/:id/payment/success" element={<PaymentSuccess />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile/edit" element={<EditProfileForm />} />
        <Route path="/profile/edit/identification" element={<EditIdInfo />} />

        {/* Blog */}
        <Route path="/blogs">
          <Route index element={<Blog />} />
          <Route path="get-your-shot" element={<GetYourShot />} />
          <Route path="the-power-of-eating-healthy" element={<PowerOfEatingHealthy />} />
          <Route path="embracing-fitness" element={<EmbracingFitness />} />
          <Route path="visionary-journey-of-shapree-marshall" element={<VisionaryJourneyOfSharpree />} />
          <Route path="atph-pledge" element={<ATPHPledge />} />
        </Route>

        {/* Renter */}
        <Route path="/renter">
          <Route path="dashboard" element={<CurrentStays />} />
          <Route path="saved" element={<SavedProperties />} />
          <Route path="messages" element={<Chat />} />
          <Route path="become-owner" element={<BecomeOwner />} />
          <Route path="reservation/:id" element={<SingleReservation />} />
        </Route>

        {/* Owner */}
        <Route path="/owner">
          <Route path="dashboard" element={<CurrentStays />} />
          <Route path="listings" element={<OwnerListings />} />
          <Route path="property/new" element={<AddProperty />} />
          <Route path="saved" element={<SavedProperties />} />
          <Route path="property/:id/reservations" element={<PropertyReservations />} />
          <Route path="manage-financials" element={<ManageFinancials />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="wallet/withdrawal" element={<Withdraw />} />
          <Route path="messages" element={<Chat />} />
        </Route>

        {/* Admin */}
        <Route path="/admin">
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="properties" element={<Properties />} />
          <Route path="contact" element={<AdminContact />} />
          <Route path="messages" element={<Chat />} />
          <Route path="payments" element={<Payments />} />
          <Route path="payments/:id" element={<SinglePayment />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
