import { createAsyncThunk } from '@reduxjs/toolkit';
import { SendMessageDTO } from '../schema/dto/chat.dto';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { RootState } from '../redux/store';

export const sendMessage: any = createAsyncThunk('sendMessage', async ({ receiverId, text, propertyId }: SendMessageDTO, thunkApi) => {
  const body = propertyId ? { receiverId, text, propertyId } : { receiverId, text };

  try {
    const response = await http.post('/api/chat', body);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getConversations: any = createAsyncThunk('getConversations', async (_, thunkApi) => {
  try {
    const response = await http.get('/api/chat');

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getMessages: any = createAsyncThunk('getMessages', async (conversationId: number, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const id = state.chat?.currentConversation?.id;

  try {
    const response = await http.get(`/api/chat/${id}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
