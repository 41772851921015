import { Box, Button, Checkbox, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/UI/Navbar/Navbar";
import GoogleButton from "../components/UI/GoogleButton";
import Footer from "../components/UI/Footer";
import { useDispatch } from "react-redux";
import { register } from "../services/auth.services";
import Swal from "sweetalert2";
import { getProfile } from "../services/profile.services";
import Banner from "../components/UI/Banner";

const Register = () => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [modalOpened, setModalOpened] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    let data = await dispatch(register({ email, username, firstName, lastName, password }));

    if (!data.error) {
      await Swal.fire({
        title: "Registration",
        text: "Your account has been created successfully",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });

      window.location.href = "/";
    }
  };

  return (
    <>
      <Box component="section" sx={{}}>
        <Navbar />
        <Banner text="Register" />

        <Container maxWidth="lg" sx={{ paddingY: "1.4rem", flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Paper
            elevation={1}
            sx={{
              padding: "1rem",
              width: "90vw",
              maxWidth: "700px",
              marginX: "auto",
            }}
          >
            <Typography variant="h5" fontWeight={"bold"} color="primary" marginBottom={2}>
              Register
            </Typography>

            <Box component={"form"} onSubmit={submit}>
              <Grid container gap={3} sx={{ marginBottom: 1.5 }}>
                <Grid item xs={12} md={5.7}>
                  <Box>
                    <label htmlFor="firstName">Enter Firstname</label>
                    <TextField
                      variant="outlined"
                      label="Firstname"
                      id="firstName"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      color="primary"
                      sx={{ width: "100%", marginTop: "10px" }}
                      required
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={5.7}>
                  <Box>
                    <label htmlFor="lastName">Enter Lastname</label>
                    <TextField
                      variant="outlined"
                      label="Lastname"
                      id="lastName"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      color="primary"
                      sx={{ width: "100%", marginTop: "10px" }}
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.7}>
                  <Box>
                    <label htmlFor="email">Enter Email</label>
                    <TextField
                      variant="outlined"
                      label="Email"
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      color="primary"
                      sx={{ width: "100%", marginTop: "10px" }}
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.7}>
                  <Box>
                    <label htmlFor="username">Enter Username</label>
                    <TextField
                      variant="outlined"
                      label="Username"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      color="primary"
                      sx={{ width: "100%", marginTop: "10px" }}
                      required
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5.7}>
                  <Box>
                    <label htmlFor="password">Enter Password</label>
                    <TextField
                      variant="outlined"
                      label="Password"
                      id="password"
                      type="password"
                      color="primary"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{ width: "100%", marginTop: "10px" }}
                      required
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                <Checkbox color="secondary" required />
                <Typography variant="body1" fontSize=".8rem" fontWeight="bold" color={"#333741"}>
                  I agree to ATPH's{" "}
                  <Link to="/user-agreement" style={{ textDecoration: "underline", color: "#53cbf0", fontWeight: "bold" }}>
                    User Agreement
                  </Link>
                  , and{" "}
                  <Link to="/refund-policy" style={{ textDecoration: "underline", color: "#53cbf0", fontWeight: "bold" }}>
                    Refund Policy
                  </Link>
                </Typography>
              </Box>

              <Button type="submit" fullWidth color="primary" variant="contained" sx={{ paddingY: 1.2, marginBottom: 1 }}>
                Register
              </Button>
              <GoogleButton />

              <Typography marginTop={1} display={"block"} marginLeft={"auto"}>
                Already have an account?{" "}
                <Link to={"/login"} color="darkblue" style={{ textDecoration: "underline" }}>
                  Sign in
                </Link>
              </Typography>
            </Box>
          </Paper>
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Register;
