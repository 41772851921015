import React, { useEffect } from "react";
import secrets from "../constants/secrets.const";

interface Props {
  onSuccess: (access_token: string) => Promise<void>;
  redirectRoute: string;
}

export default function useGoogleAuth({ onSuccess, redirectRoute }: Props) {
  const handleGoogleLogin = () => {
    const redirectUri = encodeURIComponent(`${secrets.environmentUri}${redirectRoute}`);
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=token&client_id=${secrets.googleClientId}&redirect_uri=${redirectUri}&scope=openid%20email%20profile&state=google`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = urlParams.get("access_token");

    if (accessToken) onSuccess(accessToken);
  }, []);

  return { handleGoogleLogin };
}
