import { Box, Button, Modal, Typography } from "@mui/material";
import { FaExclamationTriangle } from "react-icons/fa";
import React from "react";
import { red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { closeErrorModal } from "../../redux/handlers.slice";
import { BsArrowRight } from "react-icons/bs";

const ErrorModal = () => {
  const { errorModal } = useSelector((state: RootState) => state.handlers);

  const dispatch = useDispatch();

  const closeModal = () => dispatch(closeErrorModal({}));

  return (
    <Modal aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" open={errorModal.isOpen} onClose={closeModal}>
      <Box
        sx={{
          width: "95vw",
          maxWidth: "400px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: 3,
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaExclamationTriangle color={"#f04438"} size={55} />
        </span>

        {(errorModal?.text || [])?.map((text, index) => {
          return (
            <Typography textAlign="left" variant="subtitle1" marginY={1.2} lineHeight={1.25} display="block" marginBottom={0.6} key={index}>
              <span
                style={{
                  verticalAlign: "middle",
                  display: "inline-block",
                  marginRight: 5,
                }}
              >
                <BsArrowRight />
              </span>
              {text}
            </Typography>
          );
        })}

        <Button
          fullWidth
          sx={{
            background: "#f04438",
            color: "white",
            borderRadius: 2,
            marginTop: 3,
            ":hover": {
              background: "#d92d20",
              color: "white",
            },
            ":active": {
              transform: "scale(.98)",
            },
          }}
          onClick={closeModal}
        >
          Okay
        </Button>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
