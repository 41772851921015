import { Box } from "@mui/material";
import React from "react";

export default function BetaButton() {
  return (
    <Box
      sx={{
        borderRadius: "30px",
        paddingX: 1.2,
        paddingY: 0.5,
        background: "#eee",
        fontSize: ".7rem",
        border: "1.5px solid #333741",
        cursor: "pointer",
        boxShadow: "0px 2px 2px rgba(0,0,0,.3)",
      }}
      title="This a beta"
    >
      Beta
    </Box>
  );
}
