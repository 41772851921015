import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Container, Typography } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import BlogSubtitle from "../../components/Blog/Subtitle";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

export default function EmbracingFitness() {
  return (
    <>
      <Navbar />
      <BlogTheme>
        <Container maxWidth="md" sx={{ paddingY: 5 }} component={"article"}>
          <BlogBanner
            src="embracing-fitness.png"
            imageStyle={{ objectPosition: "top" }}
            title="Embracing Fitness: A Journey to Wellness with A Traveled Path Homes"
            date="December 12, 2023"
            tag="Fitness"
          />

          <Typography component={"p"} variant="subtitle1">
            Dear Valued Community,
          </Typography>
          <Typography component={"p"} variant="body1">
            In a world filled with processed foods and fast-paced living, the significance of maintaining a healthy diet often takes a backseat.
            However, the food choices we make significantly impact our overall well-being. Embracing a diet rich in nutritious, whole foods is a
            fundamental step towards a healthier and happier life.
          </Typography>

          <BlogSubtitle header="Why Fitness Matters">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Physical Health"
              answer="Engaging in regular exercise not only boosts physical fitness but also contributes to a stronger immune system, improved cardiovascular health, and enhanced overall well-being."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Mental Well-being"
              answer="Fitness activities have proven benefits for mental health, reducing stress, anxiety, and depression while enhancing mood and cognitive function."
            />

            <PrivacyPolicySubtopicsList
              count={3}
              question="Community Bonding"
              answer="Through fitness initiatives, we create opportunities for our residents and team members to connect, inspire each other, and build a supportive community."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Our Fitness Commitment">
            <Typography component={"p"} variant="body1">
              At A Traveled Path Homes, we've integrated various fitness amenities and initiatives into our communities to support and encourage a
              healthy lifestyle:
            </Typography>

            <PrivacyPolicySubtopicsList
              count={1}
              question="State-of-the-Art Facilities"
              answer="Our communities boast top-notch gyms, yoga studios, and outdoor spaces designed to cater to various fitness preferences and levels."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Group Fitness Activities"
              answer="We organize regular group fitness classes, including yoga sessions, cardio workouts, and group runs, fostering camaraderie and motivation among participants."
            />

            <PrivacyPolicySubtopicsList
              count={3}
              question="Wellness Programs"
              answer="From nutrition workshops to mindfulness sessions, we offer a range of programs aimed at educating and empowering our community to make informed choices about their health."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Conclusion">
            <Typography component={"p"} variant="body1" marginTop={1}>
              At A Traveled Path Homes, our commitment to fitness extends beyond providing spaces and amenities; it's about fostering a culture where
              wellness thrives. We believe that by embracing fitness as a lifestyle, we empower our community to lead healthier, more fulfilling
              lives.
            </Typography>

            <Typography component={"p"} variant="body1" marginTop={1}>
              Join us on this empowering journey toward holistic well-being. Together, let's celebrate the joy of movement, the power of community,
              and the transformative impact of fitness on our lives.
            </Typography>
          </BlogSubtitle>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
