import { Search } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

interface Props {
  openBox(): void;
}

const SearchIcon = ({ openBox }: Props) => {
  return (
    <Button
      variant="contained"
      endIcon={<Search />}
      sx={{
        width: "100%",
        maxWidth: "200px",
        padding: 1.2,
        position: "absolute",
        top: "10px",
        left: "30px",
        zIndex: 2,
        borderRadius: 2,
      }}
      onClick={openBox}
    >
      Search Location
    </Button>
  );
};

export default SearchIcon;
