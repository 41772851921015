import { Box, Typography } from "@mui/material";
import React from "react";
import { BiLoader, BiLoaderAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Preloader = () => {
  const { preloader } = useSelector((state: RootState) => state.handlers);

  if (!preloader.isOpen) return <></>;

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10,
      }}
    >
      <span>
        <BiLoaderAlt style={{ fontSize: 42, color: "white", display: "block", width: "40px", height: "40px" }} className="animate-spin" />
      </span>

      <Typography variant="h5" color="white">
        {preloader.text}...
      </Typography>
    </Box>
  );
};

export default Preloader;
