import { Box, Button, Paper, Typography, useMediaQuery } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CustomLoader from "../UI/CustomLoader";
import { getUserWallet } from "../../services/wallet.services";
import { Link } from "react-router-dom";

export default function WalletBanner() {
  const screenQuery = useMediaQuery("(max-width: 600px)");
  const wallet = useSelector((state: RootState) => state?.wallet?.singleWallet);
  const loading = useSelector((state: RootState) => state?.wallet?.handlers?.wallet?.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserWallet());
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        background: grey[100],
        padding: "1rem 2rem",
        borderRadius: 2,
        display: "flex",
        rowGap: 4,
        columnGap: 2,
        flexWrap: "wrap",
        flexDirection: screenQuery ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CustomLoader size={25} text="Fetching wallet information" loading={loading} />
      ) : (
        <>
          <Box>
            <Typography color="#53cbf0" fontWeight="bold" variant="h5">
              ${wallet?.balance?.toLocaleString()}
            </Typography>
            <Typography color="#53cbf0" variant="subtitle1" fontSize=".9rem">
              Current Balance
            </Typography>
          </Box>

          <Link to="/owner/wallet/withdrawal">
            <Button variant="contained" size="small">
              Withdraw Money
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
}
