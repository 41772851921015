import { Box, Container, ThemeProvider, Typography, createTheme } from "@mui/material";
import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import PrivacyPolicySubtopic from "../../components/PrivacyPolicy/PrivacyPolicySubtopic";

const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "subtitle1",
          },
          style: {
            fontSize: ".95rem",
            fontFamily: "inherit",
            fontWeight: "bold",
            lineHeight: 1.35,
          },
        },

        {
          props: {
            variant: "body1",
          },
          style: {
            fontSize: ".85rem",
            fontFamily: "inherit",
            lineHeight: 1.35,
          },
        },
      ],
    },
  },
});

export default function UserAgreement() {
  return (
    <>
      <Navbar />
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" sx={{ paddingY: "1.4rem" }}>
          <Typography variant="h5" marginY={2} color="#333741" fontWeight="bold">
            User Agreement
          </Typography>
          <Typography variant="body1" color="#333741">
            Welcome to Traveled Path Homes! This User Agreement governs your access to and use of our services. By using our platform, you agree to
            comply with these terms.
          </Typography>

          {/* Subtopics */}
          <PrivacyPolicySubtopic
            title="Acceptance of Terms"
            body={[
              "You must be at least 18 years old or have the legal capacity to enter into agreements to use our services.",
              "By using Traveled Path Homes, you agree to abide by these terms and any additional guidelines we provide.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Services and Account Registration"
            body={[
              "Traveled Path Homes offers a platform connecting travelers with unique accommodations. Registration is required to access certain features.",
              "You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities that occur under your account.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="User Responsibilities"
            body={[
              "You agree to provide accurate, updated, and complete information.",
              "You will not use our platform for any illegal or unauthorized purpose.",
              "You are responsible for your interactions with other users. Traveled Path Homes is not liable for any conduct or interactions between users.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Content and Usage"
            body={[
              "Users are solely responsible for the content they post on Traveled Path Homes.",
              "You grant Traveled Path Homes a worldwide, non-exclusive, royalty-free license to use, copy, display, and distribute the content you post.",
              "You agree not to reproduce, modify, or distribute any content from our platform without permission.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Payment and Fees"
            body={[
              "Fees for accommodations and services are as advertised on the platform.",
              "Users agree to pay all applicable fees and taxes.",
              "Traveled Path Homes is not responsible for any additional bank fees, currency exchange rates, or other charges.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Termination"
            body={[
              "Traveled Path Homes reserves the right to terminate or suspend your account if you violate these terms or engage in any prohibited activities",
              "You may terminate your account at any time by contacting our support team.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Disclaimers and Limitation of Liability"
            body={[
              "Traveled Path Homes does not guarantee the accuracy, reliability, or availability of the services.",
              "We are not liable for any indirect, incidental, or consequential damages arising from your use of our platform.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Updates to Terms"
            body={[
              "Traveled Path Homes may update these terms at any time. We will notify users of any material changes.",
              "Your continued use of our services after changes indicates your acceptance of the updated terms.",
            ]}
          />

          <PrivacyPolicySubtopic
            title="Governing Law"
            body={[
              "This Agreement is governed by and construed in accordance with the laws of Kansas.",
              "Any disputes arising from this Agreement shall be settled in the courts of Kansas.",
            ]}
          />

          <Typography variant="body1" color={"#333741"} marginTop={2} marginBottom={1}>
            By using Traveled Path Homes, you agree to abide by these terms and conditions. If you have any questions or concerns, please contact us
            at{" "}
            <a href="mailto:info@atphousing.com" style={{ fontWeight: "bold", textDecoration: "underline", color: "#53cbf0" }}>
              info@atphousing.com .
            </a>
          </Typography>
          <Typography variant="body1" color="#333741">
            Thank you for being part of the Traveled Path Homes community!
          </Typography>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  );
}
