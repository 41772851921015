import { Box, Switch, Typography, useMediaQuery } from "@mui/material";
import React, { useMemo, useState } from "react";
import localStorageService from "../../utils/localstorage.utils";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { blue } from "@mui/material/colors";
import { UserGroups } from "../../schema/enums/groups.enum";
import Swal, { SweetAlertResult } from "sweetalert2";
import { SwitchAccount } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

export default function GroupSwitch() {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  const location = useLocation();
  const currentGroup = useMemo(() => localStorageService.currentGroup.get(), [location.pathname]);

  const onClick = () => {
    const otherGroup = currentGroup === UserGroups.OWNER ? UserGroups.RENTER : UserGroups.OWNER;
    Swal.fire({
      title: "Account Switch",
      text: `Are you sure you want to switch to your ${otherGroup} dashboard?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      cancelButtonText: "No",
      confirmButtonColor: "#53cbf0",
      confirmButtonText: "Yes",
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        if (otherGroup === UserGroups.OWNER) {
          localStorageService.currentGroup.store(UserGroups.OWNER);
          window.location.href = "/owner/listings";
        } else {
          localStorageService.currentGroup.store(UserGroups.RENTER);
          window.location.href = "/renter/dashboard";
        }
      }
    });
  };

  return (
    <Box
      display="flex"
      gap={2}
      alignItems={"center"}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f3f3f3",
        },
      }}
      paddingY={1}
      paddingX={2}
      width={"100%"}
      maxWidth={screenQuery ? "100%" : "250px"}
      marginX={"auto"}
      onClick={onClick}
    >
      <span>
        <SwitchAccount />
      </span>
      <Typography variant="body1" textTransform={"capitalize"}>
        Switch to {currentGroup === UserGroups.OWNER ? UserGroups.RENTER : UserGroups.OWNER}
      </Typography>
    </Box>
  );
}
