import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

interface Props {
  value: string;
  title: string;
}

export default function FinancialsSummary({ value, title }: Props) {
  const smallScreen = useMediaQuery("(max-width: 600px)");
  const mediumScreen = useMediaQuery("(max-width: 1000px)");

  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.6} textAlign={"center"}>
      <Typography variant={smallScreen ? "body1" : mediumScreen ? "h6" : "h5"} fontWeight={"bold"}>
        {value}
      </Typography>
      <Typography variant={smallScreen ? "subtitle2" : mediumScreen ? "body2" : "h6"} fontWeight={"bold"} color="primary">
        {title}
      </Typography>
    </Box>
  );
}
