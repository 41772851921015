import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { IProperty } from "../../schema/interfaces/property.interface";
import { InfoWindow, InfoWindowF, MarkerF } from "@react-google-maps/api";

interface Props extends IProperty {}

function MapProperty({ Documents, name, id, description, monthlyPrice, dailyPrice, address, longitude, latitude }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hoverState, setHoverState] = useState();

  const property_images = Documents.filter((doc) => doc.type.includes("image"));

  const position = useMemo(() => ({ lng: parseFloat(longitude.toString()), lat: parseFloat(latitude.toString()) }), []);

  return (
    <MarkerF position={position} onClick={() => setIsOpen(!isOpen)}>
      {isOpen && (
        <InfoWindowF position={position} onCloseClick={() => setIsOpen(false)}>
          <Box
            sx={{
              width: "60vw",
              maxWidth: "300px",
              background: "white",
              borderRadius: 2,
              zIndex: 5,
              cursor: "auto",
              boxShadow: "0px 2px 3px rgba(0,0,0,.5)",
            }}
          >
            <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
              <img
                src={property_images?.[0].url}
                alt={name}
                style={{ width: "100px", height: "100px", borderRadius: 4, objectFit: "cover", objectPosition: "center" }}
              />

              <Box>
                <h2>{name}</h2>
                <p style={{ marginTop: 2, fontWeight: "bold" }}>
                  ${monthlyPrice}/month, ${dailyPrice}/day
                </p>

                <p style={{ marginTop: 2 }}>
                  <b style={{ textDecoration: "underline" }}>Description:</b>{" "}
                  {description?.length > 100 ? `${description?.slice(0, 100)}...` : description}
                </p>
              </Box>
            </Box>

            <p style={{ margin: "15px 0" }}>
              <b>Location:</b> {address}
            </p>

            <Link to={`/properties/${id}`} style={{ display: "block" }}>
              <Button fullWidth variant="contained" size="small">
                View Property
              </Button>
            </Link>
          </Box>
        </InfoWindowF>
      )}
    </MarkerF>
  );
}

export default MapProperty;
