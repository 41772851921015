import React from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import Footer from "../../components/UI/Footer";
import BlogTheme from "../../components/Blog/Theme";
import { Container, Typography } from "@mui/material";
import BlogBanner from "../../components/Blog/BlogBanner";
import BlogSubtitle from "../../components/Blog/Subtitle";
import PrivacyPolicySubtopicsList from "../../components/PrivacyPolicy/SubtopicsList";

export default function ATPHPledge() {
  return (
    <>
      <Navbar />
      <BlogTheme>
        <Container maxWidth="md" sx={{ paddingY: 5 }} component={"article"}>
          <BlogBanner
            src="atph-pledge.png"
            imageStyle={{ objectPosition: "center" }}
            title="A Traveled Path Homes' Pledge: Promoting Health Equity for All"
            date="December 12, 2023"
            tag="ATPH"
          />

          <Typography component={"p"} variant="subtitle1">
            Community Members,
          </Typography>
          <Typography component={"p"} variant="body1" marginTop={1}>
            At A Traveled Path Homes, our commitment to fostering thriving communities goes beyond providing comfortable accommodations; it extends to
            ensuring health equity for all individuals. We firmly believe that everyone deserves access to quality healthcare, regardless of their
            background, socioeconomic status, or circumstances.
          </Typography>

          <BlogSubtitle header="Why Health Equity Matters">
            <PrivacyPolicySubtopicsList
              count={1}
              question="Equal Access to Healthcare"
              answer="Health equity means that every individual has the opportunity to attain their highest level of health. It's about breaking down barriers and ensuring that everyone, irrespective of their circumstances, has access to essential healthcare services."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Addressing Disparities"
              answer="Health disparities exist within communities due to various factors like income, race, geographic location, or access to resources. Achieving health equity involves identifying and addressing these disparities to create fair and just opportunities for better health outcomes."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Our Commitment to Health Equity">
            <Typography component={"p"} variant="body1" marginTop={1}>
              At A Traveled Path Homes, we are dedicated to promoting health equity through various initiatives and practices:
            </Typography>

            <PrivacyPolicySubtopicsList
              count={1}
              question="Tailored Accommodations"
              answer="During emergencies, we provide tailored accommodations not only for healthcare professionals but also for community members in need, ensuring equitable access to safe and secure housing."
            />

            <PrivacyPolicySubtopicsList
              count={2}
              question="Community Engagement:"
              answer="We actively engage with diverse communities to understand their needs and work collaboratively to provide resources, support, and access to healthcare services."
            />

            <PrivacyPolicySubtopicsList
              count={3}
              question="Partnerships and Advocacy"
              answer="We collaborate with local healthcare providers, organizations, and advocacy groups to advocate for policies that promote health equity and address healthcare disparities within our communities.."
            />
          </BlogSubtitle>

          <BlogSubtitle header="Conclusion">
            <Typography component={"p"} variant="body1" marginTop={1}>
              At A Traveled Path Homes, promoting health equity isn't just a goal; it's a fundamental value embedded in our mission. We believe that
              everyone deserves the opportunity to live a healthy and fulfilling life. By advocating for equitable access to healthcare and supporting
              initiatives that bridge gaps in healthcare disparities, we strive to create a more equitable and healthier future for all.
            </Typography>

            <Typography component={"p"} variant="body1" marginTop={1}>
              Join us in our commitment to health equity. Together, let's build communities where everyone has the opportunity to thrive and access
              the healthcare they deserve.
            </Typography>
          </BlogSubtitle>
        </Container>
      </BlogTheme>
      <Footer />
    </>
  );
}
