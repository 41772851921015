import { Box, Typography } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { IGeoCodeResult } from "../../schema/interfaces/map.interface";
import CustomLoader from "./CustomLoader";

interface Props {
  selectLocation(location: IGeoCodeResult): void;
  isLoading: boolean;
  locations: IGeoCodeResult[];
  closeBox(): void;
}

export default function LocationSelector({ selectLocation, isLoading, locations, closeBox }: Props) {
  return (
    <Box
      position={"absolute"}
      top={"110%"}
      left={0}
      width="100%"
      paddingY={1.4}
      borderRadius={1.5}
      boxShadow={"0px 2px 3px rgba(0,0,0,.2)"}
      zIndex={2}
      sx={{ background: "white" }}
    >
      <>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1.2} padding={1}>
          <p style={{ fontWeight: "bold" }}>Select Location</p>
          <Box sx={{ color: "darkred" }} component="span" onClick={closeBox}>
            <MdClose size={23} cursor={"pointer"} />
          </Box>
        </Box>

        <CustomLoader size={15} sx={{ marginTop: 1, gap: 2, justifyContent: "center" }} text="Fetching Locations" loading={isLoading} />

        {locations.length > 0 && (
          <>
            {locations?.map((location, index) => {
              return (
                <Typography
                  variant="subtitle1"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  paddingX={1}
                  paddingY={0.8}
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: blue[50],
                    },
                  }}
                  key={index}
                  onClick={() => {
                    selectLocation(location);
                  }}
                >
                  {location.formatted}
                </Typography>
              );
            })}
          </>
        )}
      </>
    </Box>
  );
}
