import { Close } from "@mui/icons-material";
import { Box, Card, CardContent, CardMedia, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { BiTrash } from "react-icons/bi";

interface Props {
  deletePhoto(index: number): void;
  closeModal(): void;
  photos: Array<string | File>;
}

const SelectedPhotosModal = ({ deletePhoto, closeModal, photos }: Props) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledBy="modal-modal-title"
      aria-desribedBy="modal-modal-description"
    >
      <Box
        sx={{
          width: "90vw",
          maxWidth: "700px",
          marginX: "auto",
          backgroundColor: "white",
          borderRadius: 3,
          padding: 2,
          transform: "translate(-50%, -50%)",
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <Box component="header" display="flex" justifyContent={"space-between"} gap={4} alignItems="center">
          <Typography variant="h5">Selected Photos</Typography>
          <Close cursor="pointer" onClick={closeModal} />
        </Box>

        <Grid container gap={2} marginTop={4} sx={{ maxHeight: "70vh", overflowY: "scroll" }} justifyContent="center">
          {photos?.map((photo, index) => {
            return (
              <Grid key={index} item xs={12} sm={5}>
                <Card sx={{ padding: 0 }}>
                  <CardMedia
                    image={typeof photo === "string" ? photo : URL.createObjectURL(photo)}
                    sx={{ height: 120 }}
                  />
                  {/* For Deleting */}
                  <Box
                    padding={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      backgroundColor: "rgba(0,0,0,.5)",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => deletePhoto(index)}
                  >
                    <BiTrash style={{ fontSize: 20 }} />
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Modal>
  );
};

export default SelectedPhotosModal;
