import { createAsyncThunk } from '@reduxjs/toolkit';
import errorHandler from '../utils/error-handler.utils';
import http from '../axios.config';
import { CreateReservationDTO, UpdateReservationStatusDTO } from '../schema/dto/reservation.dto';
import { closePreloader, openPreloader } from '../redux/handlers.slice';
import { format } from 'date-fns';
import { IReservation } from '../schema/interfaces/reservation.interface';

export const getUserReservations: any = createAsyncThunk('getUserReservations', async (_, thunkApi) => {
  try {
    const response = await http.get('/api/reservation/renter');
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error, false);
  }
});

export const getPropertyReservations: any = createAsyncThunk('getPropertyReservations', async (id: number, thunkApi) => {
  try {
    const response = await http.get(`/api/reservation/property/${id}`);

    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const getSingleReservation: any = createAsyncThunk('getSingleReservation', async (id: number, thunkApi) => {
  try {
    const response = await http.get(`/api/reservation/${id}`);
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const bookReservation: any = createAsyncThunk('bookReservation', async (data: CreateReservationDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Booking Reservation'));

  data.startDate = format(<Date>data.startDate, 'yyyy-MM-dd');
  data.endDate = format(<Date>data.endDate, 'yyyy-MM-dd');
  try {
    const response = await http.post<IReservation>('/api/reservation', data);
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});

export const updateReservationStatus: any = createAsyncThunk('updateReservationStatus', async (data: UpdateReservationStatusDTO, thunkApi) => {
  const dispatch = thunkApi.dispatch;

  dispatch(openPreloader('Update Reservation Status'));

  try {
    const response = await http.put(`/api/reservation/${data.id}/status`, { status: data?.status });
    dispatch(closePreloader({}));
    return response?.data;
  } catch (error) {
    return errorHandler(thunkApi, error);
  }
});
