import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { ReactElement } from "react";
import { IAmenties } from "../../schema/interfaces/amenities.interface";

interface Props {
  toggle?: () => void;
  isSelected?: boolean;
  amenity: {
    icon?: string;
    text?: string;
  };
}

const Amenity = ({ toggle, isSelected, amenity }: Props) => {
  return (
    <Box
      padding={1.5}
      sx={{
        "&:hover": {
          background: isSelected ? "#3337413332f" : blue[50],
        },
        cursor: "pointer",
        border: "2px solid transparent",
        ...(isSelected
          ? {
              backgroundColor: "#3337413332f",
              borderColor: blue[900],
            }
          : {}),
      }}
      borderRadius={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems="center"
      gap={1}
      onClick={() => toggle && toggle()}
    >
      <img src={amenity.icon} style={{ width: "35px", height: "35px", objectFit: "cover", objectPosition: "center" }} />
      {amenity.text && (
        <Typography variant="subtitle1" component={"p"} textTransform={"uppercase"} color={blue[900]} fontSize={14}>
          {amenity.text}
        </Typography>
      )}
    </Box>
  );
};

export default Amenity;
