import React, { ChangeEvent, useState } from "react";
import Navbar from "../components/UI/Navbar/Navbar";
import { Box, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../services/auth.services";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = await dispatch(forgotPassword(email));

    if (!data?.error) {
      await Swal.fire({
        title: "Forgot Password",
        text: "A password reset will be sent to your inbox or spam folder if the email address exists in our records",
        showConfirmButton: false,
        icon: "success",
      });
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ paddingY: "1.4rem" }}>
        <Paper
          elevation={1}
          sx={{
            padding: "1rem",
            width: "90vw",
            maxWidth: "500px",
            marginX: "auto",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"} color="primary" marginBottom={2}>
            Reset Password
          </Typography>

          <Stack spacing={3} component={"form"} onSubmit={submit}>
            <Box>
              <label htmlFor="email">Enter Email</label>
              <TextField
                variant="outlined"
                label="Email"
                id="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                color="primary"
                sx={{ width: "100%", marginTop: "10px" }}
              />
            </Box>

            <Button fullWidth color="primary" type="submit" variant="contained" sx={{ paddingY: 1.2 }}>
              Submit
            </Button>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPassword;