import React, { ReactElement } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import secrets from "../../constants/secrets.const";

interface Props {
  children: ReactElement | ReactElement[];
}

function GoogleProvider({ children }: Props) {
  return <GoogleOAuthProvider clientId={secrets.googleClientId as string}>{children}</GoogleOAuthProvider>;
}

export default GoogleProvider;
