import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { FaStethoscope, FaMapLocation } from "react-icons/fa6";
import { GrMapLocation } from "react-icons/gr";
import { TbHandClick } from "react-icons/tb";
import React, { useEffect } from "react";
import Title from "./Title";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CustomLoader from "../UI/CustomLoader";
import ResidencesItem from "../UI/ResidencesItem";
import { getProperties } from "../../services/property.services";

export default function AvailableListings() {
  const screenQuery = useMediaQuery("(max-width:1000px)");
  const properties = useSelector((state: RootState) => state?.properties?.properties);
  const loading = useSelector((state: RootState) => state?.properties?.handlers?.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProperties());
  }, []);

  return (
    <Box component="section" marginY={6}>
      <Title text="Available Listings" />
      <CustomLoader loading={loading} size={30} />
      {!loading && (
        <Grid gap={2} flex={1} container marginTop={4} justifyContent={"center"}>
          {properties?.slice(0, 3)?.map((property, index) => {
            return <ResidencesItem key={index} {...property} />;
          })}
        </Grid>
      )}
    </Box>
  );
}
