import { Box, Button, IconButton, TextField } from "@mui/material";
import React, { FormEvent, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessages, sendMessage } from "../../services/chat.services";
import { RootState } from "../../redux/store";
import { getConversationOtherUser } from "../../utils/chat.utils";
import { BiDollar } from "react-icons/bi";
import CustomLoader from "../UI/CustomLoader";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { IMessage } from "../../schema/interfaces/chat.interface";
import { updateMessages } from "../../redux/chat.slice";
import { Socket } from "socket.io-client";
import localStorageService from "../../utils/localstorage.utils";

interface Props {
  socket?: Socket;
}

export default function MessageBox({ socket }: Props) {
  const dispatch = useDispatch();
  const [text, setText] = useState<string>("");
  const currentConversation = useSelector((state: RootState) => state?.chat?.currentConversation);
  const loading = useSelector((state: RootState) => state?.chat?.handlers?.sendMessage?.isLoading);
  const user = useMemo(() => getConversationOtherUser(currentConversation?.Users), [currentConversation]);
  const loggedInUser = localStorageService.user.get();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!text || !text.trim()) return;
    socket && socket.emit("message", { receiverId: user?.id, text });
    dispatch(updateMessages({ text, userId: loggedInUser?.id, conversationId: currentConversation?.id }));
    setText("");
  };

  const appendCurrency = () => setText(text + "$");

  return (
    <Box component="form" paddingTop={1} onSubmit={onSubmit}>
      <TextField
        multiline={true}
        rows={3}
        variant="outlined"
        fullWidth
        size="small"
        placeholder="Enter Message"
        InputProps={{ sx: { fontSize: ".8rem", ":placeholder": { fontSize: ".8rem" } } }}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <Box display="flex" justifyContent={"space-between"} alignItems="center" gap={2} marginTop={1}>
        <IconButton onClick={appendCurrency}>
          <BiDollar size={20} color="#333741" />
        </IconButton>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          size="small"
          sx={{ maxWidth: "fit-content", display: "block", marginLeft: "auto" }}
        >
          {loading ? <CustomLoader loading={loading} text="Sending" size={16} sx={{ justifyContent: "center" }} /> : "Send"}
        </Button>
      </Box>
    </Box>
  );
}
