import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { updatePropertyForm } from "../../redux/property-form.slice";

const Step4 = () => {
  const dispatch = useDispatch();
  const { guest, bedroom, beds, bathroom } = useSelector((state: RootState) => state?.propertyForm);

  const updateGuest = (guest: string) => {
    dispatch(updatePropertyForm({ key: "guest", value: parseInt(guest) }));
  };

  const updateBedroom = (bedroom: string) => {
    dispatch(updatePropertyForm({ key: "bedroom", value: parseInt(bedroom) }));
  };

  const updateBeds = (beds: string) => {
    dispatch(updatePropertyForm({ key: "beds", value: parseInt(beds) }));
  };

  const updateBathroom = (bathroom: string) => {
    dispatch(updatePropertyForm({ key: "bathroom", value: parseInt(bathroom) }));
  };

  return (
    <Box component={"section"} minHeight={350} display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop={6}>
      <Box
        display="flex"
        flexDirection={"column"}
        gap={1.4}
        justifyContent="center"
        alignItems={"center"}
        maxWidth={450}
        marginX={"auto"}
        textAlign="center"
      >
        <Typography variant="h3" fontWeight="bold" color="primary" component={"h2"}>
          Basics
        </Typography>

        <Typography variant="subtitle1" component={"p"}>
          Fill in the basic information for your property.
        </Typography>
      </Box>

      <Stack
        direction="column"
        gap={2}
        sx={{
          width: "100%",
          maxWidth: "500px",
          marginX: "auto",
          marginTop: 2,
        }}
      >
        <Grid container gap={1} justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" sx={{ flex: "1", display: "block" }} component="label" fontWeight={"bold"}>
            Guests
          </Typography>
          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "100px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            value={guest}
            onChange={(e) => updateGuest(e.target.value)}
            required
          />
        </Grid>

        <Grid container gap={1} justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" sx={{ flex: "1", display: "block" }} component="label" fontWeight={"bold"}>
            Beds
          </Typography>
          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "100px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            value={beds}
            onChange={(e) => updateBeds(e.target.value)}
            required
          />
        </Grid>

        <Grid container gap={1} justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" sx={{ flex: "1", display: "block" }} component="label" fontWeight={"bold"}>
            Bedrooms
          </Typography>
          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "100px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            value={bedroom}
            onChange={(e) => updateBedroom(e.target.value)}
            required
          />
        </Grid>

        <Grid container gap={1} justifyContent={"space-between"} alignItems="center">
          <Typography variant="h5" sx={{ flex: "1", display: "block" }} component="label" fontWeight={"bold"}>
            Bathrooms
          </Typography>
          <TextField
            type="number"
            sx={{ border: "none", "& fieldset": { border: "none" } }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                style: {
                  padding: 6,
                },
              },
              sx: {
                textAlign: "right",
                width: "100px",
                border: "2px solid #333741",
                borderRadius: 2,
                fontSize: "20px",
              },
            }}
            value={bathroom}
            onChange={(e) => updateBathroom(e.target.value)}
            required
          />
        </Grid>
      </Stack>
    </Box>
  );
};

export default Step4;
