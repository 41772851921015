import { Box } from "@mui/material";
import React from "react";

interface Props {
  tag: string;
}

export default function BlogTag({ tag }: Props) {
  return (
    <Box component="span" sx={{ padding: "6px 10px", background: "#53cbf0", color: "white", fontWeight: "bold", fontSize: ".7rem", borderRadius: 2 }}>
      {tag}
    </Box>
  );
}
