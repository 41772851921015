import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

interface Props {
  text?: string;
  loading: boolean;
  sx?: SxProps<Theme>;
  size?: number;
}

export default function CustomLoader({ text, loading, sx, size }: Props) {
  if (!loading) return <></>;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1, ...sx }}>
      <BiLoaderAlt size={size ?? 24} className="animate-spin" />
      {text && <p>{text}</p>}
    </Box>
  );
}
