import { Box, IconButton, Typography } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  header: string;
  headerIcon: ReactElement;
  children?: ReactElement | ReactElement[];
}

export default function ContentBox({ header, headerIcon, children }: Props) {
  return (
    <Box
      component="article"
      sx={{ borderRadius: 1, border: "1.5px solid lightgray", marginTop: 2, background: "#f8f8f8", boxShadow: "0x 1px 2px rgba(0,0,0,0.2)" }}
    >
      <Box
        component={"header"}
        sx={{ paddingY: 0.7, paddingX: 1, borderBottom: "1.5px solid lightgray", display: "flex", alignItems: "center", gap: 0.4 }}
      >
        <IconButton>{headerIcon}</IconButton>
        <Typography variant="h6" component="h2" fontWeight="bold" fontSize=".9rem">
          {header}
        </Typography>
      </Box>

      {children}
    </Box>
  );
}
