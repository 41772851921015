import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import BookPropertyDate from "./BookPropertyDate";
import { getDateAndMonth, getDateDifference, getTomorrow } from "../../utils/date.utils";
import { openErrorModal } from "../../redux/handlers.slice";
import isFuture from "date-fns/isFuture";
import { add, isAfter, isBefore, isPast, isSameDay, setDate } from "date-fns";
import { calculateFee } from "../../utils/booking.utils";
import Swal from "sweetalert2";
import { bookReservation } from "../../services/reservations.services";
import { useNavigate, useParams } from "react-router-dom";
import localStorageService from "../../utils/localstorage.utils";
import { UserGroups } from "../../schema/enums/groups.enum";
import { IReservation } from "../../schema/interfaces/reservation.interface";
import { CreateReservationDTO } from "../../schema/dto/reservation.dto";
import { AxiosError } from "axios";
import { getPaymentIntent } from "../../services/payment.services";
import { ActionDispatchResponse } from "../../schema/interfaces/redux.interface";
import { GetPaymentIntentResponse } from "../../schema/dto/payment.dto";
import { BiLoaderAlt } from "react-icons/bi";
import CustomLoader from "../UI/CustomLoader";

const BookProperty = () => {
  const property = useSelector((state: RootState) => state.properties?.singleProperty);
  const paymentLoading = useSelector((state: RootState) => state?.reservations?.handlers?.payment?.isLoading);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(getTomorrow(1));
  const [startDatePicker, setStartDatePicker] = useState<boolean>(false);
  const [endDatePicker, setEndDatePicker] = useState<boolean>(false);
  const [guest, setGuest] = useState<number>(1);
  const navigate = useNavigate();
  const user = localStorageService.user.get();
  const slug = useParams();

  const onGuestChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setGuest(e.target.selectedIndex + 1);
  };

  const difference = getDateDifference(startDate, endDate);
  const totalDayPrice = (property?.dailyPrice as number) * (difference.days as number);
  const totalMonthPrice = (property?.monthlyPrice as number) * (difference.months as number);
  const fee = calculateFee(totalDayPrice + totalMonthPrice);

  const submit = () => {
    Swal.fire({
      title: "Book Property",
      text: `Are you sure you want to book this property from ${getDateAndMonth(startDate)} to ${getDateAndMonth(endDate)}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Book",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data: ActionDispatchResponse<IReservation> = await dispatch(bookReservation({ propertyId: property?.id, guest, startDate, endDate }));
        if (!data.error) {
          navigate(`/properties/reservation/${data.payload.id}/payment`);
        }
      }
    });
  };

  const unauthorizedClick = () => {
    Swal.fire({
      title: "Book Property",
      text: "You need to log in to book this property",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Go to login page",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/login?redirect=/properties/${slug.id}`);
      }
    });
  };

  const notARenterClick = () => {
    Swal.fire({
      title: "Book Property",
      text: "You need to switch to your renter account to book this property",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "darkred",
      confirmButtonText: "Switch Account",
      confirmButtonColor: "#53cbf0",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorageService.currentGroup.store(UserGroups.RENTER);
        await Swal.fire({
          text: "You have successfully swicthed your account, you can now proceed to book this property",
          title: "Book Property",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  useEffect(() => {
    if (isBefore(startDate, getTomorrow(0)) && !isSameDay(startDate, getTomorrow(0))) {
      dispatch(openErrorModal(["Select present & future date"]));
      setStartDate(getTomorrow(0));
    }
  }, [startDate]);

  useEffect(() => {
    if (!isAfter(endDate, startDate)) {
      dispatch(openErrorModal(["End date must be greater than start date"]));
      setEndDate(add(startDate, { days: 1 }));
    }
  }, [endDate, startDate]);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: 4,
        paddingX: 3,
        paddingBottom: 2,
        paddingTop: 1,
        marginTop: 2,
      }}
    >
      {startDatePicker && <BookPropertyDate date={startDate} setDate={setStartDate} type="startDate" closeModal={() => setStartDatePicker(false)} />}

      {endDatePicker && <BookPropertyDate date={endDate} setDate={setEndDate} type="endDate" closeModal={() => setEndDatePicker(false)} />}
      <Typography variant="subtitle1" fontWeight={600} component="p" textAlign="center" marginX="auto" fontSize={14}>
        Book Apartment
      </Typography>

      <Box width="100%" maxWidth={300} marginX="auto" border="1px solid black" borderRadius={5} height={80} marginTop={2}>
        <Box height={40} borderBottom="1px solid black" display="flex">
          {/* Select start and end date */}
          <Typography
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="body2"
            sx={{
              height: "100%",
              width: "50%",
              borderRight: "1px solid black",
              cursor: "pointer",
            }}
            onClick={() => setStartDatePicker(true)}
          >
            {getDateAndMonth(startDate)}
          </Typography>
          <Typography
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="body2"
            sx={{
              height: "100%",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => setEndDatePicker(true)}
          >
            {getDateAndMonth(endDate)}
          </Typography>
        </Box>

        {/* Select the number of guests */}
        <Box height={40} display="flex" justifyContent={"center"} alignItems="center" width="100%">
          <Typography textAlign="center" variant="body2">
            <select value={guest} onChange={onGuestChange}>
              {new Array(property?.guest).fill(null).map((_, index) => {
                return (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                );
              })}
            </select>{" "}
            guest(s)
          </Typography>
        </Box>
      </Box>

      <Stack
        direction="column"
        gap={1}
        width="100%"
        maxWidth={300}
        marginX="auto"
        border="1px solid black"
        borderRadius={5}
        marginTop={2}
        padding={2}
      >
        {difference.months > 0 && (
          <Box component="article" display={"flex"} alignItems={"center"} justifyContent={"space-between"} fontSize={12}>
            <p>
              ${property?.monthlyPrice?.toLocaleString()} per Month x {difference.months} months
            </p>
            <p>${totalMonthPrice.toLocaleString()}</p>
          </Box>
        )}

        {difference.days > 0 && (
          <Box component="article" display={"flex"} alignItems={"center"} justifyContent={"space-between"} fontSize={12}>
            <p>
              ${property?.dailyPrice?.toLocaleString()} per day x {difference.days} days
            </p>
            <p>${totalDayPrice.toLocaleString()}</p>
          </Box>
        )}

        <Box component="article" display={"flex"} alignItems={"center"} justifyContent={"space-between"} fontSize={12}>
          <p>Extra Fees</p>
          <p>${fee.toLocaleString()}</p>
        </Box>

        <hr style={{ width: "100%", height: "2px" }} />

        <Box component="article" display={"flex"} alignItems={"center"} justifyContent={"space-between"} fontSize={12}>
          <p style={{ fontWeight: "bold" }}>Total</p>
          <p>${(fee + totalMonthPrice + totalDayPrice).toLocaleString()}</p>
        </Box>
      </Stack>

      <Box width="100%" maxWidth={300} marginX="auto">
        <Button
          fullWidth
          disabled={paymentLoading}
          variant="contained"
          sx={{
            marginTop: 2,
            borderRadius: 3,
            cursor: "pointer",
          }}
          onClick={() => {
            if (user) {
              const currentGroup = localStorageService.currentGroup.get();
              if (currentGroup === UserGroups.OWNER) {
                notARenterClick();
              } else {
                submit();
              }
            } else {
              unauthorizedClick();
            }
          }}
        >
          {!paymentLoading ? "Book Now" : <CustomLoader loading={paymentLoading} />}
        </Button>
      </Box>
    </Paper>
  );
};

export default BookProperty;
