import { Box, Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";
import { getReservationPayment } from "../services/payment.services";
import { RootState } from "../redux/store";
import CustomLoader from "../components/UI/CustomLoader";
import { Check } from "@mui/icons-material";
import { blue, green } from "@mui/material/colors";
import { format } from "date-fns";
import ProtectedProvider from "../components/Providers/ProtectedProvider";

export default function PaymentSuccess() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const payment = useSelector((state: RootState) => state.payment?.singlePayment);
  const loading = useSelector((state: RootState) => state.payment?.handlers?.isLoading);

  useEffect(() => {
    dispatch(getReservationPayment(id));
  }, []);

  return (
    <ProtectedProvider>
      <Box sx={{ width: "100%", background: "#f8f8f8", height: "100vh" }}>
        {loading ? (
          <CustomLoader size={35} loading={loading} />
        ) : (
          <>
            <Container maxWidth="md" sx={{ minHeight: "100vh", height: "100vh", paddingX: 2 }}>
              <Grid container gap={4} alignItems="center" sx={{ minHeight: "100%", paddingY: 6 }}>
                <Grid item xs={10} md={6.5}>
                  <Box component="img" src="/images/ATPH-Logo.png" sx={{ width: "100px" }}></Box>
                  <Typography variant="h4" fontWeight={"bold"} color={"#333741"} paddingLeft={1} maxWidth={"500px"} marginTop={2}>
                    Booking confirmed successfully!
                  </Typography>
                  <Typography variant="body1" fontSize={14} color={"#333741"} paddingLeft={1} maxWidth={"500px"} marginTop={2}>
                    Thank you for choosing to book with A Traveled Path Homes! Your reservation is confirmed. If there's anything you need before your
                    arrival please don't hesitate to reach out to your host!
                  </Typography>

                  <Link
                    variant="body1"
                    component={RouterLink}
                    to={"/"}
                    sx={{ textDecoration: "underline" }}
                    fontSize={14}
                    color={"#111"}
                    paddingLeft={1}
                    maxWidth={"500px"}
                    display={"block"}
                    marginTop={1}
                    fontWeight="500"
                  >
                    Go back to home
                  </Link>
                </Grid>
                <Grid item xs={10} md={5}>
                  <Paper
                    elevation={1}
                    sx={{
                      background: "white",
                      borderRadius: 3,
                      paddingX: 3,
                      paddingY: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h5" color="#111" lineHeight={1.4} fontWeight="bold" fontSize={18}>
                        ${((payment?.amountReceived as number) / 100)?.toLocaleString()}
                      </Typography>
                      <Typography variant="subtitle1" fontSize={12} color="#333741" lineHeight={1.2}>
                        Payment success!
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#0000ff2f",
                        cursor: "pointer",
                        borderRadius: "50%",
                      }}
                    >
                      <Check color="primary" />
                    </Box>
                  </Paper>

                  <Paper
                    elevation={1}
                    sx={{
                      background: "white",
                      borderRadius: 3,
                      paddingX: 3,
                      paddingY: 2,
                      marginTop: 2,
                    }}
                  >
                    <Typography variant="h5" color="#111" fontWeight={"500"} lineHeight={1.4} fontSize={18}>
                      Payment Details
                    </Typography>

                    <Stack flexDirection="column" gap={1.5} marginTop={1}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography variant="body1" color="#333741" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Date
                        </Typography>
                        <Typography variant="body1" color="#111" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          {payment && format(new Date(payment?.updatedAt as Date), "MMMM dd',' yyyy")}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography variant="body1" color="#333741" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Intent
                        </Typography>
                        <Typography variant="body1" color="#111" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          {payment?.intentId}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography variant="body1" color="#333741" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Amount
                        </Typography>
                        <Typography variant="body1" color="#111" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          ${((payment?.amountReceived as number) / 100)?.toLocaleString()}
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography variant="body1" color="#333741" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Method
                        </Typography>
                        <Typography variant="body1" color="#111" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Credit Card
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography variant="body1" color="#333741" fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          Status
                        </Typography>
                        <Typography variant="body1" color={green[300]} textTransform={"capitalize"} fontWeight={"500"} lineHeight={1.4} fontSize={14}>
                          {payment?.status}
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </>
        )}
      </Box>
    </ProtectedProvider>
  );
}
