import React from "react";
import ContentBox from "./ContentBox";
import { PersonOutlineRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import Information from "./Information";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import formatDate from "../../utils/date.utils";

export default function UserInformation() {
  const user = useSelector((state: RootState) => state.user);

  return (
    <ContentBox header={"Personal Information"} headerIcon={<PersonOutlineRounded />}>
      <Box sx={{ padding: 2 }}>
        <Grid container columnGap={1} rowGap={1.5}>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Full Name" info={`${user?.firstName} ${user?.lastName}`} />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Email" info={`${user?.email}`} />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Username" info={`${user?.username}`} />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Profession" info={user?.profession || "Not Specified"} />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Date of Birth" info={user?.dob ? formatDate(user?.dob) : "Not Specified"} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Phone Number" info={user?.phone || "Not Specified"} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Id Number" info={user?.idNumber || "Not Specified"} />
          </Grid>
        </Grid>
      </Box>
    </ContentBox>
  );
}
