import { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { closeErrorModal, closePreloader, openErrorModal } from '../redux/handlers.slice';

const errorHandler = (thunkApi: GetThunkAPI<{}>, error: any, openModal: boolean = true) => {
  const dispatch = thunkApi.dispatch;
  const _self = error;
  if (error?.response?.data?.error) {
    error = error?.response?.data?.error;
    if (typeof error === 'string') {
      error = [error];
    } //check if it's an array
    else if (error?.[0]) {
      // not an array again
      error = error?.[0];

      if (error.message) {
        error = [error?.message];
      } else {
        error = [error];
      }
    } else if (typeof error === 'object') {
      error = Object.values(error);
    }
  } else if (error?.response?.data?.message) {
    error = [error.response.data.message];
  }

  if (_self?.response?.status !== 403) {
    dispatch(closePreloader({}));
    openModal && dispatch(openErrorModal(error));
  }
  return thunkApi.rejectWithValue(error);
};

export default errorHandler;
