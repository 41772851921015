import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
//mui components
import { Stack, TextField, Button, Box, Paper, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
//react date picker
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { BiPencil, BiTrash } from "react-icons/bi";
import { editProfile, getProfile } from "../services/profile.services";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { RootState } from "../redux/store";
import localStorageService from "../utils/localstorage.utils";
import { formatDateIn_ } from "../utils/date.utils";
import checkIsOwner, { hasOwnerAccount } from "../utils/user-groups.utils";
import UserAddressForm from "../components/BecomeOwner/UserAddressForm";
import { getAllISOCodes, getISOByParam } from "iso-country-currency";
import { IGeoCodeResult } from "../schema/interfaces/map.interface";

function EditProfileForm() {
  //declare dispatch
  const dispatch = useDispatch();
  const user = localStorageService.user.get();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [line1, setLine1] = useState(user?.line1);
  const [line2, setLine2] = useState(user?.line2);
  const [city, setCity] = useState(user?.city);
  const [state, setState] = useState(user?.state);
  const [country, setCountry] = useState(user?.country);
  const [postalCode, setPostalCode] = useState(user?.postalCode);
  const [phone, setPhone] = useState(user?.phone);
  const [dob, setDob] = useState(user?.dob ? new Date(user?.dob) : new Date(2002, 0, 1));
  const [profession, setProfession] = useState(user?.profession || "");
  const [newFile, setNewFile] = useState<File | undefined>(undefined);
  const isOwner = hasOwnerAccount(user?.groups);
  const countries = useMemo(() => getAllISOCodes().map((iso) => iso.countryName), []);

  const selectLocation = useCallback(async function (location: IGeoCodeResult) {
    setLine1(location?.address_line1 ?? "");
    setLine2(location?.address_line2 ?? "");
    setCity(location?.city ?? "");
    setCountry(location?.country ?? "");
    setPostalCode(location?.postcode ?? "");
    setState(location?.state ?? "");
  }, []);

  const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewFile(e.target.files?.[0]);
  };

  const deleteFile = () => setNewFile(undefined);

  const submit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = await dispatch(
      editProfile({
        firstName,
        lastName,
        dob: formatDateIn_(dob),
        profession,
        profilePicture: newFile || user?.profilePicture,
        line1,
        line2,
        city,
        country,
        postalCode,
        state,
        phone,
      })
    );

    if (!data.error) {
      await Swal.fire({
        title: "Edit profile",
        text: "Your profile has been successfully edited",
        showConfirmButton: false,
        timer: 2000,
        icon: "success",
      });
      window.history.back();
    }
  };

  useEffect(() => {
    // fetch the user profile to pre-fill the inputs
    dispatch(getProfile());
  }, []);

  return (
    <DashboardLayout>
      <form onSubmit={submit}>
        <Box component="header" display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" gap={4}>
          <h2>Edit Profile</h2>
          {!isOwner && (
            <Link to="/renter/become-owner">
              <Button variant="contained" type="button">
                Become an owner
              </Button>
            </Link>
          )}
        </Box>

        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap"
          marginTop={2}
          sx={{
            maxWidth: "fit-content",
            maxHeight: "fit-content",
            position: "relative",
          }}
        >
          <img
            src={newFile ? URL.createObjectURL(newFile) : user?.profilePicture}
            alt="profile-photo"
            style={{
              width: "180px",
              height: "180px",
              objectFit: "cover",
              objectPosition: "center",
              borderRadius: "50%",
            }}
          />

          <input type="file" accept="image/*" name="file-upload" id="profile-image-upload" style={{ display: "none" }} onChange={onFileInputChange} />
          <Box component={"label"} htmlFor="profile-image-upload" sx={{ ...iconContainerStyles, bottom: "20px" }}>
            <BiPencil />
          </Box>

          {/* For deleting the selected file */}
          {newFile && (
            <Box component={"span"} onClick={deleteFile} sx={{ ...iconContainerStyles, top: "20px" }}>
              <BiTrash />
            </Box>
          )}
        </Box>

        <Box marginTop={5}>
          <h3>Personal Information</h3>
          <Grid container gap={2} sx={{ marginTop: 1 }}>
            {/* Firstname */}
            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <label style={labelStyles} htmlFor="firstName">
                  First Name
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={firstName}
                  required
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Box>
            </Grid>

            {/* Lastname */}
            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <label style={labelStyles} htmlFor="lastName">
                  Last Name
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  required
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Box>
            </Grid>
            {/* Profession */}
            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <label style={labelStyles} htmlFor="profession">
                  Profession/Area of Expertise
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Profession"
                  type="text"
                  name="profession"
                  value={profession}
                  multiline
                  onChange={(event) => setProfession(event.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={5} lg={3.8}>
              <Box sx={boxStyles}>
                <label style={labelStyles} htmlFor="phone">
                  Phone Number
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  label="Phone Number"
                  type="number"
                  name="phone"
                  helperText="Do not start phone number with 0 or country code"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {isOwner && (
          <Box marginTop={5}>
            <h3>Location Information</h3>
            <Grid container gap={2} sx={{ marginY: 1 }}>
              {/*  display for only users with owner account*/}
              <>
                {" "}
                {/* Line 1 */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <Box
                      component="header"
                      sx={{ display: "flex", alignItems: "center", flexWrap: "wrap-reverse", gap: 2, justifyContent: "space-between" }}
                    >
                      <Typography variant="subtitle2" component="label" style={labelStyles} htmlFor="addressLine1">
                        Address Line 1
                      </Typography>
                      <UserAddressForm selectLocation={selectLocation} />
                    </Box>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Address Line 1"
                      type="text"
                      name="addressLine1"
                      value={line1}
                      multiline
                      onChange={(event) => setLine1(event.target.value)}
                    />
                  </Box>
                </Grid>
                {/* Line 2 */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <label style={labelStyles} htmlFor="addressLine2">
                      Address Line 2 (optional)
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Address Line 2"
                      type="text"
                      name="addressLine2"
                      value={line2}
                      onChange={(event) => setLine2(event.target.value)}
                    />
                  </Box>
                </Grid>
                {/* City */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <label style={labelStyles} htmlFor="city">
                      City
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="City"
                      type="text"
                      name="city"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </Box>
                </Grid>
                {/* State */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <label style={labelStyles} htmlFor="state">
                      State
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="State"
                      type="text"
                      name="state"
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                    />
                  </Box>
                </Grid>
                {/* Zip Code */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <label style={labelStyles} htmlFor="postalCode">
                      Zip/Postal Code
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="postalCode"
                      type="text"
                      name="postalCode"
                      value={postalCode}
                      onChange={(event) => setPostalCode(event.target.value)}
                    />
                  </Box>
                </Grid>
                {/* Country */}
                <Grid item xs={12} md={5} lg={3.8}>
                  <Box sx={boxStyles}>
                    <label style={labelStyles} htmlFor="state">
                      Country
                    </label>
                    <FormControl fullWidth sx={{ width: "100%" }}>
                      <InputLabel id="country">Country</InputLabel>
                      <Select
                        label="Country"
                        labelId="country"
                        value={country || "Select Country"}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        size="small"
                        fullWidth={true}
                        style={{ fontSize: ".8rem", textTransform: "capitalize" }}
                      >
                        <MenuItem disabled={true} value={"Select Country"} style={{ fontSize: ".8rem", textTransform: "capitalize" }}>
                          Select Country
                        </MenuItem>

                        {countries?.map((country, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={getISOByParam("countryName", country)}
                              style={{ fontSize: ".8rem", textTransform: "capitalize" }}
                            >
                              {country}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </>
            </Grid>
          </Box>
        )}

        <Grid container gap={2} sx={{ marginY: 1 }}></Grid>
        <Box>
          <label style={labelStyles} htmlFor="date-of-birth">
            Date Of Birth
          </label>
          <Paper square elevation={2} sx={{ padding: 1, maxWidth: "fit-content", marginTop: "1rem" }}>
            <DayPicker
              mode="single"
              captionLayout="dropdown"
              required={true}
              fromYear={1900}
              toYear={new Date().getFullYear()}
              defaultMonth={dob}
              selected={dob}
              onSelect={(e) => setDob(e as Date)}
            />
          </Paper>
        </Box>
        <Button
          type="submit"
          size="large"
          sx={{
            backgroundColor: "#53cbf0",
            color: "#f8f8f8",
            minWidth: "120px",
            marginLeft: "auto",
            display: "block",
            marginTop: 2,
          }}
          variant="contained"
        >
          Save
        </Button>
      </form>
    </DashboardLayout>
  );
}

const boxStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  marginY: 1.5,
};

const labelStyles = {
  fontWeight: "500",
  fontSize: ".9rem",
};

const iconContainerStyles = {
  display: "flex",
  width: 50,
  height: 50,
  justifyContent: "center",
  alignItems: "center",
  fontSize: 20,
  borderRadius: "50%",
  border: "1.5px solid lightgray",
  position: "absolute",
  right: "-10px",
  backgroundColor: "white",
  transition: "all .3s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#53cbf0",
    color: "white",
    borderColor: "#53cbf0",
  },
};

export default EditProfileForm;
