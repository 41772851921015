import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ContentBox from "./ContentBox";
import { MoneyOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import Information from "./Information";
import { getAllInfoByISO, getISOByParam } from "iso-country-currency";

export default function PaymentInformation() {
  const bank = useSelector((state: RootState) => state?.paymentProfile?.bankAccount);
  const user = useSelector((state: RootState) => state.user);

  return (
    <ContentBox header="Bank Information" headerIcon={<MoneyOutlined />}>
      <Box sx={{ padding: 2 }}>
        <Grid container columnGap={1} rowGap={1.5}>
          <Grid item xs={12} sm={5.8}>
            <Information topic="Account Holder Name" info={bank?.accountHolderName || `${user?.firstName} ${user?.lastName}`} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Account Number" info={bank?.accountNumber || `Not Specified`} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Routing Number" info={bank?.routingNumber || `Not Specified`} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Country" info={bank?.country ? getAllInfoByISO(bank?.country)?.countryName : `Not Specified`} />
          </Grid>

          <Grid item xs={12} sm={5.8}>
            <Information topic="Currency" info={bank?.currency || `Not Specified`} />
          </Grid>
        </Grid>
      </Box>
    </ContentBox>
  );
}
