import React, { ChangeEvent, useCallback, useState } from "react";
import { IGeoCodeResult } from "../../schema/interfaces/map.interface";
import { Box, IconButton, TextField } from "@mui/material";
import LocationSelector from "../UI/LocationSelector";
import { autoCompleteLocations } from "../../services/map.services";
import { Button } from "@mui/material";
import { Close, Search } from "@mui/icons-material";

interface Props {
  selectLocation(location: IGeoCodeResult): void;
}

export default function UserAddressForm({ selectLocation }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [locations, setLocations] = useState<IGeoCodeResult[]>([]);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState<boolean>(false);

  const fetchLocations = useCallback(async (search: string) => {
    setLocationSelectorOpen(true);
    setLoading(true);
    const result = await autoCompleteLocations(search);
    setLocations(result.slice(0, 5));
    setLoading(false);
  }, []);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (e.target.value.length >= 3) {
      // fetch cities
      fetchLocations(e.target.value);
    }
  }, []);

  if (!isOpen) {
    return (
      <Button variant="contained" size="small" onClick={() => setIsOpen(true)} sx={{ maxWidth: "fit-content", display: "block", marginLeft: "auto" }}>
        Search Location
      </Button>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Box component="span" sx={{ maxWidth: "fit-content", display: "block", marginLeft: "auto" }}>
        <IconButton onClick={() => setIsOpen(false)} title="close" size="small" sx={{ maxWidth: "fit-content", marginLeft: "auto" }}>
          <Close />
        </IconButton>
      </Box>

      <TextField
        variant="outlined"
        fullWidth
        type="text"
        sx={{ marginTop: 1.4 }}
        label="Search address"
        autoComplete={"off"}
        value={search}
        size="small"
        helperText="Select a location to prefill the input fields"
        InputProps={{ sx: { fontSize: ".8rem" } }}
        onChange={onChange}
      />

      {/* locations display */}
      {locationSelectorOpen && (
        <LocationSelector
          closeBox={() => setLocationSelectorOpen(false)}
          selectLocation={(location) => {
            selectLocation(location);
            setLocationSelectorOpen(false);
            setIsOpen(false);
          }}
          isLoading={loading}
          locations={locations}
        />
      )}
    </Box>
  );
}
