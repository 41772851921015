import { Stack, Typography } from "@mui/material";
import React from "react";

interface Props {
  topic: string;
  info: string | number;
}

export default function Information({ topic, info }: Props) {
  return (
    <Stack flexDirection={"column"} gap={0.2} component="article">
      <Typography fontSize=".8rem" component={"h3"} fontWeight="bold">
        {topic}
      </Typography>
      <Typography fontSize=".8rem" component={"p"}>
        {info}
      </Typography>
    </Stack>
  );
}
