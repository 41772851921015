import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import SearchConversation from "./SearchConversation";
import { useDispatch, useSelector } from "react-redux";
import { getConversations } from "../../services/chat.services";
import { RootState } from "../../redux/store";
import SingleConversation from "./SingleConversation";
import CustomLoader from "../UI/CustomLoader";

export default function Conversations() {
  const screenQuery = useMediaQuery("(max-width: 1000px)");
  const dispatch = useDispatch();
  const conversations = useSelector((state: RootState) => state.chat.conversations);
  const loading = useSelector((state: RootState) => state.chat.handlers.conversations.isLoading);

  useEffect(() => {
    dispatch(getConversations());
  }, [window.location.pathname]);

  return (
    <Box
      component="section"
      sx={{
        background: "white",
        height: "100%",
        ...(!screenQuery
          ? {
              borderRadius: 2.4,
              boxShadow: "0px 2px 3px  #c1bcbc",
              border: "1.5px solid #c1bcbc",
            }
          : {}),
      }}
    >
      <Box component="header" padding={2}>
        <Typography variant="subtitle1" component="h2" color="#333741" fontWeight="bold">
          Messages
        </Typography>

        <SearchConversation />
      </Box>

      <Box
        component="section"
        sx={{ ...(screenQuery ? {} : { height: "calc(100vh - 250px)", maxHeight: "calc(100vh - 250px)", overflowY: "scroll" }) }}
      >
        <CustomLoader loading={loading} text="Loading Chats" sx={{ marginY: 1, justifyContent: "center", fontSize: ".8rem !important" }} size={20} />
        {conversations?.map((conversation, index) => (
          <SingleConversation {...conversation} key={index} />
        ))}
      </Box>
    </Box>
  );
}
