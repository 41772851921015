import { Box, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { BsArrowRight } from "react-icons/bs";

interface Props {
  title: string;
  body?: string[];
  children?: ReactElement | ReactElement[];
}

export default function PrivacyPolicySubtopic({ title, body, children }: Props) {
  return (
    <Box>
      <Typography variant="subtitle1" color="#333741" marginTop={1} marginBottom={1}>
        {title}
      </Typography>
      <Stack component={"ul"} gap={0.8} flexDirection={"column"} paddingLeft={2}>
        {body?.map((text, index) => {
          return (
            <Typography variant="body1" key={index}>
              <span style={{ display: "inline-block", verticalAlign: "middle", marginRight: "10px" }}>
                <BsArrowRight color="#53cbf0" size={20} />
              </span>
              {text}
            </Typography>
          );
        })}
        {children}
      </Stack>
    </Box>
  );
}
