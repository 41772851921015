import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { VerifiedUser } from "@mui/icons-material";
import localStorageService from "../../utils/localstorage.utils";
import SendMessageModal from "./SendMessage";
import Swal, { SweetAlertResult } from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

export default function PropertyUserInfo() {
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false);
  const screenQuery = useMediaQuery("(max-width:900px)");
  const property = useSelector((state: RootState) => state?.properties?.singleProperty);
  const slug = useParams();
  const navigate = useNavigate();

  const user = localStorageService.user.get();
  const isOwner = user?.id === property?.User?.id;

  const unauthorizedClick = () => {
    Swal.fire({
      title: "Send Message",
      text: "You need to login to message this user",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      cancelButtonColor: "darkred",
      confirmButtonText: "Login",
      confirmButtonColor: "#53cbf0",
    }).then((result: SweetAlertResult) => {
      if (result.isConfirmed) {
        navigate(`/login?redirect=/properties/${slug.id}`);
      }
    });
  };

  return (
    <Box marginX="auto" marginY={3} maxWidth={screenQuery ? "100%" : "350px"}>
      <SendMessageModal isOpen={messageModalOpen} closeModal={() => setMessageModalOpen(false)} user={property?.User} propertyId={property?.id} />
      <Box
        paddingY={2}
        borderTop={"1px solid #333741"}
        borderBottom={"1px solid #333741"}
        display={"flex"}
        alignItems={"flex-start"}
        width={"100%"}
        gap={2}
      >
        <img
          src={property?.User?.profilePicture}
          alt=""
          style={{ width: "60px", height: "60px", objectFit: "cover", objectPosition: "center", borderRadius: "50%" }}
        />
        <Box>
          <Typography variant="h6">
            {property?.User?.firstName} {property?.User?.lastName}
          </Typography>
          <Box display="flex" gap={0.8} alignItems={"center"} marginTop={1}>
            <VerifiedUser />
            <Typography variant="body1" fontSize={".8rem"}>
              Verified Property Owner
            </Typography>
          </Box>
        </Box>
      </Box>

      {!isOwner && (
        <Button
          variant="contained"
          onClick={() => (user ? setMessageModalOpen(true) : unauthorizedClick())}
          sx={{ display: "block", maxWidth: "200px", marginX: "auto", marginTop: 1 }}
        >
          Message Owner
        </Button>
      )}
    </Box>
  );
}
