import { Box, Link, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { PROPERTY_IMAGE } from "../../constants/property-image.const";
import { shortenText } from "../../utils/text.utils";
import { IMessage } from "../../schema/interfaces/chat.interface";
import { getPropertyImages } from "../../utils/property.utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getMessageSenderFromConversation } from "../../utils/chat.utils";
import localStorageService from "../../utils/localstorage.utils";
import { Link as RouterLink } from "react-router-dom";

interface Props extends IMessage {}

export default function SingleChat({ Property, text, userId, propertyId }: Props) {
  const currentConversation = useSelector((state: RootState) => state.chat?.currentConversation);
  const propertyImages = getPropertyImages(Property);
  const loggedInUser = localStorageService.user.get();

  const user = useMemo(() => getMessageSenderFromConversation(currentConversation, userId as number) ?? loggedInUser, []);

  return (
    <Box component="article" padding={1}>
      <Box component="div" display="flex" gap={2} alignItems="flex-statr">
        <Box maxWidth="fit-content">
          <img
            src={user?.profilePicture}
            style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover", objectPosition: "center" }}
            alt="user"
          />
        </Box>

        <Box flex={1}>
          <Typography variant={"body1"} fontWeight="bold" fontSize=".8rem">
            {user?.id === loggedInUser?.id ? "You" : `${user?.firstName} ${user?.lastName}`}
          </Typography>

          {/* Display property/reservation involved */}
          {Property && (
            <Link
              component={RouterLink}
              to={`/properties/${propertyId}`}
              display="flex"
              padding={1}
              borderRadius={"6px"}
              sx={{ background: "#eaeaea" }}
              gap={1}
              marginY={0.5}
              boxShadow={"inset 0px 1px 3px rgba(0,0,0,.3)"}
            >
              {/* Property Info */}
              <Box>
                <img
                  src={propertyImages?.[0]}
                  style={{ width: "50px", height: "50px", borderRadius: "6px", objectFit: "cover", objectPosition: "center" }}
                  alt={Property?.name}
                />
              </Box>
              <Box>
                <Typography variant={"subtitle1"} fontWeight="bold" fontSize=".8rem">
                  {Property.name}
                </Typography>
                <Typography variant={"subtitle2"} fontSize=".75rem" lineHeight={1.15}>
                  {shortenText(Property?.description, 80)}
                </Typography>
              </Box>
            </Link>
          )}

          <Typography variant="body2" fontSize=".76rem">
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
