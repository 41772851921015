import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOwnershipDocumentsFile, IPropertyFormSlice, IPropertyType } from '../schema/interfaces/property.interface';
import { IAmenties } from '../schema/interfaces/amenities.interface';
import { addProperty } from '../services/property.services';

const initialState: IPropertyFormSlice = {
  type: IPropertyType.singleFamily,
  description: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  amenities: [],
  photos: [],
  documents: [{ name: '' }],
  longitude: undefined,
  latitude: undefined,
  guest: 0,
  beds: 0,
  bedroom: 0,
  bathroom: 0,
  minStay: 0,
  dailyPrice: 0,
  monthlyPrice: 0,
};

const propertyFormSlice = createSlice({
  name: 'propertyFormSlice',
  initialState,
  reducers: {
    updatePropertyForm: (state, action: PayloadAction<{ key: string; value?: string | number }>) => {
      state[action.payload.key as keyof IPropertyFormSlice] = <never>action.payload.value;
    },
    updateAmenityDescription: (state, action: PayloadAction<{ text?: string; value: string }>) => {
      //  we'll use the text to search for it
      state.amenities = state?.amenities?.map((amenity) => {
        if (amenity.text == action.payload?.text) {
          amenity.description = action.payload.value;
        }

        return amenity;
      });
    },
    removeAmenity: (state, action: PayloadAction<{ text: string }>) => {
      // use the text to search and filter it
      state.amenities = state?.amenities?.filter((amenity) => amenity.text != action.payload.text);
    },
    addAmenity: (state, action: PayloadAction<IAmenties>) => {
      state.amenities = [...state?.amenities, action.payload];
    },

    addPhotos: (state, action: PayloadAction<FileList>) => {
      // the payload is in FileList format
      const payload = action?.payload;

      for (let i = 0; i < payload.length; i++) {
        state.photos = [...state.photos, payload.item(i) as File];
      }
    },

    removePhoto: (state, action: PayloadAction<{ index: number }>) => {
      const index = action.payload.index;

      state.photos = state.photos?.filter((photo, idx) => idx != index);
    },

    addDocument: (state, action) => {
      state.documents = [...state.documents, { name: '' }];
    },

    removeDocument: (state, action: PayloadAction<{ index: number }>) => {
      const index = action.payload.index;

      state.documents = state.documents?.filter((doc, idx) => idx != index);
    },

    updateDocName: (state, action: PayloadAction<{ index: number; value: string }>) => {
      const value = action.payload.value;
      const index = action.payload.index;

      state.documents = state.documents?.map((doc, idx) => {
        if (idx === index) {
          doc.name = value;
        }

        return doc;
      });
    },

    updateDocFile: (state, action: PayloadAction<{ index: number; value: File }>) => {
      const value = action.payload.value;
      const index = action.payload.index;

      state.documents = state?.documents?.map((doc: IOwnershipDocumentsFile, idx) => {
        if (idx === index) {
          doc.file = value;
        }
        return doc;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProperty.fulfilled, (state) => {
      return initialState;
    });
  },
});

const propertyFormReducer = propertyFormSlice.reducer;
export const {
  updatePropertyForm,
  updateAmenityDescription,
  removeAmenity,
  addAmenity,
  addPhotos,
  removePhoto,
  addDocument,
  removeDocument,
  updateDocFile,
  updateDocName,
} = propertyFormSlice.actions;
export default propertyFormReducer;
