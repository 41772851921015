import React, { useImperativeHandle } from "react";
import ChartOverlay from "./ChartOverlay";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { BarChart, XAxis, Label, YAxis, Tooltip, Legend, Bar, Text } from "recharts";
import { months } from "../../utils/date.utils";
import { yellow } from "@mui/material/colors";

const data = [
  { month: months[0], value: 2000 },
  { month: months[1], value: 400 },
  { month: months[2], value: 6000 },
  { month: months[3], value: 1250 },
  { month: months[4], value: 2250 },
  { month: months[5], value: 1900 },
  { month: months[6], value: 2145 },
  { month: months[7], value: 3240 },
  { month: months[8], value: 12500 },
  { month: months[9], value: 1400 },
  { month: months[10], value: 1000 },
  { month: months[11], value: 1100 },
];

export default function MonthlyFinanceChart() {
  const smallScreen = useMediaQuery(`(max-width: 900px)`);
  const mediumScreen = useMediaQuery(`(max-width: 1200px)`);
  const largerScreen = useMediaQuery(`(min-width: 2000px)`);
  return (
    <ChartOverlay>
      <Box sx={{ paddingLeft: 5 }}>
        <Typography variant="h6" fontWeight={"bold"}>
          {new Date().getFullYear()}
        </Typography>
      </Box>
      <BarChart
        style={{
          minHeight: "fit-content",
          cursor: "pointer",
          fontSize: ".8rem",
          overflowY: "hidden",
          transform: "scale(.9)",
          fontWeight: "bold",
          color: "#111",
        }}
        width={largerScreen ? 900 : smallScreen ? 500 : mediumScreen ? 700 : 500}
        height={250}
        data={data.map((data) => ({ ...data, month: data.month.slice(0, 3) }))}
      >
        <Tooltip />
        <Legend />
        <Bar dataKey={"value"} fill={yellow[800]} />

        <XAxis dataKey={"month"} />
        <YAxis dataKey={"value"}>
          <Label value={"Amount($)"} angle={-90} position={"insideLeft"} fontWeight={"bold"} color="#111" />
        </YAxis>
      </BarChart>
    </ChartOverlay>
  );
}
